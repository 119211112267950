import { BsPerson, BsBriefcase, BsJournalBookmarkFill, BsQuestionCircle, BsBuilding, BsCheck2Circle } from "react-icons/bs";
import { HiOutlineAcademicCap } from "react-icons/hi";
import { MdFlightTakeoff } from "react-icons/md";
import { FaRegNewspaper, FaRunning } from "react-icons/fa";
import { RiBallPenLine } from "react-icons/ri";

const menuItems = [
  {
    name: "Personal Details",
    path: "/apply/section-1",
    icon: <BsPerson />,
  },
  {
    name: "Academic Details",
    path: "/apply/section-2",
    icon: <HiOutlineAcademicCap />,
  },
  {
    name: "Internship",
    path: "/apply/section-3",
    icon: <BsBriefcase />,
  },
  {
    name: "Semester in Abroad",
    path: "/apply/section-4",
    icon: <MdFlightTakeoff />,
  },
  {
    name: "Placement/Higher Studies",
    path: "/apply/section-5",
    icon: <BsBriefcase />,
  },
  {
    name: "Research Papers",
    // name: "Journal Publications",
    path: "/apply/section-6",
    icon: <FaRegNewspaper />,
    // icon: <BsJournalBookmarkFill />,
  },
  {
    name: "Recognition in Department",
    path: "/apply/section-7",
    icon: <BsBuilding />,
  },
  {
    name: "Sports, Club and Extra Activities",
    path: "/apply/section-8",
    icon: <FaRunning />,
  },
  {
    name: "Competitive Exams",
    path: "/apply/section-9",
    icon: <RiBallPenLine />,
  },
  {
    name: "Statement of Purpose",
    path: "/apply/section-10",
    icon: <BsQuestionCircle />,
  },{
    name: "Confirm your submission",
    path: "/apply/confirm",
    icon: <BsCheck2Circle />
  }
];

export default menuItems;
