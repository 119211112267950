import React, { useContext, useEffect, useState } from "react";
import { IoMdDownload, IoMdTime } from "react-icons/io";
import { HiOutlineTrash } from "react-icons/hi";
import { CompactTable } from "@table-library/react-table-library/compact";
import { useTheme } from "@table-library/react-table-library/theme";
import { getTheme } from "@table-library/react-table-library/baseline";

import ModalImage from "react-modal-image";
import Button from "./Button";
import Popup from "reactjs-popup";
import styled, { keyframes } from "styled-components";
import { BsCheck2Circle, BsCloudArrowUpFill } from "react-icons/bs";
import { FaRegTimesCircle } from "react-icons/fa";

const breatheAnimation = keyframes`
 0% { opacity: 0; transform: scale(0.25) translateY(75px); }
 100% { opacity: 1; transform: scale(1); }
`;

const StyledPopup = styled(Popup)`
  &-overlay {
    animation-name: ${breatheAnimation};
    animation-duration: 0.5s;
  }
  &-content {
    border-radius: 10px;
    background-color: #e5e5e5;
    animation-name: ${breatheAnimation};
    animation-duration: 0.5s;
  }
  &-arrow {
    color: #e5e5e5;
    animation-name: ${breatheAnimation};
    animation-duration: 0.5s;
  }
`;

const Table = ({
  theads = [],
  tdata = [],
  tkeys = [],
  className = "",
  tratio = "",
  url = "",
  handleDownload = () => {},
  ApproveButton = null,
  RejectButton = null,
  haveID = false,
}) => {
  const nodes = tdata.map((d, di) => {
    let j = Object();
    j["ID"] = di + 1;
    j["_id"] = d["_id"];
    tkeys.forEach((k) => {
      j[k] = d[k];
    });
    return j;
  });

  let COLUMNS = theads.map((h, idx) => {
    return {
      label: h,
      renderCell: (item) => {
        if (
          /\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z)/.test(
            item[tkeys[idx]]
          )
        ) {
          return parseInt(item[tkeys[idx]].split("-")[0]) + 1;
        }

        if (
          item[tkeys[idx]] === "approved" ||
          item[tkeys[idx]] === "rejected" ||
          item[tkeys[idx]] === "pending"
        ) {
          return (
            <div className="flex space-x-2 items-center" key={idx}>
              <div
                className={`${
                  item[tkeys[idx]] === "approved"
                    ? "bg-[#2bb673] text-[#eaeaea]"
                    : item[tkeys[idx]] === "rejected"
                    ? "bg-[#ff0033] text-[#eaeaea]"
                    : item[tkeys[idx]] === "published"
                    ? "bg-[#ace5ee] text-[#0f52ba]"
                    : "bg-[#ffd000] text-[#303030]"
                } rounded-full w-8 h-8 flex text-xl justify-center items-center`}
              >
                {item[tkeys[idx]] === "approved" && <BsCheck2Circle />}
                {item[tkeys[idx]] === "rejected" && <FaRegTimesCircle />}
                {item[tkeys[idx]] === "pending" && <IoMdTime />}
                {item[tkeys[idx]] === "published" && <BsCloudArrowUpFill />}
              </div>
              <p>
                {item[tkeys[idx]][0].toUpperCase() + item[tkeys[idx]].slice(1)}
              </p>
            </div>
          );
        }

        if (
          /^https?:\/\/(?:[a-z0-9-]+\.)+[a-z]{2,6}(?:\/[^/#?]+)+\.(?:jpg|gif|png)$/.test(
            item[tkeys[idx]]
          )
        ) {
          return (
            <div className="flex space-x-2">
              <ModalImage
                className="w-12 h-12 rounded-full"
                small={item[tkeys[idx]]}
                large={item[tkeys[idx]]}
                alt="Image URL"
              />
            </div>
          );
        } else return item[tkeys[idx]];
      },
      // resize: true,
    };
  });

  COLUMNS = [
    { label: "S.No.", renderCell: (item) => item["ID"] },
    ...COLUMNS,
    {
      label: "Actions",
      renderCell: (item) => {
        return (
          <div className="flex space-x-4">
            <button
              className="bg-[#1f1fdf] text-[#eaeaea] rounded-full w-8 h-8 flex text-xl justify-center items-center"
              onClick={() => {
                handleDownload(item.roll);
              }}
            >
              <IoMdDownload />
            </button>
            {ApproveButton && (
              <button
                className="bg-[#2bb673] text-[#eaeaea] rounded-full w-8 h-8 flex text-xl justify-center items-center"
                onClick={() => {
                  ApproveButton(item.roll);
                }}
              >
                <BsCheck2Circle />
              </button>
            )}
            {RejectButton && (
              <button
                className="bg-[#ff0033] text-[#eaeaea] rounded-full w-8 h-8 flex text-xl justify-center items-center"
                onClick={() => {
                  RejectButton(item.roll);
                }}
              >
                <FaRegTimesCircle />
              </button>
            )}
          </div>
        );
      },
    },
  ];

  const getDefaults = () => {
    let defaultRatio = "";
    theads.forEach((h, idx) => {
      defaultRatio += `${100 / (theads.length + 1)}% `;
    });
    return defaultRatio;
  };

  const theme = useTheme([
    getTheme(),
    {
      Table: `
        --data-table-library_grid-template-columns: 7ch ${
          tratio.length <= 0 ? getDefaults() : tratio
        } ${100 + (ApproveButton ? 100 : 0) + (RejectButton ? 100 : 0)}px;
      `,
    },
  ]);

  const data = { nodes };

  return (
    <div className={`${className} font-poppins`}>
      <CompactTable
        columns={COLUMNS}
        data={data}
        theme={theme}
        layout={{
          custom: true,
          isDiv: true,
          fixedHeader: true,
          horizontalScroll: false,
        }}
      />
      <div className="flex justify-end space-x-4 mt-2 text-black z-30">
        <p>Total Count : {nodes.length}</p>
      </div>
    </div>
  );
};

export default Table;
