import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import "./styles/tailwind.output.css";
import Register from "./pages/Auth/Register";
import Login from "./pages/Auth/Login";
import Student from "./pages/Student";
import Faculty from "./pages/Faculty";
import Section1 from "./pages/Student/Section1";
import Section2 from "./pages/Student/Section2";
import Section3 from "./pages/Student/Section3";
import Section4 from "./pages/Student/Section4";
import Section5 from "./pages/Student/Section5";
import Section6 from "./pages/Student/Section6";
import Section7 from "./pages/Student/Section7";
import Section8 from "./pages/Student/Section8";
import Section9 from "./pages/Student/Section9";
import Section10 from "./pages/Student/Section10";
import Completed from "./pages/Student/Completed";
import Contact from "./pages/Contact";
import AllSubmissions from "./pages/Faculty/AllSubmissions";
import { Toaster } from "react-hot-toast";
import VerifyMail from "./pages/Auth/VerifyMail";
import Confirmation from "./pages/Student/Confirmation";
import Recommendations from "./pages/Faculty/Recommendations";
import NMC from "./pages/NMC";
import Refresher from "./Refresher";
import General from "./pages/NMC/General";
import Users from "./pages/NMC/Users";
import Delete from "./pages/NMC/Delete";
import ForgotPasswordStudent from "./pages/Auth/ForgotPasswordStudent";
import ForgotPasswordFaculty from "./pages/Auth/ForgotPasswordFaculty";
import Statistics from "./pages/Statistics";
import Submissions from "./pages/Faculty/Submissions";

const App = () => {
  return (
    <Refresher>
      <BrowserRouter>
        <Toaster />
        <Routes>
          <Route path="nmc-admin" element={<NMC />}>
            <Route path="users" element={<Users />} />
            <Route path="general" element={<General />} />
            <Route path="delete-submission" element={<Delete />} />
            <Route index element={<Navigate to={"general"} />} />
          </Route>
          <Route path="apply" element={<Student />}>
            <Route index element={<Navigate to={"section-1"} />} />
            <Route path="section-1" element={<Section1 />} />
            <Route path="section-2" element={<Section2 />} />
            <Route path="section-3" element={<Section3 />} />
            <Route path="section-4" element={<Section4 />} />
            <Route path="section-5" element={<Section5 />} />
            <Route path="section-6" element={<Section6 />} />
            <Route path="section-7" element={<Section7 />} />
            <Route path="section-8" element={<Section8 />} />
            <Route path="section-9" element={<Section9 />} />
            <Route path="section-10" element={<Section10 />} />
            <Route path="confirm" element={<Confirmation />} />
            <Route path="completed" element={<Completed />} />
          </Route>
          <Route path="admin-portal" element={<Faculty />}>
            <Route index element={<Navigate to={"submissions"} />} />
            <Route path="all-submissions" element={<Submissions />} />
            <Route path="submissions" element={<AllSubmissions />} />
            <Route path="recommendations" element={<Recommendations />} />
          </Route>
          <Route path="verify-mail" element={<VerifyMail />} />
          <Route path="login" element={<Login />} />
          <Route path="forgot-password-student" element={<ForgotPasswordStudent />} />
          <Route path="forgot-password-faculty" element={<ForgotPasswordFaculty />} />
          <Route path="register" element={<Navigate to={"/"} />} />
          <Route index element={<Register />} />
          <Route path="contact" element={<Contact />} />
          <Route path="stats" element={<Statistics />} />
        </Routes>
      </BrowserRouter>
    </Refresher>
  );
};

export default App;
