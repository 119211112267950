import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../components/Button";
import Heading from "../../components/Heading";
import Row from "../../components/Row";
import TextArea from "../../components/TextArea";
import Inputfield from "../../components/TextInput";
import Dropdown from "../../components/Dropdown";

import {
  fetchPostSection1,
  fetchSection1,
  fetchUploadFile,
  fetchUserDetails,
  fetchTutorsByDept,
} from "../../API/calls";
import { toast } from "react-hot-toast";
import { SubmissionContext } from ".";
import FileUpload from "../../components/FileUpload";

const Section1 = () => {
  const [name, setName] = useState("Lorem Ipsum");
  const [rollNumber, setRollNumber] = useState("00X000");
  const [dept, setDept] = useState("");
  const [fatherName, setFatherName] = useState("");
  const [yearOfJoining, setYearOfJoining] = useState("");
  const [cutOffMark, setCutOffMark] = useState("");
  const [tutorName, setTutorName] = useState("");
  const [tutorEmail, setTutorEmail] = useState("");
  const [communicationAddress, setCommunicationAddress] = useState("");
  const [permanentAddress, setPermanentAddress] = useState("");
  const [photo, setPhoto] = useState(null);
  const [photoUrl, setPhotoUrl] = useState("");
  const [tutorOptions, setTutorOptions] = useState([]);
  const [tutorValue, setTutorValue] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    if (dept.length <= 0) return;
    fetchTutorsByDept(dept)
    .then((res) => {
      setTutorOptions(res.data);
      console.log(res.data);
    })
    .catch((err) => toast.error("Error loading data. Reload!"));
  }, [dept]);
  
  useEffect(() => {
    fetchUserDetails(localStorage.getItem("rollno"))
      .then((res) => {
        setName(res.data.name);
        setRollNumber(res.data.roll);
        setDept(res.data.department);
      })
      .catch((err) => toast.error("Error loading data. Reload!"));
  }, []);

  const [submission, setSubmission] = useContext(SubmissionContext);

  useEffect(() => {
    if (!submission) return;
    const draft = submission[1];
    if (!draft) return;
    setName(draft.name);
    setRollNumber(draft.rollNo);
    setFatherName(draft.fatherName);
    setYearOfJoining(draft.yearOfJoining);
    setCutOffMark(draft.plus2CutOff ? draft.plus2CutOff : "");
    setTutorName(draft.tutorName);
    setTutorEmail(draft.tutorEmail);
    setCommunicationAddress(draft.communicationAddress);
    setPermanentAddress(draft.permanentAddress);
    setPhotoUrl(draft.photoUrl);
  }, [submission]);

  const handleProceed = async (shouldExit) => {
    if (
      name.length <= 0 ||
      rollNumber.length <= 0 ||
      fatherName.length <= 0 ||
      yearOfJoining.length <= 0 ||
      tutorName.length <= 0 ||
      tutorEmail.length <= 0 ||
      communicationAddress.length <= 0 ||
      permanentAddress.length <= 0 ||
      (photo === null && photoUrl.length <= 0)
    )
      return toast.error("Enter all the fields");

    if (photo !== null) {
      await toast.promise(fetchUploadFile(photo), {
        loading: "Uploading...",
        success: (res) => {
          setPhotoUrl(res.data.url);
          toast.promise(
            fetchPostSection1(localStorage.getItem("rollno"), {
              name: name,
              rollNo: rollNumber,
              fatherName: fatherName,
              yearOfJoining: yearOfJoining,
              plus2CutOff: cutOffMark,
              tutorName: tutorName,
              tutorEmail: tutorEmail,
              communicationAddress: communicationAddress,
              permanentAddress: permanentAddress,
              photoUrl: res.data.url,
            }),
            {
              loading: "Saving...",
              success: (res) => {
                shouldExit ? navigate("/login") : navigate("/apply/section-2");
                return "Section-1 successfully saved.";
              },
              error: (err) => {
                return `Error saving: ${err.response.data.error}`;
              },
            }
          );
          return "Uploaded";
        },
        error: "Error uploading file",
      });
    } else {
      toast.promise(
        fetchPostSection1(localStorage.getItem("rollno"), {
          name: name,
          rollNo: rollNumber,
          fatherName: fatherName,
          yearOfJoining: yearOfJoining,
          plus2CutOff: cutOffMark,
          tutorName: tutorName,
          tutorEmail: tutorEmail,
          communicationAddress: communicationAddress,
          permanentAddress: permanentAddress,
          photoUrl: photoUrl,
        }),
        {
          loading: "Saving...",
          success: (res) => {
            shouldExit ? navigate("/login") : navigate("/apply/section-2");
            return "Section-1 successfully saved.";
          },
          error: (err) => {
            return `Error saving: ${err.response.data.error}`;
          },
        }
      );
    }
  };

  return (
    <main className="w-full flex flex-col overflow-y-hidden">
      <div className="mb-8">
        <h2 className="font-poppins">Section 1</h2>
        <Heading>Personal Details</Heading>
      </div>
      <div className="overflow-y-scroll lg:pr-16 space-y-4 h-full lg:h-[calc(100vh-26rem)]">
        <Row>
          <Inputfield title="Name" valueState={[name, setName]} isDisabled />
          <Inputfield
            title="Roll Number"
            valueState={[rollNumber, setRollNumber]}
            isDisabled
          />
        </Row>
        <Row>
          <FileUpload
            fileState={[photo, setPhoto]}
            className="w-full"
            title="Attach Passport Size Photo"
            url={photoUrl}
          />
        </Row>
        <Row>
          <Inputfield
            title="Father's Name"
            valueState={[fatherName, setFatherName]}
          />
          <Inputfield
            title="Year of Joining"
            valueState={[yearOfJoining, setYearOfJoining]}
          />
          <Inputfield
            title="+2 Cut Off Mark"
            valueState={[cutOffMark, setCutOffMark]}
          />
        </Row>
        {tutorOptions.length > 0 ? (
          <React.Fragment>
            <Row>
              {/* <Inputfield
              title="Name of Tutor"
              valueState={[tutorName, setTutorName]}
              />
              <Inputfield
              title="Contact No. of Tutor"
              valueState={[tutorContactNumber, setTutorContactNumber]}
              />
              <Inputfield
              title="Email Address of Tutor"
              valueState={[tutorEmail, setTutorEmail]}
            /> */}
              <Dropdown
                title="Tutor"
                valueState={[
                  tutorName,
                  (val) => {
                    setTutorName(val);
                    const tutor = tutorOptions.find(
                      (item) => item.name === val
                    );
                    setTutorEmail(tutor.email);
                  },
                ]}
                options={tutorOptions.map((item) => item.name)}
              />
            </Row>
            <Row className="items-center">
              <p className="text-lg font-semibold">{tutorName}</p>
              <p className="text-lg">Email: {tutorEmail}</p>
            </Row>
          </React.Fragment>
        ) : (
          <p className="">Loading tutor Details...</p>
        )}
        <TextArea
          title="Communication Address"
          valueState={[communicationAddress, setCommunicationAddress]}
        />
        <TextArea
          title="Permanent Address"
          valueState={[permanentAddress, setPermanentAddress]}
        />
      </div>
      <Row className="mt-8">
        <Button
          text="Save and Proceed"
          className="w-full lg:w-1/3"
          handleClick={(e) => handleProceed(false)}
        />
        <Button
          isOutlined
          text="Save and Exit"
          className="w-full lg:w-1/3"
          handleClick={(e) => handleProceed(true)}
        />
      </Row>
    </main>
  );
};

export default Section1;
