/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useEffect, useState } from "react";
import Layout from "../Layout";
import menuItems from "./navMenuItems";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { MdKeyboardArrowDown, MdKeyboardArrowRight, MdLogout } from "react-icons/md";
import { fetchGetSubmission } from "../../API/calls";
import axios from "axios";
import { AUTH_URL } from "../../API/config";
import { BsCheck2Circle } from "react-icons/bs";
import { toast } from "react-hot-toast";
import Completed from "./Completed";

export const SubmissionContext = createContext();

const Index = () => {
  const [shown, setShown] = useState(false);
  const [submission, setSubmission] = useState(null);
  const [atStart, setAtStart] = useState(true);
  const [totallyCompleted, setTotallyCompleted] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    let details = navigator.userAgent;
    let regexp = /android|iphone|kindle|ipad/i;
    let isMobileDevice = regexp.test(details);
    if (!isMobileDevice) {
      setShown(true);
    } else {
      setShown(false);
    }
  }, []);

  useEffect(() => {
    if (
      localStorage.getItem("token") &&
      localStorage.getItem("rights") === "student" &&
      localStorage.getItem("rollno")
    ) {
      axios
        .get(`${AUTH_URL}/login/${localStorage.getItem("rollno")}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          console.log(res.data);
          fetchGetSubmission(localStorage.getItem("rollno"))
            .then((res) => setSubmission(res.data))
            .catch((err) =>
              toast.error(
                "Error loading draft submission. Retry after sometime."
              )
            );
        })
        .catch((err) => {
          localStorage.clear();
          navigate("/login");
        });
    } else {
      navigate("/login");
    }
  }, [location.pathname]);

  useEffect(() => {
    if (!submission) return;
    if (!atStart) return;
    setAtStart(false);
    // if(submission.progress
    if (submission.progress.completed) {
      setTotallyCompleted(true);
      navigate("/apply/completed");
      return;
    }
    let isCompleted = true;
    for (let i = 1; i <= 10; i++) {
      if (submission[i] === null) {
        isCompleted = false;
        navigate(`/apply/section-${i}`);
        break;
      }
    }
    isCompleted && navigate(`/apply/confirm`);
  }, [submission]);

  const handleLogout = (e) => {
    e.preventDefault();

    if (window.confirm('Are you sure you want to logout? Any unsaved changes will be lost.')) {
      localStorage.clear();
      navigate("/login");
    }
  };

  return (
    <SubmissionContext.Provider value={[submission, setSubmission]}>
      <Layout>
        <div className="w-full flex flex-col lg:flex-row h-full space-y-8 lg:space-y-0 lg:space-x-16">
          <nav className="w-full lg:w-1/3 overflow-y-auto">
            <div className="flex">
              <div className="flex-1">
                <h1 className="text-2xl text-ming font-poppins">
                  Apply for Best Outgoing Student Awards{" "}
                  {new Date().getFullYear()}
                </h1>
                <h3 className="text-xs font-poppins text-pacific">
                  Student with CGPA of 8.0 and above & without Any Reappearance (RA) should only apply.
                </h3>
              </div>
              <button className="lg:hidden" onClick={() => setShown(!shown)}>
                {shown ? (
                  <MdKeyboardArrowDown size={36} className="text-ming" />
                ) : (
                  <MdKeyboardArrowRight size={36} className="text-ming" />
                )}
              </button>
            </div>

            {shown && (
              <div className="flex flex-col w-full mt-8 lg:h-[calc(100vh-28rem)] lg:overflow-y-scroll">
                {submission && !totallyCompleted &&
                  menuItems.map((item, index) => {
                    return submission[index + 1] ? (
                      <Link
                        to={item.path}
                        key={index}
                        className={`flex flex-row items-center space-x-2 rounded-md hover:bg-gray px-4 py-2 ${location.pathname === item.path ? "bg-middle" : ""
                          }`}
                      >
                        <BsCheck2Circle size={20} className="text-[#059605]" />
                        <h3 className="text-[green] font-poppins">
                          {item.name}
                        </h3>
                      </Link>
                    ) : (
                      <div
                        key={index}
                        className={`flex flex-row items-center space-x-2 rounded-md px-4 py-2 ${location.pathname === item.path ? "bg-middle" : ""
                          }`}
                      >
                        {item.icon &&
                          React.cloneElement(item.icon, {
                            size: 20,
                            className: "text-ming",
                          })}
                        <h3 className="text-ming font-poppins">{item.name}</h3>
                      </div>
                    );
                  })}
                {
                  submission && totallyCompleted &&
                  <div
                    className={`flex flex-row items-center space-x-2 rounded-md px-4 py-2`}
                  >
                    <BsCheck2Circle size={20} className="text-ming"/>
                    <h3 className="text-ming font-poppins">Completed Submission</h3>
                  </div>
                }
              </div>
            )}
            <div className="flex-1"></div>
            <div className="flex flex-row w-full mt-8 pl-4">
              <button
                className="text-ming font-poppins flex flex-row gap-2"
                onClick={(e) => handleLogout(e)}
              >
                <MdLogout size={20} className="text-ming" />
                Logout
              </button>
            </div>
          </nav>
          <main className="w-full lg:w-2/3">
            {submission ? (
              <Outlet />
            ) : (
              <h1 className="text-xl font-poppins">
                Loading Previous draft ....
              </h1>
            )}
          </main>
        </div>
      </Layout>
    </SubmissionContext.Provider>
  );
};

export default Index;
