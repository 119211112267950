import React, { useEffect, useState } from "react";
import Button from "../../components/Button";
import Heading from "../../components/Heading";
import Row from "../../components/Row";
import TextArea from "../../components/TextArea";
import Toggle from "../../components/Toggle";
import DialogBox from "../../components/DialogBox";
import MultipleFiles from "../../components/MultipleFiles";
import { useNavigate } from "react-router-dom";
import { SubmissionContext } from ".";
import { toast } from "react-hot-toast";
import { fetchPostSection6, fetchUploadFile } from "../../API/calls";

const Section6 = () => {
  const [journal, setJournal] = useState("Yes");
  const [journalType, setJournalType] = useState("National");
  const [journalDetails, setJournalDetails] = useState("");
  const [journalUpload, setJournalUpload] = useState([]);
  const [journalUploadUrls, setJournalUploadUrls] = useState([]);

  const [conference, setConference] = useState("Yes");
  const [conferenceType, setConferenceType] = useState("National");
  const [conferenceDetails, setConferenceDetails] = useState("");
  const [conferenceUpload, setConferenceUpload] = useState([]);
  const [conferenceUploadUrls, setConferenceUploadUrls] = useState([]);
  const navigate = useNavigate();

  const [submission, setSubmission] = React.useContext(SubmissionContext);

  useEffect(() => {
    if (!submission) return;
    const draft = submission[6];
    if (!draft) return;
    setJournal(draft.havePublished ? "Yes" : "No");
    setJournalType(draft.journalType);
    setJournalDetails(draft.journalDetails);
    setJournalUploadUrls(draft.journalUploadUrl ? draft.journalUploadUrl : []);
    setConference(draft.haveConference ? "Yes" : "No");
    setConferenceType(draft.conferenceType);
    setConferenceDetails(draft.conferenceDetails);
    setConferenceUploadUrls(draft.conferenceUploadUrl ? draft.conferenceUploadUrl : []);
  }, [submission]);

  const handleJournalUpload = async (files, curr_no, total, shouldExit) => {
    if (files.length <= 0) {
      setJournalUploadUrls(journalUploadUrls);

      if (conferenceUpload.length > 0) {
        await handleConferenceUpload(conferenceUpload, 1, conferenceUpload.length, shouldExit);
      } else {
        const postBody = {
          rollNo: localStorage.getItem("rollno"),
          havePublished: journal === "Yes",
          journalType: journal === "Yes" ? journalType : null,
          journalDetails: journal === "Yes" ? journalDetails : null,
          journalUploadUrl: journal === "Yes" ? journalUploadUrls : null,
          haveConference: conference === "Yes",
          conferenceType: conference === "Yes" ? conferenceType : null,
          conferenceDetails: conference === "Yes" ? conferenceDetails : null,
          conferenceUploadUrl: conference === "Yes" ? conferenceUploadUrls : null,
        };

        toast.promise(fetchPostSection6(localStorage.getItem("rollno"), postBody), {
          loading: "Saving...",
          success: (res) => {
            shouldExit ? navigate("/login") : navigate("/apply/section-7");
            return "Section-6 Successfully Saved";
          },
          error: (err) => {
            return `Error Saving Section-6: ${err.message}`;
          },
        });
      }
    }

    const currentFile = files.pop();
    toast.promise(fetchUploadFile(currentFile), {
      loading: `Uploading ${currentFile.name} (${curr_no}/${total})`,
      success: (res) => {
        let tempFileUrls = journalUploadUrls;
        tempFileUrls.push(res.data.url);
        setJournalUploadUrls(tempFileUrls);
        handleJournalUpload(files, curr_no + 1, total, shouldExit);
        return `Uploaded (${curr_no}/${total})`;
      },
      error: (err) => {
        return `Error Uploading: ${err.message}`;
      }
    });
  };

  const handleConferenceUpload = async (files, curr_no, total, shouldExit) => {
    if (files.length <= 0) {
      setConferenceUploadUrls(conferenceUploadUrls);

      const postBody = {
        rollNo: localStorage.getItem("rollno"),
        havePublished: journal === "Yes",
        journalType: journal === "Yes" ? journalType : null,
        journalDetails: journal === "Yes" ? journalDetails : null,
        journalUploadUrl: journal === "Yes" ? journalUploadUrls : null,
        haveConference: conference === "Yes",
        conferenceType: conference === "Yes" ? conferenceType : null,
        conferenceDetails: conference === "Yes" ? conferenceDetails : null,
        conferenceUploadUrl: conference === "Yes" ? conferenceUploadUrls : null,
      };

      toast.promise(fetchPostSection6(localStorage.getItem("rollno"), postBody), {
        loading: "Saving...",
        success: (res) => {
          shouldExit ? navigate("/login") : navigate("/apply/section-7");
          return "Section-6 Successfully Saved";
        },
        error: (err) => {
          return `Error Saving Section-6: ${err.message}`;
        },
      });
    }

    const currentFile = files.pop();
    toast.promise(fetchUploadFile(currentFile), {
      loading: `Uploading ${currentFile.name} (${curr_no}/${total})`,
      success: (res) => {
        let tempFileUrls = conferenceUploadUrls;
        tempFileUrls.push(res.data.url);
        setConferenceUploadUrls(tempFileUrls);
        handleConferenceUpload(files, curr_no + 1, total, shouldExit);
        return `Uploaded (${curr_no}/${total})`;
      },
      error: (err) => {
        return `Error Uploading: ${err.message}`;
      }
    });
  };

  const handleProceed = async (shouldExit) => {
    if (
      journal === "Yes" && (
        journalType === null || journalType.length <= 0 ||
        journalDetails === null || journalDetails.length <= 0 ||
        ((journalUpload === null || journalUpload.length <= 0) &&
          (journalUploadUrls === null || journalUploadUrls.length <= 0))
      )
    ) {
      return toast.error("Please fill all the fields");
    }

    if (
      conference === "Yes" && (
        conferenceType === null || conferenceType.length <= 0 ||
        conferenceDetails === null || conferenceDetails.length <= 0 ||
        ((conferenceUpload === null || conferenceUpload.length <= 0) &&
          (conferenceUploadUrls === null || conferenceUploadUrls.length <= 0))
      )
    ) {
      return toast.error("Please fill all the fields");
    }

    if (journalUpload.length > 0) {
      await handleJournalUpload(journalUpload, 1, journalUpload.length, shouldExit);
    } else if (conferenceUpload.length > 0) {
      await handleConferenceUpload(conferenceUpload, 1, conferenceUpload.length, shouldExit);
    } else {
      const postBody = {
        rollNo: localStorage.getItem("rollno"),
        havePublished: journal === "Yes",
        journalType: journal === "Yes" ? journalType : null,
        journalDetails: journal === "Yes" ? journalDetails : null,
        journalUploadUrl: journal === "Yes" ? journalUploadUrls : null,
        haveConference: conference === "Yes",
        conferenceType: conference === "Yes" ? conferenceType : null,
        conferenceDetails: conference === "Yes" ? conferenceDetails : null,
        conferenceUploadUrl: conference === "Yes" ? conferenceUploadUrls : null,
      };

      toast.promise(fetchPostSection6(localStorage.getItem("rollno"), postBody), {
        loading: "Saving...",
        success: (res) => {
          shouldExit ? navigate("/login") : navigate("/apply/section-7");
          return "Section-6 Successfully Saved";
        },
        error: (err) => {
          return `Error Saving Section-6: ${err.message}`;
        },
      });
    }
  };

  return (
    <main className="w-full flex flex-col overflow-y-hidden">
      <div className="mb-8">
        <h2 className="font-poppins">Section 6</h2>
        <Heading>Details of Paper Published</Heading>
      </div>
      <div className="overflow-y-scroll lg:pr-16 space-y-4 h-full lg:h-[calc(100vh-26rem)]">
        <Toggle
          title={"Have you published any Journals ?"}
          options={["Yes", "No"]}
          valueState={[journal, setJournal]}
          subtext={"National Journals / International Journals with ISSN will be considered"}
        />
        {
          journal === "Yes" && (
            <div>
              <Toggle
                title={"Journal Type"}
                options={["National", "International", "Both"]}
                valueState={[journalType, setJournalType]}
              />
              <DialogBox
                className={"mt-8"}
                title="Info"
                content={
                  <div>
                    <li>Provide complete details about the journal with <strong>DOI (Digital Object Identifier)</strong></li>
                  </div>
                }
              />
              <TextArea
                className={"mt-4"}
                title={"Journal Details"}
                placeholder={"Enter the details of your journal"}
                valueState={[journalDetails, setJournalDetails]}
              />
              <MultipleFiles
                title="Attach the first page of the journal"
                fileState={[journalUpload, setJournalUpload]}
                urlState={[journalUploadUrls, setJournalUploadUrls]}
                pdf
              />
            </div>
          )
        }

        <Toggle
          title={"Have you attended any conferences ?"}
          options={["Yes", "No"]}
          valueState={[conference, setConference]}
        // subtext={"International conferences with reputation only will be considered"}
        />
        {
          conference === "Yes" && (
            <div>
              <Toggle
                title={"Conference Type"}
                options={["National", "International", "Both"]}
                valueState={[conferenceType, setConferenceType]}
              />
              <DialogBox
                className={"mt-8"}
                title="Info"
                content={
                  <div>
                    <li>Provide complete details about the conference.</li>
                  </div>
                }
              />
              <TextArea
                className={"mt-4"}
                title={"Conference Details"}
                placeholder={"Enter the details of your journal"}
                valueState={[conferenceDetails, setConferenceDetails]}
              />
              <MultipleFiles
                title="Attach the certificate of the conference"
                fileState={[conferenceUpload, setConferenceUpload]}
                urlState={[conferenceUploadUrls, setConferenceUploadUrls]}
                pdf
              />
            </div>
          )
        }
      </div>
      <Row className="mt-8">
        <Button
          text="Save and Proceed"
          className="w-full lg:w-1/3"
          handleClick={(e) => handleProceed(false)}
        />
        <Button
          isOutlined
          text="Save and Exit"
          className="w-full lg:w-1/3"
          handleClick={(e) => handleProceed(true)}
        />
      </Row>
    </main>
  );
};

export default Section6;
