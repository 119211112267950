import React, { useContext, useEffect, useState } from "react";
import Button from "../../components/Button";
import Heading from "../../components/Heading";
import Row from "../../components/Row";
import TextArea from "../../components/TextArea";
import Toggle from "../../components/Toggle";
import Dropdown from "../../components/Dropdown";
import MultipleFiles from "../../components/MultipleFiles";
import MultiSelect from "../../components/MultiSelect";
import { useNavigate } from "react-router-dom";
import { SubmissionContext } from ".";
import { toast } from "react-hot-toast";
import { fetchPostSection8, fetchUploadFile } from "../../API/calls";

const Section8 = () => {
  const [participated, setParticipated] = useState("Yes");
  const [sports, setSports] = useState([]);
  const [level, setLevel] = useState("");
  const [captain, setCaptain] = useState("");
  const [sportsDetails, setSportsDetails] = useState("")
  const [sportsCertificates, setSportsCertificates] = useState([]);
  const [sportsCertificatesURL, setSportsCertificatesURL] = useState([]);
  const [eca, setEca] = useState([]);
  const [clubActivity, setClubActivity] = useState([]);
  const [activityDetails, setActivityDetails] = useState("");
  const [activityCertificates, setActivityCertificates] = useState([]);
  const [activityCertificatesURL, setActivityCertificatesURL] = useState([]);
  const navigate = useNavigate();

  const [submission, setSubmission] = useContext(SubmissionContext);

  useEffect(() => {
    if (!submission) return;
    const draft = submission[8];
    if (!draft) return;
    setParticipated(draft.haveParticipated ? "Yes" : "No");
    setSports(draft.sportsName ? draft.sportsName : []);
    setLevel(draft.levelOfParticipation ? draft.levelOfParticipation : "");
    setCaptain(draft.haveCaptained ? "Yes" : "No");
    setSportsDetails(draft.sportsDescription ? draft.sportsDescription : "");
    setSportsCertificatesURL(draft.sportsCertificates ? draft.sportsCertificates : []);
    setEca(draft.extras ? draft.extras : []);
    setClubActivity(draft.clubs ? draft.clubs : []);
    setActivityDetails(draft.extraDescription ? draft.extraDescription : "");
    setActivityCertificatesURL(draft.extraCertificates ? draft.extraCertificates : []);
  }, [submission]);

  const handleSportsUpload = async (files, curr_no, total, shouldExit) => {
    if (files.length <= 0) {
      setSportsCertificatesURL(sportsCertificatesURL);

      if (activityCertificates.length > 0) {
        await handleActivityUpload(activityCertificates, 1, activityCertificates.length, shouldExit);
      } else {
        const postBody = {
          rollNo: localStorage.getItem("rollno"),
          haveParticipated: participated === "Yes",
          sportsName: participated === "Yes" ? (sports && sports.length > 0) ? sports : null : null,
          levelOfParticipation: participated === "Yes" ? (sports && sports.length > 0) ? level : null : null,
          haveCaptained: participated === "Yes" ? (sports && sports.length > 0) ? captain === "Yes" : null : null,
          sportsDescription: participated === "Yes" ? (sports && sports.length > 0) ? sportsDetails : null : null,
          sportsCertificates: participated === "Yes" ? (sports && sports.length > 0) ? sportsCertificatesURL : null : null,
          extras: participated === "Yes" ? (eca && eca.length > 0) ? eca : null : null,
          clubs: participated === "Yes" ? (clubActivity && clubActivity.length > 0) ? clubActivity : null : null,
          extraDescription: participated === "Yes" ? ((eca && eca.length > 0) || (clubActivity && clubActivity.length > 0)) ? activityDetails : null : null,
          extraCertificates: participated === "Yes" ? ((eca && eca.length > 0) || (clubActivity && clubActivity.length > 0)) ? activityCertificatesURL : null : null,
        };

        console.log(postBody)

        toast.promise(fetchPostSection8(localStorage.getItem("rollno"), postBody), {
          loading: "Saving...",
          success: (res) => {
            shouldExit ? navigate("/login") : navigate("/apply/section-9");
            return "Section-8 Successfully Saved";
          },
          error: (err) => {
            return `Error Saving: ${err.message}`;
          }
        });
      };
    }

    const currentFile = files.pop();
    toast.promise(fetchUploadFile(currentFile), {
      loading: `Uploading ${currentFile.name} (${curr_no}/${total})`,
      success: (res) => {
        let tempFileUrls = sportsCertificatesURL;
        tempFileUrls.push(res.data.url);
        setSportsCertificatesURL(tempFileUrls);
        handleSportsUpload(files, curr_no + 1, total, shouldExit);
        return `Uploaded ${curr_no}/${total}`;
      },
      error: (err) => {
        return `Error Uploading: ${err.message}`;
      }
    });
  };

  const handleActivityUpload = async (files, curr_no, total, shouldExit) => {
    if (files.length <= 0) {
      setActivityCertificatesURL(activityCertificatesURL);

      const postBody = {
        rollNo: localStorage.getItem("rollno"),
        haveParticipated: participated === "Yes",
        sportsName: participated === "Yes" ? (sports && sports.length > 0) ? sports : null : null,
        levelOfParticipation: participated === "Yes" ? (sports && sports.length > 0) ? level : null : null,
        haveCaptained: participated === "Yes" ? (sports && sports.length > 0) ? captain === "Yes" : null : null,
        sportsDescription: participated === "Yes" ? (sports && sports.length > 0) ? sportsDetails : null : null,
        sportsCertificates: participated === "Yes" ? (sports && sports.length > 0) ? sportsCertificatesURL : null : null,
        extras: participated === "Yes" ? (eca && eca.length > 0) ? eca : null : null,
        clubs: participated === "Yes" ? (clubActivity && clubActivity.length > 0) ? clubActivity : null : null,
        extraDescription: participated === "Yes" ? ((eca && eca.length > 0) || (clubActivity && clubActivity.length > 0)) ? activityDetails : null : null,
        extraCertificates: participated === "Yes" ? ((eca && eca.length > 0) || (clubActivity && clubActivity.length > 0)) ? activityCertificatesURL : null : null,
      };
      console.log(postBody)

      toast.promise(fetchPostSection8(localStorage.getItem("rollno"), postBody), {
        loading: "Saving...",
        success: (res) => {
          shouldExit ? navigate("/login") : navigate("/apply/section-9");
          return "Section-8 Successfully Saved";
        },
        error: (err) => {
          return `Error Saving: ${err.message}`;
        }
      });
    };

    const currentFile = files.pop();
    toast.promise(fetchUploadFile(currentFile), {
      loading: `Uploading ${currentFile.name} (${curr_no}/${total})`,
      success: (res) => {
        let tempFileUrls = activityCertificatesURL;
        tempFileUrls.push(res.data.url);
        setActivityCertificatesURL(tempFileUrls);
        handleActivityUpload(files, curr_no + 1, total, shouldExit);
        return `Uploaded ${curr_no}/${total}`;
      },
      error: (err) => {
        console.log(err);
        return `Error Uploading: ${err.message}`;
      }
    });
  };

  const handleProceed = async (shouldExit) => {
    if (
      participated === "Yes" && (
        sports.length === 0 && eca.length === 0 && clubActivity.length === 0
      )
    ) {
      return toast.error("Please fill all the fields");
    }

    if (
      sports.length > 0 &&
      (level === "" || captain === "" || sportsDetails === "" || (sportsCertificates.length === 0 && sportsCertificatesURL.length === 0))
    ) {
      return toast.error("Please fill all the fields");
    }

    if (
      (eca.length > 0 || clubActivity.length > 0) &&
      (activityDetails === "" || (activityCertificates.length === 0 && activityCertificatesURL.length === 0))
    ) {
      return toast.error("Please fill all the fields");
    }

    if (sportsCertificates.length > 0) {
      await handleSportsUpload(sportsCertificates, 1, sportsCertificates.length, shouldExit);
    } else if (activityCertificates.length > 0) {
      await handleActivityUpload(activityCertificates, 1, activityCertificates.length, shouldExit);
    } else {
      const postBody = {
        rollNo: localStorage.getItem("rollno"),
        haveParticipated: participated === "Yes",
        sportsName: participated === "Yes" ? (sports && sports.length > 0) ? sports : null : null,
        levelOfParticipation: participated === "Yes" ? (sports && sports.length > 0) ? level : null : null,
        haveCaptained: participated === "Yes" ? (sports && sports.length > 0) ? captain === "Yes" : null : null,
        sportsDescription: participated === "Yes" ? (sports && sports.length > 0) ? sportsDetails : null : null,
        sportsCertificates: participated === "Yes" ? (sports && sports.length > 0) ? sportsCertificatesURL : null : null,
        extras: participated === "Yes" ? (eca && eca.length > 0) ? eca : null : null,
        clubs: participated === "Yes" ? (clubActivity && clubActivity.length > 0) ? clubActivity : null : null,
        extraDescription: participated === "Yes" ? ((eca && eca.length > 0) || (clubActivity && clubActivity.length > 0)) ? activityDetails : null : null,
        extraCertificates: participated === "Yes" ? ((eca && eca.length > 0) || (clubActivity && clubActivity.length > 0)) ? activityCertificatesURL : null : null,
      };
      console.log(postBody)

      toast.promise(fetchPostSection8(localStorage.getItem("rollno"), postBody), {
        loading: "Saving...",
        success: (res) => {
          shouldExit ? navigate("/login") : navigate("/apply/section-9");
          return "Section-8 Successfully Saved";
        },
        error: (err) => {
          return `Error Saving: ${err.message}`;
        }
      });
    }

  };

  return (
    <main className="w-full flex flex-col overflow-y-hidden">
      <div className="mb-8">
        <h2 className="font-poppins">Section 8</h2>
        <Heading>Activities Participated</Heading>
      </div>
      <div className="overflow-y-scroll lg:pr-16 space-y-4 h-full lg:h-[calc(100vh-26rem)]">
        <Toggle
          title={"Have you participated in any Sports, Co-Curricular, Extra-Curricular or Club Activities ?"}
          options={["Yes", "No"]}
          valueState={[participated, setParticipated]}
        />
        {
          participated === "Yes" && (
            <div>
              <MultiSelect
                className="w-full lg:w-[50%]"
                title="Sports"
                placeholder="Select Sports"
                valueState={[sports, setSports]}
                options={["Athletics", "Badminton", "Ball Badminton", "Basketball", "Boxing", "Cricket", "Chess", "Cycling", "Football", "Fencing", "Handball", "Hockey", "Judo", "Karate", "Kho-Kho", "Rifle Shooting", "Sepaktakraw", "Swimming", "Taekwondo", "Table Tennis", "Tennis", "Throwball", "Volleyball", "Yoga"]}
              />

              {
                sports.length > 0 && (
                  <div>
                    <Row className="items-center">
                      <Dropdown
                        className="w-full lg:w-[50%]"
                        title="Level of Participation"
                        placeholder="Select Level"
                        valueState={[level, setLevel]}
                        options={["Inter College", "District", "State", "National"]}
                      />
                      <Toggle
                        className="w-full lg:w-[50%]"
                        title={"Have you captained the team ?"}
                        options={["Yes", "No"]}
                        valueState={[captain, setCaptain]}
                      />
                    </Row>
                    <TextArea
                      title="Please mention about your role of play and achievements in detail"
                      valueState={[sportsDetails, setSportsDetails]}
                    />
                    <MultipleFiles
                      title="Attach the sports certificate(s) that you have achieved during your period of study (In PDF format)"
                      fileState={[sportsCertificates, setSportsCertificates]}
                      urlState={[sportsCertificatesURL, setSportsCertificatesURL]}
                      pdf
                    />
                  </div>
                )
              }
              <MultiSelect
                className="w-full lg:w-[50%]"
                title="Extra Curricular Activities"
                placeholder="Select Extra Curricular Activities"
                valueState={[eca, setEca]}
                options={["NSS", "NCC", "Hostel", "Placement Representative", "Class Representative", "Dr GRD Memorial Library", "Other"]}
              />
              <MultiSelect
                className="w-full lg:w-[50%]"
                title="Club Activities"
                placeholder="Select Club Activities"
                valueState={[clubActivity, setClubActivity]}
                options={["YRC", "Martial Arts", "Fine Arts Club", "Tamil Mandram", "Other"]}
              />

              {
                (eca.length > 0 || clubActivity.length > 0) && (
                  <div>
                    <TextArea
                      title="Please mention about your position in the club and / or extra curricular activities carried out in detail"
                      valueState={[activityDetails, setActivityDetails]}
                    />
                    <MultipleFiles
                      title="Attach the certificates of the activities that you have achieved during your period of study (In PDF format)"
                      fileState={[activityCertificates, setActivityCertificates]}
                      urlState={[activityCertificatesURL, setActivityCertificatesURL]}
                      pdf
                    />
                  </div>
                )
              }
            </div>
          )
        }
      </div>
      <Row className="mt-8">
        <Button text="Save and Proceed" className="w-full lg:w-1/3" handleClick={e => handleProceed(false)} />
        <Button isOutlined text="Save and Exit" className="w-full lg:w-1/3" handleClick={e => handleProceed(true)} />
      </Row>
    </main>
  );
};

export default Section8;
