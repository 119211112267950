import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { fetchVerifyUserMail } from "../../API/calls";
import { toast } from "react-hot-toast";

const VerifyMail = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const navigate = useNavigate();

  useEffect(() => {
    toast.promise(fetchVerifyUserMail({ token: searchParams.get("token") }), {
      loading: "Verifying...",
      success: (res) => {
        navigate("/login");
        return "Verified successfully.";
      },
      error: (err) => {
        return `Retry again: ${err?.response?.data?.error}`;
      },
    });
  }, []);

  return <div>VerifyMail</div>;
};

export default VerifyMail;
