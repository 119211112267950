import React, { useEffect, useState } from "react";
import Heading from "../../components/Heading";
import Row from "../../components/Row";
import Inputfield from "../../components/TextInput";
import Button from "../../components/Button";
import DialogBox from "../../components/DialogBox";
import {
  fetchAllSubmissions,
  fetchGetSettings,
  fetchPostSettings,
} from "../../API/calls";
import { toast } from "react-hot-toast";
import Table from "../../components/Table";
import generateSubmissionTemplate from "../../template-generators/generateSubmissionTemplate";

const Users = () => {
  const [submission, setSubmission] = useState(null);
  const [data, setData] = useState(null);

  useEffect(() => {
    toast.promise(fetchAllSubmissions(), {
      loading: "Fetching data...",
      success: (res) => {
        setSubmission(res.data.data);
        return "Sucessfully fetched";
      },
      error: "Error loading submissions",
    });
  }, []);

  useEffect(() => {
    if (!submission) return;
    setData(
      submission.map((sub) => {
        return {
          image_url: sub[1].photoUrl,
          name: sub.userDetails.name,
          roll: sub.userDetails.rollNo,
          dept: sub.userDetails.dept,
        };
      })
    );
  }, [submission]);

  const handleDownload = (roll) => {
    const sub = submission.find((sub) => sub.rollno === roll);
    generateSubmissionTemplate(sub);
  };

  return (
    <main className="w-full flex flex-col overflow-y-hidden">
      <div className="mb-8">
        <Heading>Users</Heading>
      </div>
      {data ? (
        <React.Fragment>
          <Table
            theads={["Profile", "Name", "Roll No.", "Dept."]}
            tdata={data}
            tkeys={["image_url", "name", "roll", "dept"]}
            className={`${
              data.length < 8
                ? "max-h-[calc(100vh-20rem)]"
                : "h-[calc(100vh-25rem)]"
            } w-full mt-8`}
            tratio="100px 1fr 100px 1fr"
            handleDownload={handleDownload}
          />
          <p className="text-white select-none">
            dfghdfg
            <br />
            fgiydg
          </p>
        </React.Fragment>
      ) : (
        <p className="text-lg font-poppins mt-6">Loading...</p>
      )}
    </main>
  );
};

export default Users;
