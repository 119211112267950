import React, { useContext, useEffect, useState } from "react";
import Button from "../../components/Button";
import Heading from "../../components/Heading";
import Row from "../../components/Row";
import Inputfield from "../../components/TextInput";
import Toggle from "../../components/Toggle";
import FileUpload from "../../components/FileUpload";
import { useNavigate } from "react-router-dom";
import { SubmissionContext } from ".";
import { toast } from "react-hot-toast";
import { fetchPostSection5, fetchUploadFile } from "../../API/calls";

const Section5 = () => {
  const [placement, setPlacement] = useState("On Campus");
  const [companyName, setCompanyName] = useState("");
  const [offerLetter, setOfferLetter] = useState(null);
  const [offerLetterUrl, setOfferLetterUrl] = useState("");
  const [reason, setReason] = useState("Higher Studies");
  const [higherStudies, setHigherStudies] = useState("India");
  const [universityName, setUniversityName] = useState("");
  const [admissionLetter, setAdmissionLetter] = useState(null);
  const [admissionLetterUrl, setAdmissionLetterUrl] = useState("");
  const navigate = useNavigate();

  const [submission, setSubmission] = useContext(SubmissionContext);

  useEffect(() => {
    if (!submission) return;
    const draft = submission[5];
    if (!draft) return;
    setPlacement(draft.placement);
    setCompanyName(draft.companyName);
    setOfferLetterUrl(draft.offerLetterUrl);
    setReason(draft.reason);
    setHigherStudies(draft.higherStudies);
    setUniversityName(draft.universityName);
    setAdmissionLetterUrl(draft.admissionLetterUrl);
  }, [submission]);


  const handleProceed = async (shouldExit) => {
    if (
      placement.length <= 0 ||
      (
        (placement === "On Campus" || placement === "Off Campus") &&
        (companyName.length <= 0 || (offerLetter === null && offerLetterUrl.length <= 0))
      ) ||
      (
        placement === "Not Opted for Placement" &&
        (reason === null || reason.length <= 0 ||
          (reason === "Higher Studies" &&
            (higherStudies === null || higherStudies.length <= 0 ||
              ((higherStudies === "India" || higherStudies === "Abroad")
                && (universityName === null || universityName.length <= 0 ||
                  (admissionLetter === null && (admissionLetterUrl === null || admissionLetterUrl.length <= 0))))))))

    ) {
      return toast.error("Enter all the fields");
    }

    if (offerLetter !== null) {
      toast.promise(fetchUploadFile(offerLetter), {
        loading: "Uploading Offer Letter",
        success: (res) => {
          setOfferLetterUrl(res.data.url);

          const postBody = {
            rollNo: localStorage.getItem("rollno"),
            placement: placement,
            companyName: companyName,
            offerLetterUrl: res.data.url,
            reason: null,
            higherStudies: null,
            universityName: null,
            admissionLetterUrl: null,
          };

          toast.promise(fetchPostSection5(localStorage.getItem("rollno"), postBody), {
            loading: "Saving Section 5",
            success: (res) => {
              shouldExit ? navigate("/login") : navigate("/apply/section-6");
              return "Section-5 Successfully Saved";
            },
            error: (err) => {
              return `Error Saving Section 5: ${err.message}`;
            },
          });

          return "Offer Letter Uploaded Successfully";
        },
        error: (err) => {
          return `Error Uploading Offer Letter: ${err.message}`;
        },
      });
    } else if (admissionLetter !== null) {
      toast.promise(fetchUploadFile(admissionLetter), {
        loading: "Uploading Admission Letter",
        success: (res) => {
          setAdmissionLetterUrl(res.data.url);

          const postBody = {
            rollNo: localStorage.getItem("rollno"),
            placement: placement,
            companyName: null,
            offerLetterUrl: null,
            reason: reason,
            higherStudies: higherStudies,
            universityName: universityName,
            admissionLetterUrl: res.data.url,
          };

          toast.promise(fetchPostSection5(localStorage.getItem("rollno"), postBody), {
            loading: "Saving Section 5",
            success: (res) => {
              shouldExit ? navigate("/login") : navigate("/apply/section-6");
              return "Section-5 Successfully Saved";
            },
            error: (err) => {
              return `Error Saving Section 5: ${err.message}`;
            },
          });

          return "Admission Letter Uploaded Successfully";
        },
        error: (err) => {
          return `Error Uploading Admission Letter: ${err.message}`;
        },
      });
    } else {
      let postBody = {
        rollNo: localStorage.getItem("rollno"),
        placement: placement,
      }

      if (placement === "On Campus" || placement === "Off Campus") {
        postBody = {
          ...postBody,
          companyName: companyName,
          offerLetterUrl: offerLetterUrl,
          reason: null,
          higherStudies: null,
          universityName: null,
          admissionLetterUrl: null,
        };
      } else if (placement === "Not Secured Placement") {
        postBody = {
          ...postBody,
          companyName: null,
          offerLetterUrl: null,
          reason: null,
          higherStudies: null,
          universityName: null,
          admissionLetterUrl: null,
        };
      } else if (placement === "Not Opted for Placement") {
        postBody = {
          ...postBody,
          companyName: null,
          offerLetterUrl: null,
        }

        if (reason === "Higher Studies") {
          postBody = {
            ...postBody,
            reason: reason,
            higherStudies: higherStudies,
          };

          if (higherStudies === "India" || higherStudies === "Abroad") {
            postBody = {
              ...postBody,
              universityName: universityName,
              admissionLetterUrl: admissionLetterUrl,
            };
          } else {
            postBody = {
              ...postBody,
              universityName: null,
              admissionLetterUrl: null,
            };
          }
        } else {
          postBody = {
            ...postBody,
            reason: reason,
            higherStudies: null,
            universityName: null,
            admissionLetterUrl: null,
          };
        }
      }

      toast.promise(fetchPostSection5(localStorage.getItem("rollno"), postBody), {
        loading: "Saving Section 5",
        success: (res) => {
          shouldExit ? navigate("/login") : navigate("/apply/section-6");
          return "Section-5 Successfully Saved";
        },
        error: (err) => {
          return `Error Saving Section 5: ${err.message}`;
        },
      });
    }
  };

  return (
    <main className="w-full flex flex-col overflow-y-hidden">
      <div className="mb-8">
        <h2 className="font-poppins">Section 5</h2>
        <Heading>Placement / Higher Studies</Heading>
      </div>
      <div className="overflow-y-scroll lg:pr-16 space-y-4 h-full lg:h-[calc(100vh-26rem)]">
        <Toggle
          title={"Placement through"}
          options={["On Campus", "Off Campus", "Not Secured Placement", "Not Opted for Placement"]}
          valueState={[placement, setPlacement]}
          className=""
        />
        {
          (placement === "On Campus" || placement === "Off Campus") && (
            <div>
              <Row className="items-center">
                <Inputfield
                  title="Name of the Company"
                  valueState={[companyName, setCompanyName]}
                />
                <FileUpload
                  title="Offer Letter (Attach as PDF Format)"
                  fileState={[offerLetter, setOfferLetter]}
                  url={offerLetterUrl}
                  pdf
                />
              </Row>
            </div>
          )
        }

        {
          (placement === "Not Opted for Placement") && (
            <div>
              <Toggle
                title={"Reason for not opting for placement"}
                options={["Higher Studies", "Self Employment"]}
                valueState={[reason, setReason]}
              />

              {
                reason === "Higher Studies" && (
                  <div>
                    <Toggle
                      className="mt-4"
                      title={"Place where you have secured admission"}
                      options={["India", "Abroad", "Not Yet Secured Admission (Planned for Higher Studies)"]}
                      valueState={[higherStudies, setHigherStudies]}
                    />
                    {
                      (higherStudies === "India" || higherStudies === "Abroad") && (
                        <div className="mt-4">
                          <Row className="items-center">
                            <Inputfield
                              title="Name of the University"
                              valueState={[universityName, setUniversityName]}
                            />
                            <FileUpload
                              title="Proof of Admission (Attach as PDF Format)"
                              fileState={[admissionLetter, setAdmissionLetter]}
                              url={admissionLetterUrl}
                              pdf
                            />
                          </Row>
                        </div>
                      )
                    }
                  </div>
                )
              }
            </div>
          )
        }
      </div>
      <Row className="mt-8">
        <Button
          text="Save and Proceed"
          className="w-full lg:w-1/3"
          handleClick={(e) => handleProceed(false)}
        />
        <Button
          isOutlined
          text="Save and Exit"
          className="w-full lg:w-1/3"
          handleClick={(e) => handleProceed(true)}
        />
      </Row>
    </main>
  );
};

export default Section5;
