import { PDFDocument } from "pdf-lib";
import jsPDF from "jspdf";
import html2pdf from "html2pdf.js";

import {
  userProfile,
  personalDetails,
  academicsDetials,
  internshipDetails,
  abroadStudies,
  placementHigherStudiesDetails,
  researchPapersDetails,
  recognition,
  sportsDetails,
  competitiveExamDetails,
  headingPage,
  imageAttachement,
  signatureTemplate,
  statementOfPurpose,
} from "./content";

const generateSubmissionTemplate = async (submission) => {
  const multiplePdfs = [];
  const user = submission;

  const header = `
  <html>
      <body
        style="
          display: block;
          width: 100%;
          height: 100%;
          page-break-inside: avoid;
        "
        id="content"/>
         <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link
      href="https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap"
      rel="stylesheet"
    >
        `;

  const footer = `</body></html>`;

  const htmlTemplate =
    header +
    userProfile(user) +
    personalDetails(user) +
    academicsDetials(user) +
    internshipDetails(user) +
    abroadStudies(user) +
    placementHigherStudiesDetails(user) +
    researchPapersDetails(user) +
    recognition(user) +
    sportsDetails(user) +
    competitiveExamDetails(user) +
    statementOfPurpose(user) +
    footer;

  console.log(internshipDetails(user));

  const sample = async () => {
    var element = htmlTemplate;
    var opt = {
      margin: 0.5,
      filename: "myfile.pdf",
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: {
        letterRendring: true,
        scale: 2,
        dpi: 162,
        useCORS: true,
      },
      pagebreak: {
        mode: ['avoid-all', 'css', 'legacy'],
        after: ".breakPage",
        avoid: ["tr", "td", "th", "table"],
      },
      jsPDF: { unit: "in", format: "a4", orientation: "p" },
    };

    await html2pdf()
      .from(element)
      .set(opt)
      .toPdf()
      .output("datauristring")
      .then(async function (pdfAsString) {
        let currdata = await PDFDocument.load(pdfAsString, { ignoreEncryption: true });
        multiplePdfs.push(currdata);
      });
  };

  const signaturePage = async () => {
    var element = header + signatureTemplate(user) + footer;
    var opt = {
      margin: 0.5,
      filename: "myfile.pdf",
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: {
        letterRendring: true,
        scale: 1.5,
        dpi: 192,
        useCORS: true,
      },
      pagebreak: { mode: "avoid-all", after: ".breakPage" },
      jsPDF: { unit: "in", format: "a4", orientation: "p" },
    };

    await html2pdf()
      .from(element)
      .set(opt)
      .toPdf()
      .output("datauristring")
      .then(async function (pdfAsString) {
        let currdata = await PDFDocument.load(pdfAsString, { ignoreEncryption: true });
        multiplePdfs.push(currdata);
      });
  };

  const documentAdder = async () => {
    await sample();
    await internshipDocuments();
    await abroadDocuments();
    await offerOrHigherEducationDocuments();
    await researchDocuments();
    await sportsDocuments();
    await competitiveExamCertificates();
    await signaturePage();
    await mergePdfs();
  };

  async function insertHeadingPage(data, user) {
    // jsPdf code ::

    // var htmlToPdf = new jsPDF("p", "pt", "a4");
    // await htmlToPdf.html(header + headingPage(data, user) + footer, {
    //   callback: async function (pdf) {
    //     var arrayBuffer = pdf.output("arraybuffer");
    //     let currdata = await PDFDocument.load(arrayBuffer, {ignoreEncryption: true});
    //     multiplePdfs.push(currdata);
    //   },
    //   margin: [15, 15, 15, 15],
    //   x: 0,
    //   y: 0,
    //   width: 550, //target width in the PDF document
    //   windowWidth: 720,
    // });

    var opt = {
      margin: 0.5,
      filename: "myfile.pdf",
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: {
        letterRendring: true,
        scale: 1.5,
        dpi: 192,
        useCORS: true,
      },
      pagebreak: { mode: "avoid-all", after: ".breakPage" },
      jsPDF: { unit: "in", format: "a4", orientation: "p" },
    };

    await html2pdf()
      .from(header + headingPage(data, user) + footer)
      .set(opt)
      .toPdf()
      .output("datauristring")
      .then(async function (pdfAsString) {
        let currdata = await PDFDocument.load(pdfAsString, { ignoreEncryption: true });
        multiplePdfs.push(currdata);
      });
  }

  async function insertImageAttachements(url) {
    var opt = {
      margin: 0.5,
      filename: "myfile.pdf",
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2, useCORS: true },
      jsPDF: { unit: "in", format: "a4", orientation: "p" },
    };

    await html2pdf()
      .from(header + imageAttachement(url) + footer)
      .set(opt)
      .toPdf()
      .output("datauristring")
      .then(async function (pdfAsString) {
        let currdata = await PDFDocument.load(pdfAsString, { ignoreEncryption: true });
        multiplePdfs.push(currdata);
      });
  }

  async function imageOrPdf(url) {
    if (url.includes(".jpg") || url.includes(".png") || url.includes(".jpeg")) {
      await insertImageAttachements(url);
    } else {
      if (!Array.isArray(url) && url.includes(",")) {
        var urls = url.split(",");
        for (let i = 0; i < urls.length; i++) {
          await collectPdfs(urls[i]);
        }
      } else {
        await collectPdfs(url);
      }
    }
  }

  async function internshipDocuments() {
    if (
      user != null &&
      user[3] != null &&
      user[3].proofUrl != null &&
      user[3].proofUrl.length !== 0
    ) {
      await insertHeadingPage("Internship Attachments", user);
      for (let i = 0; i < user[3].proofUrl.length; i++) {
        if (user[3].proofUrl[i] != null) {
          await imageOrPdf(user[3].proofUrl[i]);
        }
      }
    }
  }

  async function abroadDocuments() {
    if (user != null && user[4] != null) {
      if (user[4].proofUrl != null) {
        await insertHeadingPage("Abroad Semester Attachments", user);
        await imageOrPdf(user[4].proofUrl);
      }
    }
  }

  async function offerOrHigherEducationDocuments() {
    if (user != null && user[5] != null) {
      if (user[5].offerLetterUrl != null) {
        await insertHeadingPage("Placement Attachments", user);
        await imageOrPdf(user[5].offerLetterUrl);
      }

      if (user[5].admissionLetterUrl != null) {
        await insertHeadingPage("Higher Studies Attachements", user);
        await imageOrPdf(user[5].admissionLetterUrl);
      }
    }
  }

  async function researchDocuments() {
    if (user != null && user[6] != null) {
      if (user[6].journalUploadUrl != null) {
        if (user[6].journalUploadUrl.length >= 1)
          await insertHeadingPage("Journal Attachments", user);
        for (var i = 0; i < user[6].journalUploadUrl.length; i++) {
          await imageOrPdf(user[6].journalUploadUrl[i]);
        }
      }

      if (user[6].conferenceUploadUrl != null) {
        if (user[6].conferenceUploadUrl.length >= 1)
          await insertHeadingPage("Conference Attachments", user);
        for (var i = 0; i < user[6].conferenceUploadUrl.length; i++) {
          await imageOrPdf(user[6].conferenceUploadUrl[i]);
        }
      }
    }
  }

  async function sportsDocuments() {
    if (user != null && user[8] != null) {
      if (user[8].sportsCertificates != null) {
        if (user[8].sportsCertificates.length >= 1)
          await insertHeadingPage("Sports Certificates", user);
        for (let i = 0; i < user[8].sportsCertificates.length; i++) {
          await imageOrPdf(user[8].sportsCertificates[i]);
        }
      }

      if (user[8].extraCertificates != null) {
        await insertHeadingPage(
          "Extra Curricular Activities Certificates",
          user
        );
        await imageOrPdf(user[8].extraCertificates);
      }
    }
  }

  async function competitiveExamCertificates() {
    if (user != null && user[9] != null) {
      if (user[9].certificates != null) {
        if (user[9].certificates.length >= 1)
          await insertHeadingPage("Competitive Exam Certificates", user);
        for (let i = 0; i < user[9].certificates.length; i++) {
          await imageOrPdf(user[9].certificates[i]);
        }
      }
    }
  }

  async function collectPdfs(url) {
    if (Array.isArray(url)) {
      for (let i = 0; i < url.length; i++) {
        var existingPdfBytes = await fetch(url[i]).then((res) =>
          res.arrayBuffer()
        );
        var pdfDoc = await PDFDocument.load(existingPdfBytes, { ignoreEncryption: true });
        multiplePdfs.push(pdfDoc);
      }
    } else {
      var existingPdfBytes = await fetch(url).then((res) => res.arrayBuffer());
      var pdfDoc = await PDFDocument.load(existingPdfBytes, { ignoreEncryption: true });
      multiplePdfs.push(pdfDoc);
    }
  }

  async function mergePdfs() {
    const doc = await PDFDocument.create();
    var pageNumber = 1;
    for (let i = 0; i < multiplePdfs.length; i++) {
      try {
        const data = multiplePdfs[i];
        const contentPages1 = await doc.copyPages(data, data.getPageIndices());
        for (const page of contentPages1) {
          page.drawText(pageNumber + "", {
            x: 560,
            y: 35,
            size: 15,
          });
          doc.addPage(page);
          pageNumber += 1;
        }
      } catch (err) {
        console.log(err)
      }
    }

    const data = await doc.save("myPdf.pdf");
    var file = new Blob([data], { type: "application/pdf" });

    // to open the pdf in new tab
    // var fileURL = URL.createObjectURL(file);
    // window.open(fileURL);

    // to download the file.
    const link = document.createElement("a");
    link.href = URL.createObjectURL(file);
    link.download = `${user?.userDetails?.rollNo.toUpperCase()} - ${user?.userDetails?.name
      } BOGS Application.pdf`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  return documentAdder();
};

export default generateSubmissionTemplate;
