import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../components/Button";
import Heading from "../../components/Heading";
import Row from "../../components/Row";
import Inputfield from "../../components/TextInput";
import Toggle from "../../components/Toggle";
import { toast } from "react-hot-toast";
import { SubmissionContext } from ".";
import { fetchPostSection2 } from "../../API/calls";

const Section2 = () => {
  const [graduate, setGraduate] = useState("UG");
  const [programStudying, setProgramStudying] = useState("");
  const [semester1, setSemester1] = useState("");
  const [semester2, setSemester2] = useState("");
  const [semester3, setSemester3] = useState("");
  const [semester4, setSemester4] = useState("");
  const [semester5, setSemester5] = useState("");
  const [semester6, setSemester6] = useState("");
  const [semester7, setSemester7] = useState("");
  const [semester8, setSemester8] = useState("");
  const [semester9, setSemester9] = useState("");
  const [semester10, setSemester10] = useState("");
  const [cgpa, setCgpa] = useState("");
  const [theoryCourses, setTheoryCourses] = useState("");
  
  const navigate = useNavigate();

  const [submission, setSubmission] = useContext(SubmissionContext);


  useEffect(() => {
    setSemester1("");
    setSemester2("");
    setSemester3("");
    setSemester4("");
    setSemester5("");
    setSemester6("");
    setSemester7("");
    setSemester8("");
    setSemester9("");
    setSemester10("");
    setCgpa("");
    setTheoryCourses("");
  }, [programStudying, graduate]);

  useEffect(() => {
    if (!submission) return;
    const draft = submission[2];
    if (!draft) return;
    setGraduate((draft.programStudying === "BE / BTech" || draft.programStudying === "BE Sandwich" || draft.programStudying === "BSc") ? "UG" : "PG");
    setProgramStudying(draft.programStudying);
    setSemester1(draft.GPA1 || "");
    setSemester2(draft.GPA2 || "");
    setSemester3(draft.GPA3 || "");
    setSemester4(draft.GPA4 || "");
    setSemester5(draft.GPA5 || "");
    setSemester6(draft.GPA6 || "");
    setSemester7(draft.GPA7 || "");
    setSemester8(draft.GPA8 || "");
    setSemester9(draft.GPA9 || "");
    setSemester10(draft.GPA10 || "");
    setCgpa(draft.CGPA);
    setTheoryCourses(draft.theoryRemaining ? "Yes" : "No");
  }, [submission]);

  const handleProceed = (shouldExit) => {
    if (
      graduate.length <= 0 ||
      programStudying.length <= 0 ||
      (graduate === "UG" && (programStudying !== "BSc" && programStudying !== "BE / BTech" && programStudying !== "BE Sandwich")) ||
      (graduate === "PG" && (programStudying !== "MSc Integrated" && programStudying !== "ME / MTech / MSc" && programStudying !== "MCA" && programStudying !== "MBA")) ||

      ((programStudying !== "ME / MTech / MSc" && programStudying !== "MBA") && (
        semester4.length <= 0 ||
        semester5.length <= 0 ||

        ((programStudying !== "BSc" && programStudying !== "MCA") && (
          semester6.length <= 0 ||
          semester7.length <= 0 ||

          ((programStudying !== "BE / BTech") && (
            semester8.length <= 0 ||
            semester9.length <= 0
          ))

        ))

      )) ||
      
      cgpa.length <= 0 ||
      theoryCourses.length <= 0
    )
      return toast.error("Enter all the fields");

    const postBody = {
      rollNo: localStorage.getItem("rollno"),
      programStudying: programStudying,
      GPA1: semester1,
      GPA2: semester2,
      GPA3: semester3,
      GPA4: (programStudying === "BSc" || programStudying === "BE / BTech" || programStudying === "BE Sandwich" || programStudying === "MSc Integrated" || programStudying === "MCA") ? semester4 : null,
      GPA5: (programStudying === "BSc" || programStudying === "BE / BTech" || programStudying === "BE Sandwich" || programStudying === "MSc Integrated" || programStudying === "MCA") ? semester5 : null,
      GPA6: (programStudying === "BE / BTech" || programStudying === "BE Sandwich" || programStudying === "MSc Integrated") ? semester6 : null,
      GPA7: (programStudying === "BE / BTech" || programStudying === "BE Sandwich" || programStudying === "MSc Integrated") ? semester7 : null,
      GPA8: (programStudying === "BE Sandwich" || programStudying === "MSc Integrated") ? semester8 : null,
      GPA9: (programStudying === "BE Sandwich" || programStudying === "MSc Integrated") ? semester9 : null,
      GPA10: null,
      CGPA: cgpa,
      theoryRemaining: theoryCourses === "Yes",
    };

    toast.promise(fetchPostSection2(localStorage.getItem("rollno"), postBody), {
      loading: "Saving...",
      success: (res) => {
        shouldExit ? navigate("/login") : navigate("/apply/section-3");
        return "Section-2 successfully saved.";
      },
      error: (err) => {
        return `Error saving: ${err.response.data.error}`;
      },
    });
  };

  return (
    <main className="w-full flex flex-col overflow-y-hidden">
      <div className="mb-8">
        <h2 className="font-poppins">Section 2</h2>
        <Heading>Academic Information</Heading>
      </div>
      <div className="overflow-y-scroll lg:pr-16 space-y-4 h-full lg:h-[calc(100vh-26rem)]">
        <Toggle
          title={"Graduate Programme"}
          options={["UG", "PG"]}
          valueState={[graduate, setGraduate]}
        />

        {graduate === "UG" && (
          <div className="space-y-4">
            <Toggle
              title={"Programme Studying"}
              options={["BE / BTech", "BE Sandwich", "BSc"]}
              valueState={[programStudying, setProgramStudying]}
            />
            <p className="text-ming text-base font-poppins mt-4">Enter your GPA</p>
            <Row>
              <Inputfield
                title="Semester 1"
                valueState={[semester1, setSemester1]}
              />
              <Inputfield
                title="Semester 2"
                valueState={[semester2, setSemester2]}
              />
              <Inputfield
                title="Semester 3"
                valueState={[semester3, setSemester3]}
              />
              <Inputfield
                title="Semester 4"
                valueState={[semester4, setSemester4]}
              />
            </Row>
            <Row className={`${programStudying === "BSc" ? "w-full lg:w-1/2" : ""}`} >
              <Inputfield
                title="Semester 5"
                valueState={[semester5, setSemester5]}
                className={`${programStudying === "BSc" ? "w-full lg:w-1/2" : "w-full lg:w-1/4"}`}
              />
              <Inputfield
                title="Semester 6"
                valueState={[semester6, setSemester6]}
                className={`${programStudying === "BSc" ? "w-full lg:w-1/2" : "w-full lg:w-1/4"}`}
                placeholder={(programStudying === "BSc") ? "N/A" : ""}
                isDisabled={(programStudying === "BSc")}
              />
              {programStudying !== "BSc" && (
                <Row className="w-full lg:w-1/2" >
                  <Inputfield
                    title="Semester 7"
                    valueState={[semester7, setSemester7]}
                    className="w-full lg:w-1/2"
                  />
                  <Inputfield
                    title="Semester 8"
                    valueState={[semester8, setSemester8]}
                    className="w-full lg:w-1/2"
                    placeholder={(programStudying === "BE / BTech") ? "N/A" : ""}
                    isDisabled={(programStudying === "BE / BTech")}
                  />
                </Row>
              )}
            </Row>
            {programStudying === "BE Sandwich" && (
              <Row className="w-full lg:w-[50%]">
                <Inputfield
                  title="Semester 9"
                  valueState={[semester9, setSemester9]}
                />
                <Inputfield
                  title="Semester 10"
                  valueState={[semester10, setSemester10]}
                  placeholder={(programStudying === "BE Sandwich") ? "N/A" : ""}
                  isDisabled={(programStudying === "BE Sandwich")}
                />
              </Row>
            )}
          </div>
        )}

        {graduate === "PG" && (
          <div className="space-y-4">
            <Toggle
              title={"Program Studying"}
              options={["ME / MTech / MSc", "MBA", "MCA", "MSc Integrated"]}
              valueState={[programStudying, setProgramStudying]}
            />
            <p className="text-ming text-base font-poppins mt-4">Enter your GPA</p>
            <Row>
              <Inputfield
                title="Semester 1"
                valueState={[semester1, setSemester1]}
              />
              <Inputfield
                title="Semester 2"
                valueState={[semester2, setSemester2]}
              />
              <Inputfield
                title="Semester 3"
                valueState={[semester3, setSemester3]}
              />
              <Inputfield
                title="Semester 4"
                valueState={[semester4, setSemester4]}
                placeholder={(programStudying === "ME / MTech / MSc" || programStudying === "MBA") ? "N/A" : ""}
                isDisabled={(programStudying === "ME / MTech / MSc" || programStudying === "MBA")}
              />
            </Row>
            {(programStudying === "MCA" || programStudying === "MSc Integrated") && (
              <Row className={`${programStudying === "MCA" ? "w-full lg:w-1/2" : ""}`} >
                <Inputfield
                  title="Semester 5"
                  valueState={[semester5, setSemester5]}
                  className={`${programStudying === "MCA" ? "w-full lg:w-1/2" : "w-full lg:w-1/4"}`}
                />
                <Inputfield
                  title="Semester 6"
                  valueState={[semester6, setSemester6]}
                  className={`${programStudying === "MCA" ? "w-full lg:w-1/2" : "w-full lg:w-1/4"}`}
                  placeholder={(programStudying === "MCA") ? "N/A" : ""}
                  isDisabled={(programStudying === "MCA")}
                />
                {programStudying === "MSc Integrated" && (
                  <Row className="w-full lg:w-1/2" >
                    <Inputfield
                      title="Semester 7"
                      valueState={[semester7, setSemester7]}
                      className="w-full lg:w-1/2"
                    />
                    <Inputfield
                      title="Semester 8"
                      valueState={[semester8, setSemester8]}
                      className="w-full lg:w-1/2"
                    />
                  </Row>
                )}
              </Row>
            )}
            {programStudying === "MSc Integrated" && (
              <div className="space-y-4">
                <Row className="w-full lg:w-[50%]">
                  <Inputfield
                    title="Semester 9"
                    valueState={[semester9, setSemester9]}
                  />
                  <Inputfield
                    title="Semester 10"
                    valueState={[semester10, setSemester10]}
                    placeholder={(programStudying === "MSc Integrated") ? "N/A" : ""}
                    isDisabled={(programStudying === "MSc Integrated")}
                  />
                </Row>
              </div>
            )}
          </div>
        )}

        <Row className="items-center">
          <Inputfield
            className="w-full lg:w-[50%]"
            title="CGPA"
            placeholder="Should be 8.0 and above in the Final Semester"
            valueState={[cgpa, setCgpa]}
          />
          <Toggle
            className="w-full lg:w-[50%]"
            title={"Any theory courses remaining in the Final Semester"}
            options={["Yes", "No"]}
            valueState={[theoryCourses, setTheoryCourses]}
          />
        </Row>
      </div>
      <Row className="mt-8">
        <Button
          text="Save and Proceed"
          className="w-full lg:w-1/3"
          handleClick={e => handleProceed(false)}
        />
        <Button
          isOutlined
          text="Save and Exit"
          className="w-full lg:w-1/3"
          handleClick={e => handleProceed(true)}
        />
      </Row>
    </main>
  );
};

export default Section2;
