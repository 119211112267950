import React, { useState } from "react";
import {
  AiOutlinePlus,
  AiOutlineMinus
} from "react-icons/ai";

const Number = ({
  valueState = [0, (v) => { }],
  errorState = ["", (v) => { }],
  title = "",
  className = "w-full",
}) => {
  const [value, setValue] = valueState;
  const [error, setError] = errorState;

  return (
    <div
      className={`${className} flex flex-col items-start justify-center space-y-2 font-poppins`}
    >
      <label className="text-ming text-base">{title}</label>
      <div className="flex space-x-2 items-center">
        <button
          className="px-4 py-3 w-full rounded-lg bg-gray bg-clip-padding bg-no-repeat border-2 border-solid border-gray first-letter:transition ease-in-out m-0"
          onClick={() => {
            if (value > 0) {
              setValue(value - 1);
            }
          }}
        >
          <AiOutlineMinus className="text-ming" />
        </button>
        <p
          className={` px-4 py-2 w-full rounded-lg text-slate bg-gray bg-clip-padding bg-no-repeat border-2 border-solid ${error.length !== 0 ? "border-red" : "border-gray"
            } first-letter:transition ease-in-out m-0 focus:outline-none focus:border-cloud`}
        >
          {value}
        </p>
        <button
          className="px-4 py-3 w-full rounded-lg bg-gray bg-clip-padding bg-no-repeat border-2 border-solid border-gray first-letter:transition ease-in-out m-0"
          onClick={() => {
            setValue(value + 1);
          }}
        >
          <AiOutlinePlus className="text-ming" />
        </button>
      </div>
    </div>
  );
};

export default Number;
