import React, { useContext, useEffect, useState } from "react";
import Heading from "../../components/Heading";
import Table from "../../components/Table";
import { FacultyContext } from ".";
import { toast } from "react-hot-toast";
import html2pdf from "html2pdf.js";
import generateSubmissionTemplate from "../../template-generators/generateSubmissionTemplate.js";
import { fetchGetRecommend } from "../../API/calls";

const Recommendations = () => {
  const { submissionState } = useContext(FacultyContext);
  const [recommends, setRecommends] = useState([]);

  useEffect(() => {
    toast.promise(fetchGetRecommend(), {
      loading: "Fetching details...",
      success: (res) => {
        setRecommends(res.data);
        return "Fetched successfully!";
      },
      error: "Error fetching data",
    });
  }, []);

  return (
    <section className="h-screen">
      <Heading>Recommendations</Heading>
      <div className="mt-4 h-3/4 overflow-y-scroll">
        {recommends.map((item, i) => (
          <div key={i} className="py-4 border-b border-b-ming font-poppins text-ming">{`${item.fromName}(${item.fromRoll}) recommended ${item.toName}(${item.toRoll}).`}</div>
        ))}
      </div>
    </section>
  );
};

export default Recommendations;
