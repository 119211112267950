import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../components/Button";
import DialogBox from "../../components/DialogBox";
import Heading from "../../components/Heading";
import Row from "../../components/Row";
import { SubmissionContext } from ".";
import generateSubmissionTemplate from "../../template-generators/generateSubmissionTemplate.js";
import html2pdf from "html2pdf.js";
import MultiSelect from "../../components/MultiSelect";
import Inputfield from "../../components/TextInput";
import { IoClose } from "react-icons/io5";
import { toast } from "react-hot-toast";
import { fetchGetRecommend, fetchPostRecommend } from "../../API/calls";

const Completed = () => {
  const navigate = useNavigate();

  const [submission] = useContext(SubmissionContext);
  const [roll, setRoll] = useState("");
  const [name, setName] = useState("");
  const [recommends, setRecommends] = useState([]);
  const [hasRecommended, setHasRecommended] = useState(null);

  useEffect(() => {
    toast.promise(fetchGetRecommend(), {
      loading: "Loading...",
      success: (res) => {
        const recommends = res.data.filter(
          (item) => item.fromRoll === localStorage.getItem("rollno")
        );
        recommends.length > 0
          ? setHasRecommended(true)
          : setHasRecommended(false);
        return "Successfully fetched";
      },
      error: (err) => {
        return `Error recommending ${err}`;
      },
    });
  }, []);

  const handleDownload = (submission) => {
    toast.promise(generateSubmissionTemplate(submission),
      {
        loading: "Generating PDF",
        success: (res) => {
          return "Successfully generated PDF";
        },
        error: (err) => {
          return `Error generating PDF ${err}`;
        },
      }
    );
  };

  const handleProceed = (shouldExit) => {
    navigate("/login");
  };

  const handleRecommend = () => {
    toast.promise(
      fetchPostRecommend(
        recommends.map((item) => ({
          fromRoll: submission.userDetails.rollNo,
          fromName: submission.userDetails.name,
          toRoll: item.roll,
          toName: item.name,
        }))
      ),
      {
        loading: "Sending recommendations",
        success: (res) => {
          setHasRecommended(true);
          return "Successfully recommended";
        },
        error: (err) => {
          return `Error recommending ${err}`;
        },
      }
    );
  };

  useEffect(() => {
    console.log(submission);
  }, [submission]);

  return (
    <main className="w-full flex flex-col overflow-y-hidden">
      <div className="mb-8">
        <h2 className="font-poppins">Congratulations!</h2>
        <Heading>You have successfully completed the application</Heading>
      </div>
      <DialogBox
        title="Info"
        content={
          <div>
            <p className="font-poppins text-base">
              Your application has been submitted.
              <br></br>
              You shall download your application, after recommending your
              friends to the award. The downloaded document has to be softbinded
              and submitted to your HOD's desk within the due date.
              <br></br>
            </p>
          </div>
        }
      />
      {hasRecommended === null ? (
        <p className="mt-8 text-lg font-poppins">Loading...</p>
      ) : !hasRecommended ? (
        <React.Fragment>
          <p className="font-poppins text-lg font-semibold mt-8">
            Recommend your friends for the Best Outgoing Student Award!
          </p>
          <Row className="mt-2 items-end">
            <Inputfield
              valueState={[roll, setRoll]}
              title="Roll no."
              className="w-full lg:w-1/3"
            />
            <Inputfield
              valueState={[name, setName]}
              title="Name"
              className="w-full lg:w-2/3"
            />
            <Button
              isOutlined
              handleClick={(e) => {
                setRecommends([...recommends, { roll: roll, name: name }]);
                setRoll("");
                setName("");
              }}
              text="Add"
              className="w-full lg:w-1/3"
            />
          </Row>
          <Row className="mt-2 font-poppins">
            {recommends.map((item, i) => (
              <div key={i} className="flex p-2 px-4 bg-gray rounded-md items-center space-x-4">
                <p className="">{`${item.roll} - ${item.name}`}</p>
                <button
                  className="text-black hover:text-red"
                  onClick={(e) =>
                    setRecommends(recommends.filter((itm, idx) => idx !== i))
                  }
                >
                  <IoClose size={16} />
                </button>
              </div>
            ))}
          </Row>
          <Row>
            <Button
              text="Recommend your friends!"
              className="w-full lg:w-1/3 mt-2"
              handleClick={handleRecommend}
            />
          </Row>
        </React.Fragment>
      ) : (
        <Row className="mt-8">
          <Button
            handleClick={(e) => handleDownload(submission)}
            text="Download"
            className="w-full lg:w-1/3"
          />
          <Button
            isOutlined
            text="Exit"
            className="w-full lg:w-1/3"
            handleClick={(e) => handleProceed(true)}
          />
        </Row>
      )}
    </main>
  );
};

export default Completed;
