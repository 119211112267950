import React, { useContext, useEffect, useState } from "react";
import Button from "../components/Button";
import Heading from "../components/Heading";
import Row from "../components/Row";
import Layout from "./Layout";
import { fetchCountAllSubs, fetchCountByDept } from "../API/calls";
import departments from "../utils/Departments";

const Statistics = () => {
  const [totalSubs, setTotalSubs] = useState(0);
  const [deptSubs, setDeptSubs] = useState([]);

  useEffect(() => {
    fetchCountAllSubs()
      .then((res) => {
        setTotalSubs(res.data.count);
      })
      .catch((err) => console.log(err));
  }, [])

  useEffect(() => {
    fetchCountByDept()
      .then((res) => {
        let temp = res.data.data;
        temp.sort((a, b) => {
          if (a.dept < b.dept) {
            return -1;
          } else if (a.dept > b.dept) {
            return 1;
          } else {
            return 0;
          }
        });
        setDeptSubs(temp);
      })
      .catch((err) => console.log(err));

  }, []);

  const sortAlphabetically = () => {
    deptSubs.sort((a, b) => {
      if (a.dept < b.dept) {
        return -1;
      } else if (a.dept > b.dept) {
        return 1;
      } else {
        return 0;
      }
    });
    setDeptSubs([...deptSubs]);
  };

  const sortNumerically = () => {
    deptSubs.sort((a, b) => {
      if (a.count < b.count) {
        return 1;
      } else if (a.count > b.count) {
        return -1;
      } else {
        return 0;
      }
    });
    setDeptSubs([...deptSubs]);
  };

  const [alpha, setAlpha] = useState(true);
  const [num, setNum] = useState(false);

  return (
    <Layout>
      <main className="w-full flex flex-col overflow-y-hidden">
        <div className="mb-8">
          <Heading>Statistics</Heading>
        </div>
        <div className="overflow-y-scroll lg:pr-16 space-y-4 h-full lg:h-[calc(100vh-18rem)]">
          <div className="flex flex-col lg:flex-row gap-x-12">
            <div className="flex flex-row pt-8 gap-x-14 lg:gap-x-24 w-3/4">
              <p className="text-3xl lg:text-4xl font-semibold">Total Applications</p>
              <p className={`${totalSubs === 0 ? "text-xl" : "text-5xl"} font-bold text-ming`}>{totalSubs === 0 ? "Loading..." : totalSubs}</p>
            </div>
            <div className="flex flex-col items-center gap-y-4 w-full lg:w-1/4 py-12 lg:py-0">
              <p className="font-semibold">Sort By</p>
              <button className={`px-6 py-2 rounded-full ${alpha ? "bg-[#3c3c3c] text-white" : "bg-[#eaeaea] text-[#303030]"} border-2 border-[#303030]`}
                onClick={() => {
                  setAlpha(true);
                  setNum(false);
                  sortAlphabetically()
                }}
              >
                Alphabetically
              </button>
              <button className={`px-6 py-2 rounded-full ${num ? "bg-[#3c3c3c] text-white" : "bg-[#eaeaea] text-[#303030]"} border-2 border-[#303030]`}
                onClick={() => {
                  setAlpha(false);
                  setNum(true);
                  sortNumerically()
                }}
              >
                Number of Submissions
              </button>
            </div>
          </div>

          {deptSubs.map((dept, index) => (
            <div>
              <div className="flex flex-row items-center gap-x-12">
                <p className="text-xl font-semibold">{index + 1}.</p>
                <p className="text-xl w-3/4 lg:w-[55%]">{dept.dept}</p>
                <p className="text-3xl font-bold text-ming">{dept.count}</p>
              </div>
              <div className="w-full lg:w-[70%] h-0.5 my-2 bg-gray"></div>
            </div>
          ))}

        </div>
      </main>
    </Layout>
  );
};

export default Statistics;
