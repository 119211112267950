import React, { useContext, useEffect, useReducer, useState } from "react";
import Button from "../../components/Button";
import Heading from "../../components/Heading";
import Row from "../../components/Row";
import Inputfield from "../../components/TextInput";
import Toggle from "../../components/Toggle";
import FileUpload from "../../components/FileUpload";
import { useNavigate } from "react-router-dom";
import { SubmissionContext } from ".";
import { toast } from "react-hot-toast";
import { fetchPostSection3, fetchUploadFile } from "../../API/calls";
import Number from "../../components/Number";
import axios from "axios";
import MultipleFiles from "../../components/MultipleFiles";

const Section3 = () => {
  const [state, dispatch] = useReducer(internshipReducer, []);
  const [proofs, setProofs] = useState([]);
  const [proofUrls, setProofUrls] = useState([]);
  const [submission] = useContext(SubmissionContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (!submission || !submission[3]) return;
    console.log(submission[3]);
    dispatch({
      type: "setState",
      options: {
        state: submission[3].internships.map((draft) => ({
          placeOfInternship: draft.isAbroad ? "Abroad" : "India",
          companyName: draft.companyName,
          duration: draft.duration,
          stipend: draft.stipend,
          outcome: draft.outcome,
          placementOffice: draft.throughPlacement ? "Yes" : "No",
        })),
      },
    });
    setProofUrls(submission[3].proofUrl);
  }, [submission]);

  useEffect(() => {
    console.log(proofs, proofUrls);
  }, [proofs, proofUrls]);

  const handleFileUpload = async (files, curr_no, total, shouldExit) => {
    if (files.length <= 0) {
      setProofUrls(proofUrls);

      const postBody = {
        rollNo: localStorage.getItem("rollno"),
        internships: state.map((item) => ({
          isAbroad: item.placeOfInternship === "Abroad",
          companyName: item.companyName,
          duration: item.duration,
          stipend: item.stipend,
          outcome: item.outcome,
          throughPlacement: item.placementOffice === "Yes",
        })),
        proofUrl: proofUrls,
      };

      console.log("JOJ", postBody);

      toast.promise(
        fetchPostSection3(localStorage.getItem("rollno"), postBody),
        {
          loading: "Saving...",
          success: (res) => {
            shouldExit ? navigate("/login") : navigate("/apply/section-4");
            return "Section-3 Successfully Saved";
          },
          error: (err) => {
            return `Error Saving: ${err.message}`;
          },
        }
      );
    } else {
      const currentFile = files.pop();
      console.log(curr_no, currentFile);
      toast.promise(fetchUploadFile(currentFile), {
        loading: `Uploading... ${curr_no}/${total}`,
        success: (res) => {
          let tempFileUrls = proofUrls;
          tempFileUrls.push(res.data.url);
          setProofUrls(tempFileUrls);
          handleFileUpload(files, curr_no + 1, total, shouldExit);
          return `Uploaded ${curr_no}/${total}`;
        },
        error: (err) => `Error: ${err.message}`,
      });
    }
  };

  const isNumeric = (str) => {
    if (str === null) return false;
    return isNaN(str)
  }

  const handleProceed = (shouldExit) => {

    const checkForStipend = state.filter(item => item.stipend && isNumeric(item.stipend));
    if (checkForStipend.length > 0) return toast.error("Stipend value must be a numeric value only.")

    const check = state.filter(
      (item) =>
        item.placeOfInternship.length <= 0 ||
        item.companyName.length <= 0 ||
        item.duration <= 0 ||
        item.outcome.length <= 0 ||
        (item.placeOfInternship === "India" &&
          (item.placementOffice === null || item.placementOffice.length <= 0))
    );
    if (check.length > 0)
      return toast.error("Enter all the required fields");

    if (proofs) {
      handleFileUpload(proofs, 1, proofs.length, shouldExit);
    } else {
      const postBody = {
        rollNo: localStorage.getItem("rollno"),
        internships: state.map((item) => ({
          isAbroad: item.placeOfInternship === "Abroad",
          companyName: item.companyName,
          duration: item.duration,
          stipend: item.stipend,
          outcome: item.outcome,
          throughPlacement: item.placementOffice === "Yes",
        })),
        proofUrl: proofUrls,
      };

      console.log("JOJ", postBody);

      toast.promise(
        fetchPostSection3(localStorage.getItem("rollno"), postBody),
        {
          loading: "Saving...",
          success: (res) => {
            shouldExit ? navigate("/login") : navigate("/apply/section-4");
            return "Section-3 Successfully Saved";
          },
          error: (err) => {
            return `Error Saving: ${err.message}`;
          },
        }
      );
    }
  };

  return (
    <main className="w-full flex flex-col overflow-y-hidden">
      <div className="mb-8">
        <h2 className="font-poppins">Section 3</h2>
        <Heading>Details of Internship / Fellowship</Heading>
      </div>
      <div className="overflow-y-scroll lg:pr-16 space-y-4 h-full lg:h-[calc(100vh-26rem)]">
        <Number
          title="Enter the number of internships / fellowships you have done"
          valueState={[
            state.length,
            (n) => {console.log(n); dispatch({ type: "setCount", options: { number: n } })},
          ]}
        />
        {state.map((stateItem, idx) => (
          <InternshipDetails
            idx={idx}
          key={idx}
            placeOfInternshipState={[
              stateItem.placeOfInternship,
              (val) =>
                dispatch({
                  type: "setValue",
                  options: { key: "placeOfInternship", value: val, idx: idx },
                }),
            ]}
            companyNameState={[
              stateItem.companyName,
              (val) =>
                dispatch({
                  type: "setValue",
                  options: { key: "companyName", value: val, idx: idx },
                }),
            ]}
            durationState={[
              stateItem.duration,
              (val) =>
                dispatch({
                  type: "setValue",
                  options: { key: "duration", value: val, idx: idx },
                }),
            ]}
            stipendState={[
              stateItem.stipend,
              (val) =>
                dispatch({
                  type: "setValue",
                  options: { key: "stipend", value: val, idx: idx },
                }),
            ]}
            outcomeState={[
              stateItem.outcome,
              (val) =>
                dispatch({
                  type: "setValue",
                  options: { key: "outcome", value: val, idx: idx },
                }),
            ]}
            placementOfficeState={[
              stateItem.placementOffice,
              (val) =>
                dispatch({
                  type: "setValue",
                  options: { key: "placementOffice", value: val, idx: idx },
                }),
            ]}
          />
        ))}
        {state.length > 0 && (
          <MultipleFiles
            pdf
            fileState={[proofs, setProofs]}
            urlState={[proofUrls, setProofUrls]}
            title="Upload all the documents required as proofs for the above internships"
          />
        )}
      </div>
      <Row className="mt-8">
        <Button
          handleClick={() => handleProceed(false)}
          text="Save and Proceed"
          className="w-full lg:w-1/3"
        />
        <Button isOutlined text="Save and Exit" className="w-full lg:w-1/3" />
      </Row>
    </main>
  );
};

const InternshipDetails = ({
  idx,
  placeOfInternshipState,
  companyNameState,
  durationState,
  stipendState,
  outcomeState,
  placementOfficeState,
}) => {
  const [placeOfInternship, setPlaceOfInternship] = placeOfInternshipState;
  const [companyName, setCompanyName] = companyNameState;
  const [duration, setDuration] = durationState;
  const [stipend, setStipend] = stipendState;
  const [outcome, setOutcome] = outcomeState;
  const [placementOffice, setPlacementOffice] = placementOfficeState;

  return (
    <div className="bg-white rounded-lg py-4 space-y-4">
      <h3 className="font-poppins text-lg font-bold">Internship {idx + 1}</h3>
      <Toggle
        title={"Place of Internship"}
        options={["India", "Abroad"]}
        valueState={[placeOfInternship, setPlaceOfInternship]}
      />
      <Row>
        <Inputfield
          title="Name of the Company"
          valueState={[companyName, setCompanyName]}
        />
        <Number
          title="Duration (in months)"
          valueState={[duration, setDuration]}
        />
      </Row>
      <Row>
        <Inputfield
          title="Stipend (if any)"
          valueState={[stipend, setStipend]}
          placeholder="In INR"
        />
        <Inputfield
          title="Outcome"
          valueState={[outcome, setOutcome]}
          placeholder="Experience / Skills gained"
          />
      </Row>
      {placeOfInternship === "India" && (
        <Row className="items-center">
          <Toggle
            className="w-full lg:w-[50%]"
            title={"Selected through Placement Office"}
            options={["Yes", "No"]}
            valueState={[placementOffice, setPlacementOffice]}
          />
        </Row>
      )}
    </div>
  );
};

const internshipReducer = (state = [], action) => {
  switch (action.type) {
    case "setCount": {
      const { number } = action.options;
      if (number === state.length) return state;
      else if (number < state.length) return state.slice(0, number);
      else {
        let temp = state;
        for (let i = state.length; i <= number; i++) {
          temp.push({
            placeOfInternship: "India",
            companyName: "",
            duration: 0,
            stipend: "",
            outcome: "",
            placementOffice: "Yes",
            proof: null,
            proofUrl: [],
          });
        }
        return temp;
      }
    }
    case "setValue": {
      const { idx, key, value } = action.options;
      if (idx >= state.length)
        throw Error(action.type + ": Array access out of length");
      else {
        let newState = state.map((stateItem, i) => {
          if (idx === i) {
            stateItem[key] = value;
          }
          return stateItem;
        });
        return newState;
      }
    }
    case "setState": {
      const { state } = action.options;
      return state;
    }
    default: {
      throw Error("Unknown action: " + action.type);
    }
  }
};

export default Section3;
