import React, { useEffect, useState } from "react";
import Heading from "../../components/Heading";
import Row from "../../components/Row";
import Inputfield from "../../components/TextInput";
import Button from "../../components/Button";
import { fetchDeleteSubmission, fetchDeleteUser, fetchUserDetails } from "../../API/calls";
import { toast } from "react-hot-toast";
import { IoCloseOutline } from "react-icons/io5";

const Delete = () => {
  const [roll, setRoll] = useState("");
  const [details, setDetails] = useState({});

  const handleFetch = () => {
    if (roll.length !== 6) return toast.error("Invalid roll number");
    toast.promise(fetchUserDetails(roll.toLowerCase()), {
      loading: "Fetching details",
      success: (res) => {
        console.log(res.data);
        setDetails(res.data);
        return "Details fetched";
      },
      error: "Error fetching details",
    });
  };

  const handleDelete = () => {
    if (roll.length !== 6) return toast.error("Invalid roll number");
    if (Object.keys(details).length === 0) return toast.error("Fetch the details before deleting");

    if (window.confirm("Are you sure you want to delete this submission?")) {
      toast.promise(fetchDeleteSubmission(roll.toLowerCase()), {
        loading: "Deleting submission",
        success: (res) => {
          console.log(res.data);
          toast.promise(fetchDeleteUser(roll.toLowerCase()), {
            loading: "Deleting user",
            success: (res) => {
              console.log(res.data);
              clearRoll();
              return "User deleted";
            },
            error: "Error deleting user",
          });
          return "Submission deleted";
        },
        error: "Error deleting submission",
      });
    } else {
      return toast.error("Submission not deleted");
    }
  };

  const clearRoll = () => {
    setRoll("");
    setDetails({});
  };

  return (
    <main className="w-full flex flex-col overflow-y-hidden">
      <div className="mb-8">
        <Heading>Delete Submission</Heading>
      </div>
      <Row className="items-end">
        <Inputfield
          title="Roll Number"
          placeholder="Eg: 20X000"
          valueState={[roll, setRoll]}
        />
        <div className="pb-2">
          <button
            className="rounded-full mt-8 bg-cloud p-1 hover:text-gray z-40"
            onClick={clearRoll}
          >
            <IoCloseOutline />
          </button>
        </div>
        <Button
          className="w-1/2"
          text="Fetch"
          handleClick={handleFetch}
        />
        <Button
          className="w-1/2"
          text="Delete"
          handleClick={handleDelete}
        />
      </Row>
      {Object.keys(details).length !== 0 && (
        <div className="mt-8">
          <Heading>Details</Heading>
          <div className="mt-4">
            <Row className="mt-4">
              <div className="w-1/2">
                <p className="text-ming">Name</p>
                <p className="">{details.name}</p>
              </div>
              <div className="w-1/2">
                <p className="text-ming">Roll Number</p>
                <p className="">{details.roll}</p>
              </div>
            </Row>
            <Row className="mt-4">
              <div className="w-1/2">
                <p className="text-ming">Email</p>
                <p className="">{details.email}</p>
              </div>
              <div className="w-1/2">

                <p className="text-ming">Department</p>
                <p className="">{details.department}</p>
              </div>
            </Row>
          </div>
        </div>
      )}
    </main>
  );
};

export default Delete;
