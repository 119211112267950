import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../components/Button";
import DialogBox from "../../components/DialogBox";
import Heading from "../../components/Heading";
import Row from "../../components/Row";
import { SubmissionContext } from ".";
import generateSubmissionTemplate from "../../template-generators/generateSubmissionTemplate.js";
import html2pdf from "html2pdf.js";
import { fetchConfirm } from "../../API/calls";
import { toast } from "react-hot-toast";

const Confirmation = () => {
  const navigate = useNavigate();

  const [submission] = useContext(SubmissionContext);

  const handleConfirm = () => {
    if (
      window.confirm(
        "Are you sure you want to submit? You cannot edit your application after submitting."
      )
    ) {
      console.log(submission.rollno);
      toast.promise(fetchConfirm(submission.rollno), {
        loading: "Confirming...",
        success: (res) => {
          navigate(0);
          console.log("Confirmed");
          return "Submission confirmed successfully";
        },
        error: (err) => {
          console.log(err);
          return "Error confirming";
        },
      });
    }
  };

  const handleDownload = (submission) => generateSubmissionTemplate(submission);

  const handleProceed = (shouldExit) => {
    navigate("/login");
  };

  return (
    <main className="w-full flex flex-col overflow-y-hidden">
      <div className="mb-8">
        <h2 className="font-poppins">Congratulations!</h2>
        <Heading>You have successfully completed the application</Heading>
      </div>
      <DialogBox
        title="Info"
        content={
          <div>
            <p className="font-poppins text-base">
              Your application has been completed. You have to confirm before
              submission. Kindly check through all the sections before
              confirmation.
              <br></br>
            </p>
          </div>
        }
      />
      <Row className="mt-8">
        <Button
          handleClick={(e) => handleConfirm()}
          text="Confirm"
          className="w-full lg:w-1/3"
        />
        <Button
          isOutlined
          text="Exit"
          className="w-full lg:w-1/3"
          handleClick={(e) => handleProceed(true)}
        />
      </Row>
    </main>
  );
};

export default Confirmation;
