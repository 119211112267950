import React, { useContext, useEffect, useState } from "react";
import Button from "../components/Button";
import Heading from "../components/Heading";
import Row from "../components/Row";
import Layout from "./Layout";
import { MdMail } from "react-icons/md";

const Contact = () => {

  const handleProceed = async (shouldExit) => {
    console.log("Proceed");
  };

  return (
    <Layout>
      <main className="w-full flex flex-col overflow-y-hidden">
        <div className="mb-8">
          <Heading>Contact Us</Heading>
          <h2 className="font-poppins mt-2">For any enquires, please use the contacts below.</h2>
        </div>
        <div className="overflow-y-scroll lg:pr-16 space-y-4 h-full lg:h-[calc(100vh-26rem)]">
          <Row className="mt-8 gap-x-16">
            <div className="space-y-2">
              <h1 className="text-2xl text-ming font-poppins">
                Dr. Saranya K G
              </h1>
              <h2 className="text-xl font-poppins">
                Assistant Professor (Sr. Gr.), Department of Computer Science and Engineering
              </h2>
              <button
                className="font-poppins flex flex-row items-center gap-2"
                onClick={() => { window.location = "mailto:kgs@cse.psgtech.ac.in" }}
              >
                <MdMail />
                kgs@cse.psgtech.ac.in
              </button>
            </div>
            <div className="space-y-2">
              <h1 className="text-2xl text-ming font-poppins">
                Dr. Sarathambekai S
              </h1>
              <h2 className="text-xl font-poppins">
                Associate Professor, Department of Information Technology
              </h2>
              <button
                className="font-poppins flex flex-row items-center gap-2"
                onClick={() => { window.location = "mailto:ssi.it@psgtech.ac.in" }}
              >
                <MdMail />
                ssi.it@psgtech.ac.in
              </button>
            </div>
          </Row>
        </div>
      </main>
    </Layout>
  );
};

export default Contact;
