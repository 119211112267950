const BASE_URL = "";
const API_URL = `${BASE_URL}/api`;

export const AUTH_URL = `${API_URL}/auth`;
export const LOGIN_URL = `${AUTH_URL}/login`;
export const FACULTY_AUTH_URL = `${API_URL}/faculty-auth`;

export const UPLOAD_URL = `${API_URL}/upload`;

export const APPLY_URL = `${API_URL}/apply`;
export const RECOMMEND_URL = `${API_URL}/recommend`;

export const SETTINGS_URL = `${API_URL}/settings`;
