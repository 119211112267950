/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import Layout from "../Layout";
import { Link, Outlet, useNavigate } from "react-router-dom";
import {
  MdKeyboardArrowDown,
  MdKeyboardArrowRight,
  MdLogout,
  MdOutlineDeleteOutline,
} from "react-icons/md";
import { FACULTY_AUTH_URL } from "../../API/config";
import axios from "axios";
import {
  fetchAllSubmissions,
  fetchAllSubmissionsByDept,
  fetchAllSubmissionsByTutor,
  fetchFacultyDetails,
} from "../../API/calls";
import { toast } from "react-hot-toast";
import { BsPersonPlus } from "react-icons/bs";
import { HiOutlineDocumentSearch } from "react-icons/hi";
import { FiSettings } from "react-icons/fi";
import { AiOutlineUser } from "react-icons/ai";

export const FacultyContext = React.createContext();

const Index = () => {
  const navigate = useNavigate();

  const [facultyDetails, setFacultyDetails] = useState(null);

  useEffect(() => {
    if (
      localStorage.getItem("token") &&
      localStorage.getItem("rights") === "nmc" &&
      localStorage.getItem("email")
    ) {
      axios
        .get(`${FACULTY_AUTH_URL}/login/${localStorage.getItem("email")}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          console.log(res.data);
          toast.promise(fetchFacultyDetails(localStorage.getItem("email")), {
            loading: "Fetching details...",
            success: (res) => {
              setFacultyDetails(res.data.user);
              return "Details fetched successfully !";
            },
            error: "Error loading details !",
          });
        })
        .catch((err) => {
          localStorage.clear();
          navigate("/login");
        });
    } else {
      navigate("/login");
    }
  }, []);

  return (
    <Layout>
      <FacultyContext.Provider
        value={{
          facultyState: [facultyDetails, setFacultyDetails],
        }}
      >
        <div className="w-full flex flex-col lg:flex-row h-full space-y-8 lg:space-y-0 lg:space-x-16">
          <MenuNav />
          <main className="w-full lg:w-2/3">
            <Outlet />
          </main>
        </div>
      </FacultyContext.Provider>
    </Layout>
  );
};

export default Index;

const MenuNav = () => {
  const [shown, setShown] = useState(false);
  const [facultyDetails] = useContext(FacultyContext).facultyState;
  const navigate = useNavigate();

  useEffect(() => {
    let details = navigator.userAgent;

    let regexp = /android|iphone|kindle|ipad/i;

    let isMobileDevice = regexp.test(details);

    if (!isMobileDevice) {
      setShown(true);
    } else {
      setShown(false);
    }
  }, []);

  const handleLogout = (e) => {
    e.preventDefault();
    localStorage.clear();
    navigate("/login");
  };

  return (
    <nav className="w-full lg:w-1/3 h-full overflow-y-hidden">
      <div className="flex">
        <div className="flex-1  ">
          <h1 className="text-2xl text-ming font-poppins pb-6">
            Admin Portal for Best Outgoing Student Awards{" "}
            {new Date().getFullYear()}
          </h1>
          <div className="space-y-4 mt-8">
            <div className="flex flex-row w-full">
              <Link
                className="text-ming font-poppins flex flex-row gap-2"
                to="/nmc-admin/general"
              >
                <FiSettings size={20} className="text-ming" />
                General Settings
              </Link>
            </div>
            <div className="flex flex-row w-full">
              <Link
                className="text-ming font-poppins flex flex-row gap-2"
                to="/nmc-admin/users"
              >
                <AiOutlineUser size={20} className="text-ming" />
                Users
              </Link>
            </div>
            <div className="flex flex-row w-full">
              <Link
                className="text-ming font-poppins flex flex-row gap-2"
                to="/nmc-admin/delete-submission"
              >
                <MdOutlineDeleteOutline size={20} className="text-ming" />
                Delete Submission
              </Link>
            </div>
          </div>
          {localStorage.getItem("rights") === "admin" && (
            <div className="flex flex-row w-full mt-4">
              <Link
                className="text-ming font-poppins flex flex-row gap-2"
                to="/admin-portal/recommendations"
              >
                <BsPersonPlus size={20} className="text-ming" />
                Recommendations
              </Link>
            </div>
          )}
          <div className="flex-1"></div>
          <div className="flex flex-row w-full mt-8">
            <button
              className="text-ming font-poppins flex flex-row gap-2"
              onClick={(e) => handleLogout(e)}
            >
              <MdLogout size={20} className="text-ming" />
              Logout
            </button>
          </div>
        </div>
        <button className="lg:hidden" onClick={() => setShown(!shown)}>
          {shown ? (
            <MdKeyboardArrowDown size={36} className="text-ming" />
          ) : (
            <MdKeyboardArrowRight size={36} className="text-ming" />
          )}
        </button>
      </div>
      {shown && <div className="flex flex-col w-full mt-8 "></div>}
    </nav>
  );
};
