import React, { useState } from "react";
import toast from "react-hot-toast";
import Button from "../../components/Button";
import Heading from "../../components/Heading";
import Row from "../../components/Row";
import Inputfield from "../../components/TextInput";
import DialogBox from "../../components/DialogBox";
import { FaUserGraduate, FaUserTie } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import {
  fetchFacultyLoginOTP,
  fetchFacultyLoginVerify,
  fetchForgotPasswordOTP,
  fetchForgotPasswordVerifyOTP,
  fetchPasswordReset,
  fetchStudentLoginVerify,
} from "../../API/calls";

const ForgotPasswordStudent = () => {
  return (
    <main className="h-screen w-screen overflow-x-hidden lg:overflow-y-hidden flex flex-col lg:flex-row">
      <PrimarySection />
      <RegisterFormSection />
    </main>
  );
};

export default ForgotPasswordStudent;

const PrimarySection = () => {
  const navigate = useNavigate();

  const handleSignup = () => {
    navigate("/");
  };

  return (
    <section className="lg:p-8 bg-white h-full w-full lg:w-5/12">
      <div className="bg-gradient-to-b from-ming to-pacific p-8 lg:rounded-xl shadow-xl h-full flex flex-col justify-between">
        <header className="flex space-x-4 items-center pb-4">
          <img
            src="https://upload.wikimedia.org/wikipedia/en/e/eb/PSG_College_of_Technology_logo.png"
            alt=""
            className="h-12 lg:h-16 w-auto filter invert"
          />
          <div className=" text-white font-poppins ">
            <p className="text-xl lg:text-2xl">PSG College of Technology</p>
            <p className="text-sm">Coimbatore</p>
          </div>
        </header>
        <div className="">
          <h1 className="text-white font-poppins text-4xl mt-8">
            Apply for Best Outgoing Student Awards {new Date().getFullYear()}
          </h1>
          <h2 className="text-white font-poppins text-base mt-4 tracking-wide">
            Student with CGPA of 8.0 and above & without Any Reappearance (RA)
            should only apply.
          </h2>
        </div>
        <div className="rounded-lg bg-ming lg:p-8 p-4 font-fira text-xl mt-8">
          <div className="flex flex-col lg:flex-row items-start mb-4">
            <h1 className="text-white  border-b-2 border-transparent">
              Don't have an account ?
            </h1>
            <button
              className="text-white lg:ml-2 border-b border-dashed box-border hover:text-middle"
              onClick={handleSignup}
            >
              Click to Sign Up
            </button>
          </div>
          {/* <p className="text-sm font-poppins text-white">
            Developed by{" "}
            <span className="text-middle">
              <a href="https://github.com/tgashwinkumar" className="">
                TG Ashwin Kumar
              </a>
            </span>
            ,{" "}
            <span className="text-middle">
              {" "}
              <a href="https://github.com/jeyam03" className="">
                Jeyam Palaniappan D
              </a>
            </span>
            <span className="text-middle">
              ,{" "}
              <a href="https://github.com/dineshchandran311" className="">
                Dinesh Chandran
              </a>
            </span>
          </p> */}
          <p className="text-sm font-poppins text-white">
            For any queries contact{" "}
            <a className="text-middle" href="mailto:bestoutgoing@psgtech.ac.in">
              bestoutgoing@psgtech.ac.in
            </a>
          </p>
          <p className="text-sm font-poppins text-white">
            For tutorial{" "}
            <a
              className="text-middle"
              href="https://tutorialforstudents.netlify.app"
            >
              Click here
            </a>
          </p>
        </div>
      </div>
    </section>
  );
};

const RegisterFormSection = () => {
  const [roll, setRoll] = useState("");
  const [otp, setOtp] = useState("");
  const [otpSent, setOtpSent] = useState(false);
  const [otpVerified, setOtpVerified] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordErrorState, setPasswordErrorState] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordErrorState, setConfirmPasswordErrorState] =
    useState("");

  const navigate = useNavigate();

  const handleVerifyOTP = () => {
    localStorage.clear();
    toast.promise(
      fetchForgotPasswordVerifyOTP({
        email: roll.toLowerCase() + "@psgtech.ac.in",
        otp: otp,
      }),
      {
        loading: "Verifying...",
        success: (res) => {
          setOtpVerified(true);
          return "Verified Successfully";
        },
        error: (err) => {
          console.log(err);
          return `Retry again: ${err?.response?.data?.error}`;
        },
      }
    );
  };

  const handleSendOTP = () => {
    if (roll.length <= 0) {
      toast.error("Enter Email ID");
      return;
    }

    toast.promise(
      fetchForgotPasswordOTP({ email: roll.toLowerCase() + "@psgtech.ac.in" }),
      {
        loading: "Sending...",
        success: (res) => {
          setOtpSent(true);
          return "OTP sent to your registered email";
        },
        error: (err) => {
          console.log(err);
          return `Retry again: ${err?.response?.data?.error}`;
        },
      }
    );
  };

  const handleResetPassword = () => {
    if (password !== confirmPassword)
      return toast.error("Error: Passwords do not match");
    if (
      password.length < 8 ||
      !/[A-Z]/.test(password) ||
      !/[!_#$&%@]/.test(password) ||
      !/[0-9]/.test(password)
    ) {
      console.log(
        password.length >= 8,
        /[A-Z]/.test(password),
        /[!_#$&%@]/.test(password),
        /[0-9]/.test(password)
      );
      return toast.error("Error: Enter valid password");
    }

    toast.promise(
      fetchPasswordReset({
        email: roll.toLowerCase() + "@psgtech.ac.in",
        password: password,
      }),
      {
        loading: "Resetting...",
        success: (res) => {
          localStorage.clear();
          navigate("/");
          return "Password reset successfully";
        },
        error: (err) => {
          console.log(err);
          return `Retry again: ${err?.response?.data?.error}`;
        },
      }
    );
  };

  return (
    <section className="w-full lg:w-7/12 h-full py-16 p-8 lg:pl-0 lg:pr-[calc(100vw/12)] flex flex-col">
      <div className="hidden lg:block mb-4 w-[60%] ml-96 h-48 bg-[url(https://i.ibb.co/q94VWQN/ka3.png)] bg-cover "></div>
      <Heading>Forgot Password</Heading>
      <div className="space-y-6 mt-8 w-full lg:w-[60%]">
        <div>
          <Inputfield
            className="w-full"
            valueState={[roll, setRoll]}
            placeholder="Enter Roll Number"
            title="Roll Number"
          />
          {!otpSent && (
            <Button
              text="Send OTP"
              className="w-full mt-4 lg:w-1/2"
              handleClick={handleSendOTP}
            />
          )}

          {otpSent && !otpVerified && (
            <Row className="items-end">
              <Inputfield
                className="mt-4 lg:w-3/4"
                valueState={[otp, setOtp]}
                placeholder="Enter OTP"
                title="OTP"
              />
              <Button
                text="Verify OTP"
                className="w-full lg:w-1/3"
                handleClick={handleVerifyOTP}
              />
            </Row>
          )}
        </div>
      </div>

      {otpVerified && (
        <div className="space-y-8 mt-4">
          <Row>
            <Inputfield
              title="New Password"
              placeholder="Enter your password"
              valueState={[password, setPassword]}
              errorState={[passwordErrorState, setPasswordErrorState]}
              type="password"
            />
            <Inputfield
              title="Confirm Password"
              placeholder="Confirm your password"
              valueState={[confirmPassword, setConfirmPassword]}
              errorState={[
                confirmPasswordErrorState,
                setConfirmPasswordErrorState,
              ]}
              type="password"
            />
          </Row>
          <DialogBox
            title="For Note"
            content={
              <div>
                Password must
                <li>
                  Have minimum length of <strong>8</strong> characters
                </li>
                <li>
                  Contain an <strong>uppercase letter</strong> (A-Z)
                </li>
                <li>
                  Contain a <strong>special character</strong> (! _ # $ & % @)
                </li>
                <li>
                  Contain a <strong>number</strong> (0-9)
                </li>
              </div>
            }
          />
          <Button
            text="Update Password"
            className="mt-8 w-full lg:w-1/3"
            handleClick={handleResetPassword}
          />
        </div>
      )}

      <div className="p-4 lg:hidden"></div>
    </section>
  );
};
