import React, { useEffect, useState } from "react";
import Heading from "../../components/Heading";
import Row from "../../components/Row";
import Inputfield from "../../components/TextInput";
import Button from "../../components/Button";
import DialogBox from "../../components/DialogBox";
import {
  fetchGetSettings,
  fetchPostInviteToAll,
  fetchPostSettings,
} from "../../API/calls";
import { toast } from "react-hot-toast";

const General = () => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const getDateFromISOString = (date = "") => date.split("T")[0];

  useEffect(() => {
    toast.promise(fetchGetSettings(), {
      loading: "Loading settings...",
      success: (res) => {
        setStartDate(getDateFromISOString(res.data.startDate));
        setEndDate(getDateFromISOString(res.data.endDate));
        return "Settings successfully loaded!";
      },
      error: "Error loading settings",
    });
  }, []);

  const handleUpdate = () => {
    let d1 = new Date(startDate);
    let d2 = new Date(endDate);
    if (d1 >= d2)
      return toast.error("End date lies before the start date. Kindly check! ");
    toast.promise(fetchPostSettings({ startDate, endDate }), {
      loading: "Updating settings",
      success: (res) => {
        console.log({ startDate, endDate });
        return "Settings successfully updated";
      },
      error: "Error updating settings",
    });
  };

  const sendInvite = () => {
    toast.promise(fetchPostInviteToAll(), {
      loading: "Sending mail...",
      success: (res) => {
        console.log(res);
        return "Invitations sent successfuly";
      },
      error: "Error sending mail",
    });
  };

  return (
    <main className="w-full flex flex-col overflow-y-hidden">
      <div className="mb-8">
        <Heading>General Settings</Heading>
      </div>
      <Row className="items-end">
        <Inputfield
          title="Start Date for Submission"
          type="date"
          valueState={[startDate, setStartDate]}
        />
        <Inputfield
          title="End Date for Submission"
          type="date"
          valueState={[endDate, setEndDate]}
        />
        <Button text="Update" handleClick={handleUpdate} />
      </Row>
      <DialogBox
        className="mt-6"
        title="Warning !"
        content="Please create a backup of all student data and submissions before erasing them."
      />
      <Row className="items-center mt-6">
        <p className="text-lg font-poppins w-full lg:w-1/3">
          Fetch Backup for current year
        </p>
        <Button
          isOutlined
          text="Download as .csv file"
          className="w-full lg:w-1/3"
        />
      </Row>
      <Row className="items-center mt-6">
        <p className="text-lg font-poppins w-full lg:w-1/3">
          Erase all data for current year
        </p>
        <Button text="Delete all data" className="w-full lg:w-1/3" />
      </Row>
      <Row className="items-center mt-6">
        <p className="text-lg font-poppins w-full lg:w-1/3">
          Invite all the tutors/HODs to the portal
        </p>
        <Button
          handleClick={sendInvite}
          text="Invite"
          className="w-full lg:w-1/3"
        />
      </Row>
    </main>
  );
};

export default General;
