import React, { useContext, useEffect, useState } from "react";
import Button from "../../components/Button";
import Heading from "../../components/Heading";
import Row from "../../components/Row";
import TextArea from "../../components/TextArea";
import Toggle from "../../components/Toggle";
import MultipleFiles from "../../components/MultipleFiles";
import MultiSelect from "../../components/MultiSelect";
import { useNavigate } from "react-router-dom";
import { SubmissionContext } from ".";
import { toast } from "react-hot-toast";
import { fetchPostSection9, fetchUploadFile } from "../../API/calls";

const Section9 = () => {
  const [competitive, setCompetitive] = useState("Yes");
  const [exams, setExams] = useState([]);
  const [examDetails, setExamDetails] = useState("");
  const [examCertificates, setExamCertificates] = useState([]);
  const [examCertificatesURL, setExamCertificatesURL] = useState([]);
  const navigate = useNavigate();

  const [submission, setSubmission] = useContext(SubmissionContext);

  useEffect(() => {
    if (!submission) return;
    const draft = submission[9];
    if (!draft) return;
    setCompetitive(draft.hasExam ? "Yes" : "No");
    setExams(draft.exams ? draft.exams : []);
    setExamDetails(draft.description ? draft.description : "");
    setExamCertificatesURL(draft.certificates ? draft.certificates : []);
  }, [submission]);

  const handleExamsUpload = async (files, curr_no, total, shouldExit) => {
    if (files.length <= 0) {
      setExamCertificatesURL(examCertificatesURL);

      const postBody = {
        rollNo: localStorage.getItem("rollno"),
        hasExam: competitive === "Yes",
        exams: competitive === "Yes" ? exams : null,
        description: competitive === "Yes" ? examDetails : null,
        certificates: competitive === "Yes" ? examCertificatesURL : null,
      };

      toast.promise(fetchPostSection9(localStorage.getItem("rollno"), postBody), {
        loading: "Saving...",
        success: (res) => {
          shouldExit ? navigate("/login") : navigate("/apply/section-10");
          return "Section-9 saved successfully";
        },
        error: (err) => {
          return `Error Saving: ${err.message}`;
        },
      });
    }

    const currentFile = files.pop();
    toast.promise(fetchUploadFile(currentFile), {
      loading: `Uploading ${currentFile.name} (${curr_no}/${total})`,
      success: (res) => {
        let tempFileUrls = examCertificatesURL;
        tempFileUrls.push(res.data.url);
        setExamCertificatesURL(tempFileUrls);
        handleExamsUpload(files, curr_no + 1, total);
        return `Uploaded ${curr_no}/${total}`;
      },
      // error: (err) => `Error: ${err.message}`,
    });
  };

  const handleProceed = async (shouldExit) => {
    if (competitive === "Yes") {
      if (exams.length === 0) {
        toast.error("Please select at least one exam");
        return;
      }
      if (examDetails.length === 0) {
        toast.error("Please provide details about your exams");
        return;
      }
      if (examCertificates.length === 0 && examCertificatesURL.length === 0) {
        toast.error("Please upload at least one certificate");
        return;
      }
    }

    if (examCertificates.length > 0) {
      await handleExamsUpload(examCertificates, 1, examCertificates.length, shouldExit);
    } else {
      const postBody = {
        rollNo: localStorage.getItem("rollno"),
        hasExam: competitive === "Yes",
        exams: competitive === "Yes" ? exams : null,
        description: competitive === "Yes" ? examDetails : null,
        certificates: competitive === "Yes" ? examCertificatesURL : null,
      };

      toast.promise(fetchPostSection9(localStorage.getItem("rollno"), postBody), {
        loading: "Saving...",
        success: (res) => {
          shouldExit ? navigate("/login") : navigate("/apply/section-10");
          return "Section-9 saved successfully";
        },
        error: (err) => {
          return `Error Saving: ${err.message}`;
        },
      });
    };
  }

  return (
    <main className="w-full flex flex-col overflow-y-hidden">
      <div className="mb-8">
        <h2 className="font-poppins">Section 9</h2>
        <Heading>Competitive Exams</Heading>
      </div>
      <div className="overflow-y-scroll lg:pr-16 space-y-4 h-full lg:h-[calc(100vh-26rem)]">
        <Toggle
          title={"Have you appeared for any competitive exams ?"}
          options={["Yes", "No"]}
          valueState={[competitive, setCompetitive]}
        />
        {
          competitive === "Yes" && (
            <div>
              <MultiSelect
                className="w-full lg:w-[50%]"
                title="Exam List"
                placeholder="Select Exams"
                valueState={[exams, setExams]}
                options={[
                  "Graduate Aptitude Test in Engineering (GATE)",
                  "Joint Entrance Examination (JEE Main)",
                  "Joint Entrance Examination (JEE Advanced)",
                  "Kishore Vaigyanik Protsahan Yojana (KVPY)",
                  "Other"]}
              />
              <TextArea
                title="Provide detailed description about your exams"
                valueState={[examDetails, setExamDetails]}
              />
              <MultipleFiles
                title="Please attach certificates (In PDF format)"
                fileState={[examCertificates, setExamCertificates]}
                urlState={[examCertificatesURL, setExamCertificatesURL]}
                pdf
              />
            </div>
          )
        }
      </div>
      <Row className="mt-8">
        <Button text="Save and Proceed" className="w-full lg:w-1/3" handleClick={e => handleProceed(false)} />
        <Button isOutlined text="Save and Exit" className="w-full lg:w-1/3" handleClick={e => handleProceed(true)} />
      </Row>
    </main>
  );
};

export default Section9;
