import React from "react";
import { BsTelephone } from "react-icons/bs";
import { useNavigate } from "react-router-dom";

const Layout = ({ children }) => {
  const navigate = useNavigate();

  return (
    <main className="h-screen w-screen overflow-x-hidden lg:overflow-y-hidden flex flex-col ">
      <nav className="w-full  lg:px-[calc(100vw/12)] lg:mt-8 bg-white">
        <div className="bg-gradient-to-r from-ming to-pacific p-8 py-4 lg:py-8 lg:rounded-xl shadow-lg">
          <header className="flex space-x-4 items-center">
            <img
              src="https://upload.wikimedia.org/wikipedia/en/e/eb/PSG_College_of_Technology_logo.png"
              alt=""
              className="h-16 w-auto filter invert"
            />
            <div className=" text-white font-poppins hidden lg:block">
              <p className="text-2xl">
                Best Outgoing Student Awards {new Date().getFullYear()}
              </p>
              <p className="text-sm">PSG College of Technology, Coimbatore</p>
            </div>
            <div className="flex-1"> </div>
            {/* <button
              className="hover:bg-ming text-white px-4 py-2 font-poppins rounded-md flex items-center space-x-2 text-lg"
              onClick={() => {
                // window.open("https://www.psgtech.edu/cont.php");
                navigate("/contact");
              }}
            >
              <BsTelephone />
              <p className="">Contact Us</p>
            </button> */}
            <p className="text-sm font-poppins text-white">
              For any queries contact{" "}
              <a
                className="text-middle"
                href="mailto:bestoutgoing@psgtech.ac.in"
              >
                bestoutgoing@psgtech.ac.in
              </a>
            </p>
            <p className="text-sm font-poppins text-white">
              For tutorial{" "}
              <a
                className="text-middle"
                href="https://tutorialforstudents.netlify.app"
              >
                Click here
              </a>
            </p>
          </header>
        </div>
      </nav>
      <div className=" w-full flex-1 px-8 lg:px-[calc(100vw/12)] py-8 ">
        {children}
      </div>
    </main>
  );
};

export default Layout;
