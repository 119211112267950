/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import Layout from "../Layout";
import { Link, Outlet, useNavigate } from "react-router-dom";
import {
  MdKeyboardArrowDown,
  MdKeyboardArrowRight,
  MdLogout,
} from "react-icons/md";
import { FACULTY_AUTH_URL } from "../../API/config";
import axios from "axios";
import {
  fetchAllSubmissions,
  fetchAllSubmissionsByDept,
  fetchAllSubmissionsByTutor,
  fetchFacultyDetails,
} from "../../API/calls";
import { toast } from "react-hot-toast";
import { BsPersonPlus } from "react-icons/bs";
import {
  HiOutlineDocumentDuplicate,
  HiOutlineDocumentSearch,
} from "react-icons/hi";

export const FilterContext = React.createContext();
export const FacultyContext = React.createContext();

const Index = () => {
  const navigate = useNavigate();

  const [facultyDetails, setFacultyDetails] = useState(null);
  const [rawSubmissions, setRawSubmissions] = useState([]);
  const [submissions, setSubmissions] = useState(null);
  const [noSubmissions, setNoSubmissions] = useState(false);

  const [isApproved, setIsApproved] = useState(true);
  const [isRejected, setIsRejected] = useState(true);
  const [isPending, setIsPending] = useState(true);

  useEffect(() => {
    if (
      localStorage.getItem("token") &&
      (localStorage.getItem("rights") === "admin" ||
        localStorage.getItem("rights") === "tutor" ||
        localStorage.getItem("rights") === "hod") &&
      localStorage.getItem("email")
    ) {
      axios
        .get(`${FACULTY_AUTH_URL}/login/${localStorage.getItem("email")}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          console.log(res.data);
          toast.promise(fetchFacultyDetails(localStorage.getItem("email")), {
            loading: "Fetching details...",
            success: (res) => {
              setFacultyDetails(res.data.user);
              return "Details fetched successfully !";
            },
            error: "Error loading details !",
          });
        })
        .catch((err) => {
          localStorage.clear();
          navigate("/login");
        });
    } else {
      navigate("/login");
    }
  }, []);

  // For tutor
  useEffect(() => {
    if (!facultyDetails) return;
    localStorage.getItem("rights") === "tutor" &&
      toast.promise(fetchAllSubmissionsByTutor(facultyDetails.email), {
        loading: "Fetching Submissions",
        success: (res) => {
          console.log("RAW DUBM", res.data.data);
          if (res.data.data.length === 0) {
            setNoSubmissions(true);
          }
          setRawSubmissions(res.data.data);
          return "Successfully fetched submission";
        },
        error: (err) => {
          console.log(err);
          return "Error fetching submissions";
        },
      });
  }, [facultyDetails]);

  useEffect(() => {
    if (!rawSubmissions) return;
    console.log(rawSubmissions);
    localStorage.getItem("rights") === "tutor" &&
      setSubmissions(
        rawSubmissions.filter(
          (item) =>
            (isApproved && item.progress.approvedByTutor === "approved") ||
            (isRejected && item.progress.approvedByTutor === "rejected") ||
            (isPending && item.progress.approvedByTutor === "pending")
        )
      );
  }, [isApproved, isPending, isRejected, rawSubmissions]);

  useEffect(() => {
    console.log("SUBM", submissions);
  }, [submissions]);

  // For HOD
  useEffect(() => {
    if (!facultyDetails) return;
    let temp = [];
    localStorage.getItem("rights") === "hod" &&
      toast.promise(fetchAllSubmissionsByDept(facultyDetails.department), {
        loading: "Fetching submissions",
        success: (res) => {
          console.log(res.data.data);
          temp = [...temp, ...res.data.data];
          if (
            facultyDetails.department ===
              "ELECTRICAL & ELECTRONICS ENGINEERING" ||
            facultyDetails.department === "PRODUCTION ENGINEERING" ||
            facultyDetails.department === "MECHANICAL ENGINEERING"
          ) {
            toast.promise(
              fetchAllSubmissionsByDept(
                facultyDetails.department + " (SANDWICH)"
              ),
              {
                loading: "Fetching sandwich submissions",
                success: (res) => {
                  console.log("SAND", res.data.data);
                  temp = [...temp, ...res.data.data];
                  setRawSubmissions([...rawSubmissions, ...temp]);
                },
                error: "Error loading submissions !",
              }
            );
          }
          setRawSubmissions([...rawSubmissions, ...temp]);
          if (temp.length === 0) {
            setNoSubmissions(true);
          }
          return "Successfully fetched submission";
        },
        error: "Error loading submissions !",
      });
  }, [facultyDetails]);

  useEffect(() => {
    if (!rawSubmissions) return;
    localStorage.getItem("rights") === "hod" &&
      setSubmissions(
        rawSubmissions.filter(
          (item) =>
            (isApproved && item.progress.approvedByHOD === "approved") ||
            (isRejected && item.progress.approvedByHOD === "rejected") ||
            (isPending && item.progress.approvedByHOD === "pending")
        )
      );
  }, [isApproved, isPending, isRejected, rawSubmissions]);

  // For Admin
  useEffect(() => {
    if (!facultyDetails) return;
    localStorage.getItem("rights") === "admin" &&
      toast.promise(fetchAllSubmissions(), {
        loading: "Fetching submissions",
        success: (res) => {
          console.log(res.data.data);
          if (res.data.data.length === 0) {
            setNoSubmissions(true);
          }
          setRawSubmissions(res.data.data);
          return "Successfully fetched submission";
        },
        error: (err) => {
          console.log(err);
          return "Error fetching submissions";
        },
      });
  }, [facultyDetails]);

  useEffect(() => {
    if (!rawSubmissions) return;
    localStorage.getItem("rights") === "admin" &&
      setSubmissions(
        rawSubmissions.filter(
          (item) =>
            (isApproved && item.progress.approvedByAdmin === "approved") ||
            (isRejected && item.progress.approvedByAdmin === "rejected") ||
            (isPending && item.progress.approvedByAdmin === "pending")
        )
      );
  }, [isApproved, isPending, isRejected, rawSubmissions]);

  return (
    <Layout>
      <FacultyContext.Provider
        value={{
          facultyState: [facultyDetails, setFacultyDetails],
          submissionState: [submissions, setSubmissions],
          noSubsState: [noSubmissions, setNoSubmissions],
        }}
      >
        <FilterContext.Provider
          value={{
            approvedState: [isApproved, setIsApproved],
            rejectedState: [isRejected, setIsRejected],
            pendingState: [isPending, setIsPending],
          }}
        >
          <div className="w-full flex flex-col lg:flex-row h-full space-y-8 lg:space-y-0 lg:space-x-16">
            <FilterNav />
            <main className="w-full lg:w-2/3">
              <Outlet />
            </main>
          </div>
        </FilterContext.Provider>
      </FacultyContext.Provider>
    </Layout>
  );
};

export default Index;

const FilterNav = () => {
  const [shown, setShown] = useState(false);
  const [facultyDetails] = useContext(FacultyContext).facultyState;
  const { approvedState, rejectedState, pendingState } =
    useContext(FilterContext);
  const navigate = useNavigate();

  const [isApproved, setIsApproved] = approvedState;
  const [isRejected, setIsRejected] = rejectedState;
  const [isPending, setIsPending] = pendingState;

  useEffect(() => {
    let details = navigator.userAgent;

    let regexp = /android|iphone|kindle|ipad/i;

    let isMobileDevice = regexp.test(details);

    if (!isMobileDevice) {
      setShown(true);
    } else {
      setShown(false);
    }
  }, []);

  const handleLogout = (e) => {
    e.preventDefault();
    localStorage.clear();
    navigate("/login");
  };

  return (
    <nav className="w-full lg:w-1/3 h-full overflow-y-hidden">
      <div className="flex">
        <div className="flex-1  ">
          <h1 className="text-2xl text-ming font-poppins pb-6">
            Admin Portal for Best Outgoing Student Awards{" "}
            {new Date().getFullYear()}
          </h1>
          {facultyDetails && (
            <div className="">
              <h2 className="text-xl font-fira py-6">
                Welcome, {facultyDetails.name}!
              </h2>
              {facultyDetails.rights === "tutor" ? (
                <p className="font-poppins">
                  You are shown only the submissions from the department{" "}
                  <span className="font-bold">{facultyDetails.department}</span>
                  , and of only from your class.
                </p>
              ) : facultyDetails.rights === "hod" ? (
                <p className="font-poppins">
                  You are shown only the submissions from the department{" "}
                  <span className="font-bold">{facultyDetails.department}</span>
                  .
                </p>
              ) : (
                <p className="font-poppins">
                  You are shown all the submissions from all the department.{" "}
                </p>
              )}
            </div>
          )}
          <div className="flex flex-wrap w-full gap-4 mt-4 font-poppins text-sm">
            <button
              onClick={(e) => {
                setIsApproved(true);
                setIsRejected(false);
                setIsPending(false);
              }}
              className={`px-6 py-2 rounded-full ${
                isApproved && "bg-[#c3ffe2]"
              } text-[#2bb673] border-2 border-[#2bb673]`}
            >
              Approved
            </button>
            <button
              onClick={(e) => {
                setIsRejected(true);
                setIsPending(false);
                setIsApproved(false);
              }}
              className={`px-6 py-2 rounded-full ${
                isRejected && "bg-[#ffafbf]"
              } text-[#ff0033] border-2 border-[#ff0033]`}
            >
              Rejected
            </button>
            <button
              onClick={(e) => {
                setIsPending(true);
                setIsApproved(false);
                setIsRejected(false);
              }}
              className={`px-6 py-2 rounded-full ${
                isPending && "bg-[#fff7d4]"
              } text-[#ff9d00] border-2 border-[#ff9d00]`}
            >
              Pending
            </button>
            <button
              onClick={(e) => {
                setIsApproved(true);
                setIsRejected(true);
                setIsPending(true);
              }}
              className={`px-6 py-2 rounded-full bg-[#eaeaea] text-[#303030] border-2 border-[#303030]`}
            >
              Show All
            </button>
          </div>
          {localStorage.getItem("rights") === "admin" && (
            <div className="flex flex-row w-full mt-8">
              <Link
                className="text-ming font-poppins flex flex-row gap-2"
                to="/admin-portal/submissions"
              >
                <HiOutlineDocumentSearch size={20} className="text-ming" />
                Submissions
              </Link>
            </div>
          )}
          {localStorage.getItem("rights") === "admin" && (
            <div>
              <div className="flex flex-row w-full mt-4">
                <Link
                  className="text-ming font-poppins flex flex-row gap-2"
                  to="/admin-portal/all-submissions"
                >
                  <HiOutlineDocumentDuplicate size={20} className="text-ming" />
                  HOD Approved Submissions
                </Link>
              </div>
              <div className="flex flex-row w-full mt-4">
                <Link
                  className="text-ming font-poppins flex flex-row gap-2"
                  to="/admin-portal/recommendations"
                >
                  <BsPersonPlus size={20} className="text-ming" />
                  Recommendations
                </Link>
              </div>
            </div>
          )}
          <div className="flex-1"></div>
          <div className="flex flex-row w-full mt-8">
            <button
              className="text-ming font-poppins flex flex-row gap-2"
              onClick={(e) => handleLogout(e)}
            >
              <MdLogout size={20} className="text-ming" />
              Logout
            </button>
          </div>
        </div>
        <button className="lg:hidden" onClick={() => setShown(!shown)}>
          {shown ? (
            <MdKeyboardArrowDown size={36} className="text-ming" />
          ) : (
            <MdKeyboardArrowRight size={36} className="text-ming" />
          )}
        </button>
      </div>
      {shown && <div className="flex flex-col w-full mt-8 "></div>}
    </nav>
  );
};
