export const noDetails = (text) => `
  <div style="width: 25%; padding: 10px">
        <p style='margin:5px'> ${text}</p>
    </div>
  `;

export const userProfile = (user) => {
  return `
    <div style="width: 100%; background: #eeeeee; padding: 10px; display:flex;">
        <div style="width: 80; text-align: left; flex:0.3; display:flex; align-items: left; justify-content:left;">
            <img    
            width="80"
            src="https://upload.wikimedia.org/wikipedia/en/e/eb/PSG_College_of_Technology_logo.png"
            />
        </div>
        <div style="width:100%; text-align: left; padding: 5px; flex:0.7; display:flex; flex-direction:column;">
            <p style="line-height: 1rem; font-size: 1.5rem; font-weight: 700; flex:0.4">
            ${user?.userDetails?.name}
            </p>
            <p style="font-weight: 500; font-size: 1rem; margin: 0; flex:0.3">
            Application for Best Outgoing Student Awards ${new Date().getFullYear()}
            </p>
            <p style="margin-top: 0.25rem; flex:0.3; font-size: 1rem;">PSG College of Technology</p>
        </div>
    </div>
    <table style="width: 100%; background: #ffffff; padding: 10px" >
      <tr>
        <td style="width: 30%">
          <img src="${user[1]?.photoUrl}" height="200" width="170" />
        </td>
        <td style="width: 70%">
          <table>
            <tr style="margin: 0">
              <td style="width: 25%; padding: 10px">
                <b>Name</b>
              </td>
              <td style="width: 75%; padding: 10px">
                <p class="">${user?.userDetails?.name}</p>
              </td>
            </tr>
            <tr>
              <td style="width: 25%; padding: 10px">
                <b class="">Roll No.</b>
              </td>
              <td style="width: 75%; padding: 10px">
                <p class="">${user?.userDetails?.rollNo}</p>
              </td>
            </tr>
            <tr style="margin: 0">
              <td style="width: 25%; padding: 10px">
                <b>Department</b>
              </td>
              <td style="width: 75%; padding: 10px;">
                <p style="text-transform: capitalize;"> ${
                  user?.userDetails?.dept
                }</p>
              </td>
            </tr>
            <tr style="margin: 0">
              <td style="width: 25%; padding: 10px">
                <b>Specialization (if any)</b>
              </td>
              <td style="width: 75%; padding: 10px;">
                <p style="text-transform: capitalize;"> ${
                  user?.tutor?.specialization &&
                  user?.tutor?.specialization.length > 0
                    ? user?.tutor?.specialization
                    : "NIL"
                }</p>
              </td>
            </tr>
            <tr style="margin: 0">
            <td style="width: 25%; padding: 10px">
              <b>Programme</b>
            </td>
            <td style="width: 75%; padding: 10px;">
              <p style="text-transform: capitalize;"> ${
                user[2]?.programStudying
              }</p>
            </td>
          </tr>
            <tr>
              <td style="width: 25%; padding: 10px">
                <b class="">Email</b>
              </td>
              <td style="width: 75%; padding: 10px">
                <p class="">${user?.userDetails?.email}</p>
              </td>
            </tr>
          </table>
        </td>
      </tr>
    </table>`;
};

export const personalDetails = (user) => `
    <table style="padding: 10px; margin-top: 50px">
      <tr>
        <td style="font-size: 1.5rem; font-weight: 700">
          <p style="margin: 0; padding: 0">Personal Details</p>
        </td>
      </tr>
    </table>
    <table style="width: 100%; padding: 10px;">
      <tr style="margin: 0">
        <td style="width: 25%; padding: 10px">
          <b>Name</b>
        </td>
        <td style="width: 25%; padding: 10px">
          <p class="">${user?.userDetails?.name}</p>
        </td>
        <td style="width: 25%; padding: 10px">
          <b>Roll No.</b>
        </td>
        <td style="width: 25%; padding: 10px">
          <p class="">${user?.userDetails?.rollNo}</p>
        </td>
      </tr>
      <tr>
        <td style="width: 25%; padding: 10px">
          <b class="">Father's Name</b>
        </td>
        <td style="width: 25%; padding: 10px">
          <p class="">${user[1]?.fatherName}</p>
        </td>
      </tr>
      <tr style="margin: 0">
        <td style="width: 25%; padding: 10px">
          <b class="">Year of Joining</b>
        </td>
        <td style="width: 25%; padding: 10px">
          <p class="">${user[1]?.yearOfJoining}</p>
        </td>
        <td style="width: 25%; padding: 10px">
          <b class="">+2 Cutoff</b>
        </td>
        <td style="width: 25%; padding: 10px">
          <p class="">${user[1]?.plus2CutOff}</p>
        </td>
      </tr>
      <tr style="margin: 0">
        <td style="width: 25%; padding: 10px">
          <b class="">Name of Tutor</b>
        </td>
        <td style="width: 25%; padding: 10px">
          <p class="">${user[1]?.tutorName}</p>
        </td>
        <td style="width: 25%; padding: 10px">
          <b class="">Tutor's Email</b>
        </td>
        <td style="width: 25%; padding: 10px">
          <p class="">${user[1]?.tutorEmail}</p>
        </td>
      </tr>
      <tr style="margin: 0;">
        <td style="padding: 10px" colspan="4">
          <b class="">Communication Address</b>
        </td>
      </tr>
      <tr style="margin:0;">
        <td style="padding: 10px" colspan="4">
            <p style="text-indent:30px">${user[1]?.communicationAddress
              .split("\n")
              .join("<br>")}</>
        </td>
      </tr>
      <tr style="margin: 0">
        <td style="padding: 10px;" colspan="4">
          <b class="">Permanent Address</b>
        </td>
      </tr>
      <tr style="margin:0">
        <td style="padding: 10px" colspan="4">
          <p style="text-indent:30px">${user[1]?.permanentAddress
            .split("\n")
            .join("<br>")}</p>
        </td>
      </tr>
    </table>
    `;

export const academicsDetials = (user) => `
    <table style="padding: 10px; margin-top: 50px">
      <tr>
        <td style="font-size: 1.5rem; font-weight: 700">
          <p style="margin: 0; padding: 0">Academics Details</p>
        </td>
      </tr>
    </table>
    <table style="width: 100%; padding: 10">
      <tr style="margin: 0">
        <td style="width: 25%; padding: 10px">
          <b>Programme</b>
        </td>
        <td style="width: 25%; padding: 10px">
          <p class="">${user[2]?.programStudying}</p>
        </td>
        <td style="width: 25%; padding: 10px">
          <b>CGPA</b>
        </td>
        <td style="width: 25%; padding: 10px">
          <p class="">${user[2]?.CGPA}</p>
        </td>
      </tr>
      <tr>
        <td style="width: 25%; padding: 10px">
          <b class="">GPA Sem 1</b>
        </td>
        <td style="width: 25%; padding: 10px">
          <p class="">${user[2]?.GPA1 ? user[2]?.GPA1 : "N/A"}</p>
        </td>
        <td style="width: 25%; padding: 10px">
          <b class="">GPA Sem 2</b>
        </td>
        <td style="width: 25%; padding: 10px">
          <p class="">${user[2]?.GPA2 ? user[2]?.GPA2 : "N/A"}</p>
        </td>
      </tr>
      <tr style="margin: 0">
        <td style="width: 25%; padding: 10px">
          <b class="">GPA Sem 3</b>
        </td>
        <td style="width: 25%; padding: 10px">
          <p class="">${user[2]?.GPA3 ? user[2]?.GPA3 : "N/A"}</p>
        </td>
        <td style="width: 25%; padding: 10px">
          <b class="">GPA Sem 4</b>
        </td>
        <td style="width: 25%; padding: 10px">
          <p class="">${user[2]?.GPA4 ? user[2]?.GPA4 : "N/A"}</p>
        </td>
      </tr>
      <tr style="margin: 0">
        <td style="width: 25%; padding: 10px">
          <b class="">GPA Sem 5</b>
        </td>
        <td style="width: 25%; padding: 10px">
          <p class="">${user[2]?.GPA5 ? user[2]?.GPA5 : "N/A"}</p>
        </td>
        <td style="width: 25%; padding: 10px">
          <b class="">GPA Sem 6</b>
        </td>
        <td style="width: 25%; padding: 10px">
          <p class="">${user[2]?.GPA6 ? user[2]?.GPA6 : "N/A"}</p>
        </td>
      </tr>
      <tr style="margin: 0">
        <td style="width: 25%; padding: 10px">
          <b class="">GPA Sem 7</b>
        </td>
        <td style="width: 25%; padding: 10px">
          <p class="">${user[2]?.GPA7 ? user[2]?.GPA7 : "N/A"}</p>
        </td>
        <td style="width: 25%; padding: 10px">
          <b class="">GPA Sem 8</b>
        </td>
        <td style="width: 25%; padding: 10px">
          <p class="">${user[2]?.GPA8 ? user[2]?.GPA8 : "N/A"}</p>
        </td>
      </tr>
      <tr
        style="
          margin: 0;
          ${
            user[2]?.gpaCount === 8 ||
            (user[2]?.GPA9 == null && user[2]?.GPA10 == null)
              ? "display:none;"
              : ""
          }
        "
      >
        <td style="width: 25%; padding: 10px">
          <b class="">GPA Sem 9</b>
        </td>
        <td style="width: 25%; padding: 10px">
          <p class="">${user[2]?.GPA9 ? user[2]?.GPA9 : "N/A"}</p>
        </td>
        <td style="width: 25%; padding: 10px">
          <b class="">GPA Sem 10</b>
        </td>
        <td style="width: 25%; padding: 10px">
          <p class="">${user[2]?.GPA10 ? user[2]?.GPA10 : "N/A"}</p>
        </td>
      </tr>
    </table>`;

export const internshipDetails = (user) => `
  <table style="padding: 10px; margin-top:50px; width:100%; ">
    <tr>
        <td style="font-size: 1.5rem; font-weight: 700" colspan="3">
            <p style="margin: 0; padding: 0">Internship Details</p>
        </td>
    </tr>
</table>
<table>
  ${
    user[3] === null ||
    user[3].internships === null ||
    user[3].internships.length <= 0
      ? noDetails("No internship details")
      : user[3]?.internships
          ?.map(
            (intern, idx) =>
              `<tr style="margin: 0">
                <td style="width: 25%; padding: 10px">
                    <b class=""> Company ${idx + 1}:</b>
                </td>
            </tr>
            <tr style="margin: 0">
                <td style="width: 25%; padding: 10px">
                    <b class="">Company Name</b>
                </td>
                <td style="width: 25%; padding: 10px">
                    <p class="">${intern?.companyName}</p>
                </td>
                <td style="width: 25%; padding: 10px">
                    <b class="">Duration</b>
                </td>
                <td style="width: 25%; padding: 10px">
                    <p class="">${intern?.duration} Months</p>
                </td>
            </tr>
            <tr style="margin: 0">
                <td style="width: 25%; padding: 10px">
                    <b class="">Stipend</b>
                </td>
                <td style="width: 25%; padding: 10px">
                    <p class="">${
                      intern?.stipend ? `Rs ${intern?.stipend} /-` : "N/A"
                    }</p>
                </td>
                <td style="width: 25%; padding: 10px">
                    <b class="">On Campus</b>
                </td>
                <td style="width: 25%; padding: 10px">
                    <p class="">${intern?.throughPlacement ? "Yes" : "No"}</p>
                </td>
            </tr>
            <tr>
                <td style="width: 25%; padding: 10px">
                    <b class="">Outcome</b>
                </td>
            </tr>
            <tr>
                <td style="padding: 10px" colspan="4">
                    <p style="text-indent:30px">${intern?.outcome} </p>
                </td>
            </tr> `
          )
          .join("")
  }
  </table>
`;

export const abroadStudies = (user) => `
    <table style="padding: 10px; margin-top: 50px">
        <tr>
            <td style="font-size: 1.5rem; font-weight: 700">
                <p style="margin: 0; padding: 0">Abroad Studies Details</p>
            </td>
        </tr>
    </table>
    ${
      user[4]?.hasSemesterAbroad
        ? `
    <table style="width: 100%; padding: 10">
        <tr style="margin: 0">
            <td style="width: 25%; padding: 10px">
                <b>University Name</b>
            </td>
            <td style="width: 25%; padding: 10px">
                <p class="">${user[4]?.universityName}</p>
            </td>
            <td style="width: 25%; padding: 10px">
                <b>Duration</b>
            </td>
            <td style="width: 25%; padding: 10px">
                <p class="">${user[4]?.duration} Months</p>
            </td>
        </tr>
        <tr style="margin: 0">
            <td style="padding: 10px">
                <b>Address</b>
            </td>
        </tr>
        <tr>
            <td style="padding: 10px" colspan="4">
                <p style="text-indent:30px;">${user[4]?.universityAddress
                  .split("\n")
                  .join("<br>")}</p>
            </td>
        </tr>
        <tr style="margin: 0">
            <td style="width: 25%; padding: 10px">
                <b>Email</b>
            </td>
            <td style="width: 25%; padding: 10px">
                <p class="">${user[4]?.universityEmail}</p>
            </td>
        </tr>
    </table>
  </div>
    `
        : noDetails("No Abroad Studies")
    }
    
  `;

export const placementHigherStudiesDetails = (user) => `
  <div style="${user[5] != null ? "" : "display:none;"}">

  ${
    user[5]?.placement !== "Not Opted for Placement"
      ? `
        <table style="padding: 10px; margin-top: 50px">
            <tr>
                <td style="font-size: 1.5rem; font-weight: 700">
                    <p style="margin: 0; padding: 0">Placement</p>
                </td>
            </tr>
        </table>
        ${
          user[5]?.companyName != null
            ? ` <table style="width: 100%; padding: 10">
            <tr style="margin: 0">
                <td style="width: 25%; padding: 10px">
                <b>Company Name</b>
                </td>
                <td style="width: 25%; padding: 10px">
                    <p class="">${user[5]?.companyName}</p>
                </td>
            </tr>
            <tr style="margin: 0">
                <td style="width: 25%; padding: 10px">
                    <b>Placement through</b>
                </td>
                <td style="width: 25%; padding: 10px">
                    <p class="">${user[5]?.placement}</p>
                </td>
            </tr>
        </table>
        `
            : `<table style="width: 100%; padding: 10">
            <tr style="margin: 0">
                <td style="width: 25%; padding: 10px">
                    Not Secured Placement
                </td>
            </tr>
        </table>`
        }
        </div>
       
        `
      : `
        <table style="padding: 10px; margin-top: 50px">
            <tr>
                <td style="font-size: 1.5rem; font-weight: 700">
                    <p style="margin: 0; padding: 0">Higher Studies</p>
                </td>
            </tr>
        </table>
        <table style="width: 100%; padding: 10">
            <tr style="margin: 0">
                <td style="width: 25%; padding: 10px">
                    <b>Reason</b>
                </td>
            </tr>
            <tr>
                <td style="width: 100%%; padding: 10px">
                    <p style="text-indent:30px">${user[5]?.reason}</p>
                </td>
            </tr>
            ${
              user[5]?.higherStudies != null
                ? `<tr>
                <td style="width: 25%; padding: 10px">
                    <b>Region</b>
                </td>
                <td style="width: 25%; padding: 10px">
                    <p class="">${user[5]?.higherStudies}</p>
                </td>
            </tr>
            <tr style="margin: 0">
                <td style="width: 25%; padding: 10px">
                    <b>University Name</b>
                </td>
                <td style="width: 25%; padding: 10px">
                    <p class="">${
                      user[5]?.universityName ? user[5]?.universityName : "N/A"
                    }</p>
                </td>
            </tr>`
                : "Not opted for placement"
            }
        </table>
        `
  }
    </div>`;

export const researchPapersDetails = (user) => `
  <table style="padding: 10px; margin-top: 50px">
      <tr>
          <td style="font-size: 1.5rem; font-weight: 700">
              <p style="margin: 0; padding: 0">Research Paper Details</p>
          </td>
      </tr>
  </table>
  ${
    user[6]?.havePublished
      ? `
  
  <table style="width: 100%; padding: 10">
        <tr style="margin: 0">
            <td style="width: 25%; padding: 10px">
                <b>Journal Type</b>
            </td>
            <td style="padding: 10px">
                <p class="">${
                  user[6]?.journalType === "Both"
                    ? "National & International"
                    : user[6]?.journalType
                }</p>
            </td>
        </tr>
        <tr style="margin: 0">
            <td style="width: 25%; padding: 10px;">
                <b>Journal Details</b>
            </td>
        </tr>
        <tr>
            <td style="padding: 10px;" colspan="4">
                <p style="text-indent:30px;">${user[6]?.journalDetails
                  .split("\n")
                  .join("<br>")}</p>
            </td>
        </tr>
    </table>
  `
      : noDetails("Not published any papers")
  }

  ${
    user[6]?.haveConference
      ? `
  
  <table style="width: 100%; padding: 10">
      <tr style="margin: 0">
      <td style="width: 25%; padding: 10px">
          <b>Attended Conference</b>
      </td>
      <td style="padding: 10px">
          <p class="">${user[6]?.haveConference ? "Yes" : "No"}</p>
      </td>
    </tr>
    <tr style="margin: 0">
      <td style="width: 25%; padding: 10px">
          <b>Conference Type</b>
      </td>
      <td style="padding: 10px">
          <p class="">${
            user[6]?.conferenceType === "Both"
              ? "National & International"
              : user[6]?.conferenceType
          }</p>
      </td>
    </tr>
    <tr style="margin: 0">
      <td style="width: 25%; padding: 10px;">
          <b>Conference Details</b>
      </td>
    </tr>
    <tr>
      <td style="padding: 10px;" colspan="4">
          <p style="text-indent:30px;">${user[6]?.conferenceDetails
            .split("\n")
            .join("<br>")}</p>
      </td>
    </tr>
    </table>
  `
      : noDetails("No conferences attended")
  }
  `;

export const recognition = (user) => `
    <table style="padding: 10px; margin-top: 50px">
        <tr>
            <td style="font-size: 1.5rem; font-weight: 700">
                <p style="margin: 0; padding: 0">Recognition in Department</p>
            </td>
        </tr>
    </table>
    <table style="width: 100%; padding: 10">
        <tr>
            <td style="padding: 10px" colspan="4">
                <p style="text-indent:30px;">
                ${
                  user[7]?.recognition.length === 0
                    ? "No recognition details"
                    : user[7]?.recognition.split("\n").join("<br>")
                }
                </p>
            </td>
        </tr>
    </table>

  `;

export const sportsDetails = (user) => `
  <table style="padding: 10px; margin-top: 50px">
      <tr>
          <td style="font-size: 1.5rem; font-weight: 700">
              <p style="margin: 0; padding: 0">Sports, Club and Extra Activites</p>
          </td>
      </tr>
  </table>
  ${
    user[8]?.haveParticipated
      ? `
  <table style="width: 100%; padding: 10">
    ${
      user[8]?.sportsName?.length > 0
        ? `<tr style="margin: 0">
            <td style="width: 25%; padding: 10px">
                <b>Sports:</b>
            </td>
            <td style="padding: 10px; display:flex; width:100%;" colspan="3">
                ${user[8]?.sportsName?.map((name) => name).join(", ")}
            </td>
        </tr>
        <tr style="margin: 0">
            <td style="width: 25%; padding: 10px">
                <b>Captain</b>
            </td>
            <td style="width: 25%; padding: 10px">
                <p class="">${user[8]?.haveCaptained ? "Yes" : "No"}</p>
            </td>
                <td style="width: 25%; padding: 10px">
                <b>Highest level of Participation</b>
            </td>
            <td style="width: 25%; padding: 10px">
                <p class="">${user[8]?.levelOfParticipation}</p>
            </td>
        </tr>
        <tr style="margin: 0">
            <td style="width: 25%; padding: 10px">
                <b>Description</b>
            </td>
        </tr>
        <tr>
            <td style="width: 100%; padding: 10px" colspan="4">
                <p style="text-indent:30px;">${user[8]?.sportsDescription
                  .split("\n")
                  .join("<br>")}</p>
            </td>
        </tr>`
        : ""
    }
        <tr style="margin: 0">
            <td style="width: 25%; padding: 10px">
                <b>Extra Curricular Activities</b>
            </td>
            <td style="width: 25%; padding: 10px">
                <p class="">${
                  user[8]?.extras ? user[8]?.extras : "No Participation"
                }</p>
            </td>
        </tr>
        <tr style="margin: 0">
            <td style="width: 25%; padding: 10px">
                <b>Clubs</b>
            </td>
            <td style="width: 25%; padding: 10px">
                <p class="">${
                  user[8]?.clubs ? user[8]?.clubs : "No Participation"
                }</p>
            </td>
        </tr>
        <tr>
            <td style="width: 25%; padding: 10px">
                <b>Description</b>
            </td>
        </tr>
        <tr>
            <td style="padding: 10px; width:100%;" colspan="4">
                <p style="text-indent:30px;">${
                  user[8]?.extraDescription
                    ? user[8]?.extraDescription.split("\n").join("<br>")
                    : "N/A"
                }</p>
            </td>
        </tr>
  </table>
  `
      : noDetails("No participation")
  }`;

export const competitiveExamDetails = (user) => `
    <table style="padding: 10px; margin-top:10px">
            <tr>
                <td style="font-size: 1.5rem; font-weight: 700">
                    <p style="margin: 0; padding: 0">Competitive Exams</p>
                </td>
            </tr>
    </table>
    ${
      user[9]?.hasExam
        ? `
        <table style="width: 100%; padding: 10">
            <tr style="margin: 0">
                <td style="width: 25%; padding: 10px">
                    <b>Exams</b>
                </td>
                <td style="width: 75%; padding: 10px; display:flex;" colspan="3">
                    ${user[9]?.exams?.map((exam) => exam).join(", ")}
                </td>
            </tr>
            <tr>
                <td style="width: 25%; padding: 10px">
                    <b>Description</b>
                </td>
            </tr>
            <tr>
                <td style="width: 75%; padding: 10px" colspan="4">
                    <p style="text-indent:30px;">${
                      user[9]?.description
                        ? user[9]?.description.split("\n").join("<br>")
                        : "No Description"
                    }</p>
                </td>
            </tr>
        </table>
        `
        : noDetails("No participation")
    }
    
  `;

export const statementOfPurpose = (user) => `
    <table style="padding: 10px; margin-top: 50px">
        <tr>
            <td style="font-size: 1.5rem; font-weight: 700">
                <p style="margin: 0; padding: 0">Statement of Purpose</p>
            </td>
        </tr>
    </table>
    <table style="width: 100%; padding: 10">
        <tr>
            <td style="padding: 10px" colspan="4">
                <p style="text-indent:30px;">${user[10]?.reason
                  .split("\n")
                  .join("<br>")}</p>
            </td>
        </tr>
    </table>

  `;

export const headingPage = (data, user) => `
    <div style="padding: 20; height: 100vh; display:flex; align-items:center; justify-content:center; text-align:center; flex-direction:column;">
        <div style="font-size: 4rem; font-weight: 900">
            <p style="margin: 0; padding: 0">${data}</p>
        </div>
        <div style="margin-top:100px; font-size: 3rem; font-weight: 200;">
            <p>${user?.userDetails?.rollNo.toUpperCase()}</p>
        </div>
        <div style="font-size: 3rem; font-weight: 200;">
            <p >${user?.userDetails?.name.toUpperCase()}</p>
        </div>
    </div>
  `;

export const imageAttachement = (url) => `
  <div style="background-image: url(${url}); height: 100vh;background-position: center; background-repeat: no-repeat; background-size: cover;"> </div>
  `;

export const signatureTemplate = (
  user
) => `<div style="width:100%; display:flex; flex-direction:column; margin-top:30px;">
  <div style="width: 100%; background: #eeeeee; padding: 10px; display:flex;">
        <div style="width: 80; text-align: left; flex:0.3; display:flex; align-items: left; justify-content:left;">
            <img    
            width="80"
            src="https://upload.wikimedia.org/wikipedia/en/e/eb/PSG_College_of_Technology_logo.png"
            />
        </div>
        <div style="width:100%; text-align: left; padding: 5px; flex:0.7; display:flex; flex-direction:column;">
            <p style="line-height: 1rem; font-size: 1.5rem; font-weight: 700; flex:0.4">
            ${user?.userDetails?.name}
            </p>
            <p style="font-weight: 500; font-size: 1rem; margin: 0; flex:0.3">
            Application for Best Outgoing Student Awards ${new Date().getFullYear()}
            </p>
            <p style="margin-top: 0.25rem; flex:0.3; font-size: 1rem;">PSG College of Technology</p>
        </div>
    </div>
    <div style="width:100%; text-align: left; padding: 10px; margin-top: 20px; display:flex">
        <p style="height:100%; text-indent:30px"> Hard copy of the application must be authorized by the Student, Tutor, Programme Coordinator and HoD. </p>
    </div>
    <div style="text-align: left; padding: 20px;">
        <p style="height:100%;"> <b> Signatures </b> </p>
    <div>
    <table style="width: 100%; padding: 30px; margin-top: 30px">
      <tr style="margin-top: 20px">
        <td style="width: 50%; padding: 30px">
          <b>Student</b>
        </td>
        <td style="width: 50%; padding: 30px; text-align: center">
          <b>Tutor</b>
        </td>
      </tr>
      <tr style="margin-top: 40px">
        <td style="width: 50%; padding: 30px">
          <b>Programme Coordinator</b>
        </td>
        <td style="width: 50%; padding: 30px; text-align: center">
          <b>HoD</b>
        </td>
      </tr>
  </table>
</div>`;
