import React, { useContext, useEffect, useState } from "react";
import Button from "../../components/Button";
import Heading from "../../components/Heading";
import Row from "../../components/Row";
import TextArea from "../../components/TextArea";
import Inputfield from "../../components/TextInput";
import Toggle from "../../components/Toggle";
import FileUpload from "../../components/FileUpload";
import { useNavigate } from "react-router-dom";
import { SubmissionContext } from ".";
import { toast } from "react-hot-toast";
import { fetchPostSection4, fetchUploadFile } from "../../API/calls";
import Number from "../../components/Number";

const Section4 = () => {
  const [abroad, setAbroad] = useState("Yes");
  const [universityName, setUniversityName] = useState("");
  const [universityEmail, setUniversityEmail] = useState("");
  const [universityAddress, setUniversityAddress] = useState("");
  const [duration, setDuration] = useState(0);
  const [contactPerson, setContactPerson] = useState("");
  const [proof, setProof] = useState(null);
  const [proofUrl, setProofUrl] = useState("");

  const navigate = useNavigate();

  const [submission, setSubmission] = useContext(SubmissionContext);

  useEffect(() => {
    if (!submission) return;
    const draft = submission[4];
    if (!draft) return;
    setAbroad(draft.hasSemesterAbroad ? "Yes" : "No");
    setUniversityName(draft.universityName);
    setUniversityEmail(draft.universityEmail);
    setUniversityAddress(draft.universityAddress);
    setDuration(draft.duration ? draft.duration : 0);
    setContactPerson(draft.poc);
    setProofUrl(draft.proofUrl);
  }, [submission]);

  const handleProceed = async (shouldExit) => {
    if (
      abroad === "Yes" && (
        universityName === null || universityName.length <= 0 ||
        universityEmail === null || universityEmail.length <= 0 ||
        universityAddress === null || universityAddress.length <= 0 ||
        duration === null || duration <= 0 ||
        contactPerson === null || contactPerson.length <= 0 ||
        (proof === null && (proofUrl === null || proofUrl.length <= 0)))
    ) {
      return toast.error("Enter all the fields");
    }

    if (proof !== null) {
      toast.promise(fetchUploadFile(proof), {
        loading: "Uploading...",
        success: (res) => {
          setProofUrl(res.data.url);

          const postBody = {
            rollNo: localStorage.getItem("rollno"),
            hasSemesterAbroad: abroad === "Yes",
            universityName: abroad === "Yes" ? universityName : null,
            universityEmail: abroad === "Yes" ? universityEmail : null,
            universityAddress: abroad === "Yes" ? universityAddress : null,
            duration: abroad === "Yes" ? duration : null,
            poc: abroad === "Yes" ? contactPerson : null,
            proofUrl: abroad === "Yes" ? res.data.url : null,
          };

          toast.promise(fetchPostSection4(localStorage.getItem("rollno"), postBody), {
            loading: "Saving...",
            success: (res) => {
              shouldExit ? navigate("/login") : navigate("/apply/section-5");
              return "Section-4 successfully saved.";
            },
            error: (err) => {
              return `Error saving: ${err.response.data.error}`;
            },
          });

          return "File uploaded successfully.";
        },
        error: (err) => {
          return `Error uploading: ${err.response.data.error}`;
        },
      });
    } else {
      const postBody = {
        rollNo: localStorage.getItem("rollno"),
        hasSemesterAbroad: abroad === "Yes",
        universityName: abroad === "Yes" ? universityName : null,
        universityEmail: abroad === "Yes" ? universityEmail : null,
        universityAddress: abroad === "Yes" ? universityAddress : null,
        duration: abroad === "Yes" ? duration : null,
        poc: abroad === "Yes" ? contactPerson : null,
        proofUrl: abroad === "Yes" ? proofUrl : null,
      };

      toast.promise(fetchPostSection4(localStorage.getItem("rollno"), postBody), {
        loading: "Saving...",
        success: (res) => {
          shouldExit ? navigate("/login") : navigate("/apply/section-5");
          return "Section-4 successfully saved.";
        },
        error: (err) => {
          return `Error saving: ${err.response.data.error}`;
        },
      });
    }
  };

  return (
    <main className="w-full flex flex-col overflow-y-hidden">
      <div className="mb-8">
        <h2 className="font-poppins">Section 4</h2>
        <Heading>Semester in Abroad</Heading>
      </div>
      <div className="overflow-y-scroll lg:pr-16 space-y-4 h-full lg:h-[calc(100vh-26rem)]">
        <Toggle
          title={"Have you studied any semester abroad ?"}
          options={["Yes", "No"]}
          valueState={[abroad, setAbroad]}
        />
        {abroad === "Yes" && (
          <div>
            <Row>
              <Inputfield
                title="Name of the University"
                valueState={[universityName, setUniversityName]}
              />
              <Inputfield
                title="University Email ID"
                valueState={[universityEmail, setUniversityEmail]}
              />
            </Row>
            <TextArea
              title="University Address"
              valueState={[universityAddress, setUniversityAddress]}
            />
            <Row>
              <Inputfield
                title="Name of the contact person"
                valueState={[contactPerson, setContactPerson]}
              />
              <Number
                title="Duration (in months)"
                valueState={[duration, setDuration]}
              />
            </Row>
            <FileUpload
              title="Proof of Semester Abroad"
              fileState={[proof, setProof]}
              url={proofUrl}
              pdf
            />
          </div>
        )}
      </div>
      <Row className="mt-8">
        <Button
          text="Save and Proceed"
          className="w-full lg:w-1/3"
          handleClick={(e) => handleProceed(false)}
        />
        <Button
          isOutlined
          text="Save and Exit"
          className="w-full lg:w-1/3"
          handleClick={(e) => handleProceed(true)}
        />
      </Row>
    </main>
  );
};

export default Section4;
