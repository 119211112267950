import axios from "axios";
import {
  APPLY_URL,
  AUTH_URL,
  UPLOAD_URL,
  FACULTY_AUTH_URL,
  RECOMMEND_URL,
  SETTINGS_URL,
} from "./config";
import imageCompression from "browser-image-compression";

// For Auth
export const fetchRegisterUser = (postBody) =>
  axios.post(`${AUTH_URL}/register`, postBody, {});

export const fetchVerifyUserMail = (postBody) =>
  axios.post(`${AUTH_URL}/verify-mail`, postBody, {});

export const fetchStudentLoginVerify = (postBody) =>
  axios.post(`${AUTH_URL}/login`, postBody, {});

export const fetchForgotPasswordOTP = (postBody) =>
  axios.post(`${AUTH_URL}/forgot-password`, postBody, {});

export const fetchForgotPasswordVerifyOTP = (postBody) =>
  axios.post(`${AUTH_URL}/verify-otp`, postBody, {});

export const fetchPasswordReset = (postBody) =>
  axios.post(`${AUTH_URL}/reset-password`, postBody, {});

export const fetchFacultyLogin = (postBody) =>
  axios.post(`${FACULTY_AUTH_URL}/login`, postBody, {});

export const fetchForgtPasswordFaculty = (postBody) =>
  axios.post(`${FACULTY_AUTH_URL}/forgot-password`, postBody, {});

export const fetchFacultyOTPVerify = (postBody) =>
  axios.post(`${FACULTY_AUTH_URL}/verify-otp`, postBody, {});

export const fetchFacultyPasswordReset = (postBody) =>
  axios.post(`${FACULTY_AUTH_URL}/reset-password`, postBody, {});

export const fetchUserDetails = (rollno) =>
  axios.get(`${AUTH_URL}/roll/${rollno}`, {});

export const fetchDeleteSubmission = (rollno) =>
  axios.delete(`${APPLY_URL}/delete/${rollno}`, {});

export const fetchDeleteUser = (rollno) =>
  axios.delete(`${AUTH_URL}/delete/${rollno}`, {});

// For upload

async function handleImageUpload(img) {
  const imageFile = img;

  const options = {
    maxSizeMB: 1,
    maxWidthOrHeight: 1920,
    useWebWorker: true,
  };

  return imageCompression(imageFile, options);
}

export const fetchUploadFile = async (file) => {
  if (file && (file.type === "image/jpeg" || file.type === "image/png")) {
    await handleImageUpload(file).then((res) => {
      file = new File([res], res.name, { type: res.type });
    });
  }
  let data = new FormData();
  data.append("file", file);
  return axios.post(UPLOAD_URL, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

// For Apply

export const fetchGetSubmission = (rollno) =>
  axios.get(`${APPLY_URL}/${rollno}`, {});

export const fetchAllSubmissions = () => axios.post(`${APPLY_URL}/all`, {}, {});

export const fetchAllSubmissionsByDept = (dept) =>
  axios.post(`${APPLY_URL}/dept`, { dept: dept }, {});

export const fetchAllSubmissionsByTutor = (email) =>
  axios.post(`${APPLY_URL}/tutor-email`, { email: email }, {});

export const fetchPostSection1 = (rollno, postBody) =>
  axios.post(`${APPLY_URL}/section-1/${rollno}`, postBody, {});

export const fetchPostSection2 = (rollno, postBody) =>
  axios.post(`${APPLY_URL}/section-2/${rollno}`, postBody, {});

export const fetchPostSection3 = (rollno, postBody) =>
  axios.post(`${APPLY_URL}/section-3/${rollno}`, postBody, {});

export const fetchPostSection4 = (rollno, postBody) =>
  axios.post(`${APPLY_URL}/section-4/${rollno}`, postBody, {});

export const fetchPostSection5 = (rollno, postBody) =>
  axios.post(`${APPLY_URL}/section-5/${rollno}`, postBody, {});

export const fetchPostSection6 = (rollno, postBody) =>
  axios.post(`${APPLY_URL}/section-6/${rollno}`, postBody, {});

export const fetchPostSection7 = (rollno, postBody) =>
  axios.post(`${APPLY_URL}/section-7/${rollno}`, postBody, {});

export const fetchPostSection8 = (rollno, postBody) =>
  axios.post(`${APPLY_URL}/section-8/${rollno}`, postBody, {});

export const fetchPostSection9 = (rollno, postBody) =>
  axios.post(`${APPLY_URL}/section-9/${rollno}`, postBody, {});

export const fetchPostSection10 = (rollno, postBody) =>
  axios.post(`${APPLY_URL}/section-10/${rollno}`, postBody, {});

export const fetchConfirm = (rollno) =>
  axios.post(`${APPLY_URL}/confirm/${rollno}`, {}, {});

export const fetchTutorApprove = (rollno) =>
  axios.post(`${APPLY_URL}/tutorApprove/${rollno}`, {}, {});
export const fetchTutorReject = (rollno) =>
  axios.post(`${APPLY_URL}/tutorReject/${rollno}`, {}, {});
export const fetchHODApprove = (rollno) =>
  axios.post(`${APPLY_URL}/hodApprove/${rollno}`, {}, {});
export const fetchHODReject = (rollno) =>
  axios.post(`${APPLY_URL}/hodReject/${rollno}`, {}, {});
export const fetchAdminApprove = (rollno) =>
  axios.post(`${APPLY_URL}/adminApprove/${rollno}`, {}, {});
export const fetchAdminReject = (rollno) =>
  axios.post(`${APPLY_URL}/adminReject/${rollno}`, {}, {});

// For Faculty

export const fetchFacultyDetails = (email) =>
  axios.get(`${FACULTY_AUTH_URL}/details/${email}`, {});

export const fetchTutorsByDept = (dept) =>
  axios.post(`${FACULTY_AUTH_URL}/tutors-by-dept`, { department: dept }, {});

// For Recommends

export const fetchGetRecommend = () => axios.get(`${RECOMMEND_URL}`, {});

export const fetchPostRecommend = (postBody) =>
  axios.post(`${RECOMMEND_URL}`, postBody, {});

export const fetchHasRecommended = (rollNo) =>
  axios.get(`${RECOMMEND_URL}/has-recommended/${rollNo}`, {});

// For Settings

export const fetchGetSettings = () => axios.get(`${SETTINGS_URL}/`, {});

export const fetchPostSettings = (postBody) =>
  axios.post(`${SETTINGS_URL}/`, postBody, {});

export const fetchPostInviteToAll = () =>
  axios.post(`${SETTINGS_URL}/send-invite-to-faculty/`, {}, {});

// For Stats
export const fetchCountAllSubs = () =>
  axios.get(`${APPLY_URL}/count-all/`, {});

export const fetchCountByDept = () =>
  axios.get(`${APPLY_URL}/statistics/`, {});
