import React, { useState } from "react";
import toast from "react-hot-toast";
import Button from "../../components/Button";
import Heading from "../../components/Heading";
import Row from "../../components/Row";
import Inputfield from "../../components/TextInput";
import Toggle from "../../components/Toggle";
import { FaUserGraduate, FaUserTie } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { fetchFacultyLogin, fetchStudentLoginVerify } from "../../API/calls";

const Login = () => {
  return (
    <main className="h-screen w-screen overflow-x-hidden lg:overflow-y-hidden flex flex-col lg:flex-row">
      <PrimarySection />
      <RegisterFormSection />
    </main>
  );
};

export default Login;

const PrimarySection = () => {
  const navigate = useNavigate();

  const handleSignup = () => {
    navigate("/");
  };

  return (
    <section className="lg:p-8 bg-white h-full w-full lg:w-5/12">
      <div className="bg-gradient-to-b from-ming to-pacific p-8 lg:rounded-xl shadow-xl h-full flex flex-col justify-between">
        <header className="flex space-x-4 items-center pb-4">
          <img
            src="https://upload.wikimedia.org/wikipedia/en/e/eb/PSG_College_of_Technology_logo.png"
            alt=""
            className="h-12 lg:h-16 w-auto filter invert"
          />
          <div className=" text-white font-poppins ">
            <p className="text-xl lg:text-2xl">PSG College of Technology</p>
            <p className="text-sm">Coimbatore</p>
          </div>
        </header>
        <div className="">
          <h1 className="text-white font-poppins text-4xl mt-8">
            Apply for Best Outgoing Student Awards {new Date().getFullYear()}
          </h1>
          <h2 className="text-white font-poppins text-base mt-4 tracking-wide">
            Student with CGPA of 8.0 and above & without Any Reappearance (RA)
            should only apply.
          </h2>
        </div>
        <div className="rounded-lg bg-ming lg:p-8 p-4 font-fira text-xl mt-8">
          <div className="flex flex-col lg:flex-row items-start mb-4">
            <h1 className="text-white  border-b-2 border-transparent">
              Don't have an account ?
            </h1>
            <button
              className="text-white lg:ml-2 border-b border-dashed box-border hover:text-middle"
              onClick={handleSignup}
            >
              Click to Sign Up
            </button>
          </div>
          {/* <p className="text-sm font-poppins text-white">
            Developed by{" "}
            <span className="text-middle">
              <a href="https://github.com/tgashwinkumar" className="">
                TG Ashwin Kumar
              </a>
            </span>
            ,{" "}
            <span className="text-middle">
              {" "}
              <a href="https://github.com/jeyam03" className="">
                Jeyam Palaniappan D
              </a>
            </span>
            <span className="text-middle">
              ,{" "}
              <a href="https://github.com/dineshchandran311" className="">
                Dinesh Chandran
              </a>
            </span>
          </p> */}
          <p className="text-sm font-poppins text-white">
            For any queries contact{" "}
            <a className="text-middle" href="mailto:bestoutgoing@psgtech.ac.in">
              bestoutgoing@psgtech.ac.in
            </a>
          </p>
          <p className="text-sm font-poppins text-white">
            For tutorial{" "}
            <a
              className="text-middle"
              href="https://tutorialforstudents.netlify.app"
            >
              Click here
            </a>
          </p>{" "}
        </div>
      </div>
    </section>
  );
};

const RegisterFormSection = () => {
  const [loginType, setLoginType] = useState("Student");
  const [rollNumber, setRollNumber] = useState("");
  const [studentPassword, setStudentPassword] = useState("");
  const [emailID, setEmailID] = useState("");
  const [facultyPassword, setFacultyPassword] = useState("");

  const navigate = useNavigate();

  const handleClick = () => {
    if (loginType === "Student") {
      toast.promise(
        fetchStudentLoginVerify({
          roll: rollNumber.toLowerCase(),
          password: studentPassword,
        }),
        {
          loading: "Verifying...",
          success: (res) => {
            localStorage.setItem("rollno", rollNumber.toLowerCase());
            localStorage.setItem("token", res.data.token);
            localStorage.setItem("rights", res.data.rights);
            navigate("/apply");
            return "Login Successful";
          },
          error: (err) => {
            console.log(err);
            return `Retry again: ${err?.response?.data?.error}`;
          },
        }
      );
    }
    if (loginType === "Faculty") {
      localStorage.clear();
      toast.promise(
        fetchFacultyLogin({ email: emailID, password: facultyPassword }),
        {
          loading: "Verifying...",
          success: (res) => {
            localStorage.setItem("email", emailID);
            localStorage.setItem("token", res.data.token);
            localStorage.setItem("rights", res.data.rights);
            res.data.rights === "nmc"
              ? navigate("/nmc-admin")
              : navigate("/admin-portal");
            return "Login Successful";
          },
          error: (err) => {
            console.log(err);
            return `Retry again: ${err?.response?.data?.error}`;
          },
        }
      );
    }
  };

  return (
    <section className="w-full lg:w-7/12 h-full py-16 p-8 lg:pl-0 lg:pr-[calc(100vw/12)] flex flex-col">
      <div className="hidden lg:block mb-4 w-[60%] ml-96 h-48 bg-[url(https://i.ibb.co/q94VWQN/ka3.png)] bg-cover "></div>
      <Heading>Login</Heading>
      <div className="space-y-6 mt-8 w-full lg:w-[60%]">
        <Toggle
          title={"Choose type"}
          className="mt-4"
          options={["Student", "Faculty"]}
          valueState={[loginType, setLoginType]}
          icons={[<FaUserGraduate />, <FaUserTie />]}
        />
        {loginType === "Student" ? (
          <div>
            <Inputfield
              className="mt-8"
              valueState={[rollNumber, setRollNumber]}
              placeholder="Enter Roll Number"
              title="Roll Number"
            />
            <Inputfield
              className="mt-4"
              valueState={[studentPassword, setStudentPassword]}
              placeholder="Enter Password"
              title="Password"
              type="password"
            />
            <div className="flex justify-end">
              <button
                className="mt-4 w-full lg:w-1/3 text-[#0066C0] hover:underline text-right"
                onClick={() => navigate("/forgot-password-student")}
              >
                Forgot Password
              </button>
            </div>
          </div>
        ) : (
          <div>
            <Inputfield
              className="w-full mt-8"
              valueState={[emailID, setEmailID]}
              placeholder="Enter Email ID"
              title="Email ID"
            />
            <Inputfield
              className="mt-4"
              valueState={[facultyPassword, setFacultyPassword]}
              placeholder="Enter Password"
              title="Password"
              type="password"
            />
            <div className="flex justify-end">
              <button
                className="mt-4 w-full lg:w-1/3 text-[#0066C0] hover:underline text-right"
                onClick={() => navigate("/forgot-password-faculty")}
              >
                Forgot Password
              </button>
            </div>
          </div>
        )}
      </div>
      <div className="flex-1"></div>
      <Button
        text="Login"
        className="mt-8 w-full lg:w-1/3"
        handleClick={handleClick}
      />
      <div className="p-4 lg:hidden"></div>
    </section>
  );
};
