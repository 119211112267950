import React, { useContext, useEffect, useState } from "react";
import Button from "../../components/Button";
import Heading from "../../components/Heading";
import Row from "../../components/Row";
import TextArea from "../../components/TextArea";
import Toggle from "../../components/Toggle";
import DialogBox from "../../components/DialogBox";
import { useNavigate } from "react-router-dom";
import { SubmissionContext } from ".";
import { toast } from "react-hot-toast";
import { fetchPostSection7 } from "../../API/calls";

const Section7 = () => {
  const [recognition, setRecognition] = useState("");
  const navigate = useNavigate();

  const [submission, setSubmission] = useContext(SubmissionContext);

  useEffect(() => {
    if (!submission) return;
    const draft = submission[7];
    if (!draft) return;
    setRecognition(draft.recognition);
  }, [submission]);

  const handleProceed = async (shouldExit) => {
    const postBody = {
      rollNo: localStorage.getItem("rollno"),
      recognition: recognition,
    };

    toast.promise(fetchPostSection7(localStorage.getItem("rollno"), postBody), {
      loading: "Saving...",
      success: (res) => {
        shouldExit ? navigate("/login") : navigate("/apply/section-8");
        return "Section-7 Successfully Saved";
      },
      error: (err) => {
        return `Error Saving Section-7: ${err.message}`;
      },
    });
  };
  
  return (
    <main className="w-full flex flex-col overflow-y-hidden">
      <div className="mb-8">
        <h2 className="font-poppins">Section 7</h2>
        <Heading>Recognition at Department Level</Heading>
      </div>
      <div className="overflow-y-scroll lg:pr-16 space-y-4 h-full lg:h-[calc(100vh-26rem)]">
        <div>
          <DialogBox
            title="Info"
            content={
              <div>
                <li>Best academic performance</li>
                <li>Contributions to the department labs by
                  <li className="pl-4">making models,</li>
                  <li className="pl-4">good videos,</li>
                  <li className="pl-4">helping placement activities by conducting skill enhancement activities.</li>
                </li>
              </div>
            }
          />
          <TextArea
            className={"mt-4"}
            title={"Please mention if any"}
            valueState={[recognition, setRecognition]}
          />
        </div>
      </div>
      <Row className="mt-8">
        <Button text="Save and Proceed" className="w-full lg:w-1/3" handleClick={e => handleProceed(false)} />
        <Button isOutlined text="Save and Exit" className="w-full lg:w-1/3" handleClick={e => handleProceed(true)} />
      </Row>
    </main>
  );
};

export default Section7;
