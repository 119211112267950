import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../components/Button";
import Heading from "../../components/Heading";
import Row from "../../components/Row";
import TextArea from "../../components/TextArea";
import { SubmissionContext } from ".";
import { toast } from "react-hot-toast";
import { fetchPostSection10, fetchUploadFile } from "../../API/calls";

const Section10 = () => {
  const [applicationReason, setApplicationReason] = useState("");
  const [wordsState, setWordsState] = useState(0);
  const countLimit = 2000;
  const navigate = useNavigate();

  const [submission, setSubmission] = useContext(SubmissionContext);

  useEffect(() => {
    if (!submission) return;
    const draft = submission[10];
    if (!draft) return;
    setApplicationReason(draft.reason ? draft.reason : "");
  }, [submission]);

  const handleProceed = async (shouldExit) => {
    if (applicationReason.length === 0) {
      toast.error("Please provide a statement of purpose");
      return;
    }

    if (wordsState > countLimit) {
      toast.error(`Maximum ${countLimit} words allowed`);
      return;
    }

    const postBody = {
      rollNo: localStorage.getItem("rollno"),
      reason: applicationReason,
    };

    toast.promise(fetchPostSection10(localStorage.getItem("rollno"), postBody), {
      loading: "Saving...",
      success: (res) => {
        shouldExit ? navigate("/login") : navigate("/apply/confirm");
        return "Application Successfully Saved";
      },
      error: (err) => {
        return `Error Saving: ${err.message}}`
      },
    });

  };

  return (
    <main className="w-full flex flex-col overflow-y-hidden">
      <div className="mb-8">
        <h2 className="font-poppins">Section 10</h2>
        <Heading>Statement of Purpose</Heading>
      </div>
      <div className="overflow-y-scroll lg:pr-16 space-y-4 h-full lg:h-[calc(100vh-26rem)]">
        <TextArea
          title={`Kindly write up about the motivation for applying for this award, statement of purpose and a self assessment report.  (Max. ${countLimit} words)`}
          valueState={[applicationReason, setApplicationReason]}
          wordsState={[wordsState, setWordsState]}
          countLimit={countLimit}
        />
      </div>
      <Row className="mt-8">
        <Button text="Save and Proceed" className="w-full lg:w-1/3" handleClick={e => handleProceed(false)} />
        <Button isOutlined text="Save and Exit" className="w-full lg:w-1/3" handleClick={e => handleProceed(true)} />
      </Row>
    </main>
  );
};

export default Section10;
