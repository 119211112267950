const departments = [
  "APPLIED MATHEMATICS AND COMPUTATIONAL SCIENCES",
  "APPLIED SCIENCE",
  "APPAREL AND FASHION DESIGN",
  "AUTOMOBILE ENGINEERING",
  "BIOMEDICAL ENGINEERING",
  "CIVIL ENGINEERING",
  "COMPUTER SCIENCE & ENGINEERING",
  "COMPUTER APPLICATIONS",
  "ELECTRICAL & ELECTRONICS ENGINEERING",
  "ELECTRONICS & COMMUNICATION ENGINEERING",
  "INSTRUMENTATION & CONTROL ENGINEERING",
  "MBA",
  "MECHANICAL ENGINEERING",
  "METALLURGICAL ENGINEERING",
  "PRODUCTION ENGINEERING",
  "ROBOTICS & AUTOMATION ENGINEERING",
  "BIOTECHNOLOGY",
  "FASHION TECHNOLOGY",
  "INFORMATION TECHNOLOGY",
  "TEXTILE TECHNOLOGY",
  // "ELECTRICAL & ELECTRONICS ENGINEERING (SANDWICH)",
  // "MECHANICAL ENGINEERING (SANDWICH)",
  // "PRODUCTION ENGINEERING (SANDWICH)",
];

export default departments;
