import React, { useState } from "react";
import toast from "react-hot-toast";
import Button from "../../components/Button";
import Dropdown from "../../components/Dropdown";
import departments from "../../utils/Departments";
import DialogBox from "../../components/DialogBox";
import Heading from "../../components/Heading";
import Row from "../../components/Row";
import Inputfield from "../../components/TextInput";
import { fetchRegisterUser } from "../../API/calls";
import { Link, useNavigate } from "react-router-dom";

const Register = () => {
  return (
    <main className="h-screen w-screen overflow-x-hidden lg:overflow-y-hidden flex flex-col lg:flex-row">
      <PrimarySection />
      <RegisterFormSection />
      {/* <ClosedForm /> */}
    </main>
  );
};

export default Register;

const ClosedForm = () => {
  return (
    <section className="w-full lg:w-7/12 h-full py-8 p-8 lg:pl-0 lg:pr-[calc(100vw/12)] flex flex-col items-center justify-center">
      <div className="text-lg font-poppins text-center bg-middle text-ming p-8 rounded-xl border-4 border-dashed border-ming">
        <p className="text-xl font-semibold mb-4">Oops!</p>
        We have currently closed the application to Best Outgoing Student Awards{" "}
        {new Date().getFullYear()}. You can{" "}
        <Link to="/login" className="text-pacific font-semibold">
          {" "}
          login{" "}
        </Link>{" "}
        to download the submitted application.
      </div>
    </section>
  );
};

const PrimarySection = () => {
  const navigate = useNavigate();

  const handleLogin = () => {
    navigate("/login");
  };

  return (
    <section className="lg:p-8 bg-white h-full w-full lg:w-5/12">
      <div className="bg-gradient-to-b from-ming to-pacific p-8 lg:rounded-xl shadow-xl h-full flex flex-col justify-between">
        <header className="flex space-x-4 items-center pb-4">
          <img
            src="https://upload.wikimedia.org/wikipedia/en/e/eb/PSG_College_of_Technology_logo.png"
            alt=""
            className="h-12 lg:h-16 w-auto filter invert"
          />
          <div className=" text-white font-poppins ">
            <p className="text-xl lg:text-2xl">PSG College of Technology</p>
            <p className="text-sm">Coimbatore</p>
          </div>
        </header>
        <div className="">
          <h1 className="text-white font-poppins text-4xl mt-8">
            Apply for Best Outgoing Student Awards {new Date().getFullYear()}
          </h1>
          <h2 className="text-white font-poppins text-base mt-4 tracking-wide">
            Student with CGPA of 8.0 and above & without Any Reappearance (RA)
            should only apply.
          </h2>
        </div>
        <div className="rounded-lg bg-ming lg:p-8 p-4 font-fira text-xl mt-8">
          <div className="flex flex-col lg:flex-row items-start mb-4">
            <h1 className="text-white  border-b-2 border-transparent">
              Already have an account ?
            </h1>
            <button
              className="text-white lg:ml-2 border-b border-dashed box-border hover:text-middle"
              onClick={handleLogin}
            >
              Click to Login
            </button>
          </div>
          {/* <p className="text-sm font-poppins text-white">
            Developed by{" "}
            <span className="text-middle">
              <a href="https://github.com/tgashwinkumar" className="">
                TG Ashwin Kumar
              </a>
            </span>
            ,{" "}
            <span className="text-middle">
              {" "}
              <a href="https://github.com/jeyam03" className="">
                Jeyam Palaniappan D
              </a>
            </span>
            <span className="text-middle">
              ,{" "}
              <a href="https://github.com/dineshchandran311" className="">
                Dinesh Chandran
              </a>
            </span>
          </p> */}
          <p className="text-sm font-poppins text-white">
            For any queries contact{" "}
            <a className="text-middle" href="mailto:bestoutgoing@psgtech.ac.in">
              bestoutgoing@psgtech.ac.in
            </a>
          </p>
          <p className="text-sm font-poppins text-white">
            For tutorial{" "}
            <a
              className="text-middle"
              href="https://tutorialforstudents.netlify.app"
            >
              Click here
            </a>
          </p>
        </div>
      </div>
    </section>
  );
};

const RegisterFormSection = () => {
  const [name, setName] = useState("");
  const [dept, setDept] = useState("");
  const [roll, setRoll] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordErrorState, setPasswordErrorState] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordErrorState, setConfirmPasswordErrorState] =
    useState("");

  const handleApply = () => {
    if (
      password.length <= 0 ||
      name.length <= 0 ||
      dept.length <= 0 ||
      roll.length <= 0 ||
      email.length <= 0
    )
      return toast.error("Error: Enter all the fields.");

    if (!email.endsWith("@psgtech.ac.in"))
      return toast.error("Enter official email address provided.");

    if (roll.toLowerCase() !== email.split("@")[0].toLowerCase())
      return toast.error("Error: Roll number and Email mismatch");

    if (password !== confirmPassword)
      return toast.error("Error: Passwords do not match");
    if (
      password.length < 8 ||
      !/[A-Z]/.test(password) ||
      !/[!_#$&%@]/.test(password) ||
      !/[0-9]/.test(password)
    ) {
      console.log(
        password.length >= 8,
        /[A-Z]/.test(password),
        /[!_#$&%@]/.test(password),
        /[0-9]/.test(password)
      );
      return toast.error("Error: Enter valid password");
    }
    toast.promise(
      fetchRegisterUser({
        name: name,
        department: dept,
        roll: roll.toLowerCase(),
        email: email.toLowerCase(),
        password: password,
        rights: "student",
      }),
      {
        loading: "Registering...",
        success: (res) => {
          return "Check Email for Verification";
        },
        error: (err) => {
          return `Error: ${err.response.data.error}`;
        },
      }
    );
  };

  return (
    <section className="w-full lg:w-7/12 h-full py-8 p-8 lg:pl-0 lg:pr-[calc(100vw/12)] flex flex-col">
      {/* <div className="mb-4 w-[60%] ml-96 h-48 bg-[url(https://i.ibb.co/9yyhjKV/ka2.png)] bg-cover bg-center" /> */}

      <Heading>Sign up</Heading>
      <div className="space-y-6 mt-8 h-[calc(100vh-8rem)] overflow-y-auto pr-8">
        <Row>
          <Inputfield
            title="Name (as per Academic Records)"
            placeholder="Enter your name"
            valueState={[name, setName]}
          />
          <Dropdown
            title="Department"
            options={departments}
            placeholder="Select Department"
            valueState={[dept, setDept]}
          />
        </Row>
        <Row>
          <Inputfield
            title="Roll Number"
            placeholder="Enter your roll number"
            valueState={[roll, setRoll]}
          />
          <Inputfield
            title="Official Email Address"
            placeholder="Enter your email address"
            valueState={[email, setEmail]}
          />
        </Row>
        <Row>
          <Inputfield
            title="New Password"
            placeholder="Enter your password"
            valueState={[password, setPassword]}
            errorState={[passwordErrorState, setPasswordErrorState]}
            type="password"
          />
          <Inputfield
            title="Confirm Password"
            placeholder="Confirm your password"
            valueState={[confirmPassword, setConfirmPassword]}
            errorState={[
              confirmPasswordErrorState,
              setConfirmPasswordErrorState,
            ]}
            type="password"
          />
        </Row>
        <DialogBox
          title="For Note"
          content={
            <div>
              Password must
              <li>
                Have minimum length of <strong>8</strong> characters
              </li>
              <li>
                Contain an <strong>uppercase letter</strong> (A-Z)
              </li>
              <li>
                Contain a <strong>special character</strong> (! _ # $ & % @)
              </li>
              <li>
                Contain a <strong>number</strong> (0-9)
              </li>
            </div>
          }
        />
      </div>
      <div className="flex-1"></div>
      <Button
        text="Apply Now"
        className="mt-8 w-full lg:w-1/3"
        handleClick={handleApply}
      />
      <div className="p-4 lg:hidden"></div>
    </section>
  );
};
