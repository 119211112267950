import React, { useContext, useEffect, useState } from "react";
import Heading from "../../components/Heading";
import Table from "../../components/Table";
import { FacultyContext } from ".";
import { toast } from "react-hot-toast";
import html2pdf from "html2pdf.js";
import generateSubmissionTemplate from "../../template-generators/generateSubmissionTemplate.js";
import Toggle from "../../components/Toggle";
import { RefreshContext } from "../../Refresher";
import {
  fetchAdminApprove,
  fetchAdminReject,
  fetchHODApprove,
  fetchHODReject,
  fetchTutorApprove,
  fetchTutorReject,
} from "../../API/calls";

const Submissions = () => {
  const [data, setData] = useState(null);
  const { submissionState, facultyState } = useContext(FacultyContext);
  const [submissions, setSubmissions] = submissionState;
  const [facultyDetails, setFacultyDetails] = facultyState;
  const [condition, setCondition] = useState("All");
  const { refreshPage } = useContext(RefreshContext);

  useEffect(() => {
    if (!submissions) return;
    setData(
      submissions
        .filter((sub) => sub.progress.approvedByHOD === "approved")
        .map((sub) => {
          return {
            image_url: sub[1].photoUrl,
            name: sub.userDetails.name,
            roll: sub.userDetails.rollNo,
            dept: sub.userDetails.dept,
            status:
              facultyDetails.rights === "tutor"
                ? sub.progress.approvedByTutor
                : facultyDetails.rights === "hod"
                  ? sub.progress.approvedByHOD
                  : facultyDetails.rights === "admin"
                    ? sub.progress.approvedByAdmin
                    : "Pending",
          };
        })
    );
    console.log(data);
  }, [submissions]);

  const handleDownload = (roll) => {
    const sub = submissions.find((sub) => sub.rollno === roll);

    toast.promise(generateSubmissionTemplate(sub),
      {
        loading: "Generating PDF",
        success: (res) => {
          return "Successfully generated PDF";
        },
        error: (err) => {
          return `Error generating PDF ${err}`;
        },
      }
    );
  };

  const ApproveButton = (roll) => {
    console.log(roll, facultyDetails);
    facultyDetails.rights === "tutor"
      ? toast.promise(fetchTutorApprove(roll), {
        loading: "Approving...",
        success: (res) => {
          console.log("Approved");
          window.location.reload();
          return "Submission approved successfully";
        },
        error: (err) => {
          console.log(err);
          return "Error approving";
        },
      })
      : facultyDetails.rights === "hod"
        ? toast.promise(fetchHODApprove(roll), {
          loading: "Approving...",
          success: (res) => {
            console.log("Approved");
            window.location.reload();
            return "Submission approved successfully";
          },
          error: (err) => {
            console.log(err);
            return "Error approving";
          },
        })
        : facultyDetails.rights === "admin"
          ? toast.promise(fetchAdminApprove(roll), {
            loading: "Approving...",
            success: (res) => {
              console.log("Approved");
              window.location.reload();
              return "Submission approved successfully";
            },
            error: (err) => {
              console.log(err);
              return "Error approving";
            },
          })
          : toast.error("You don't have the rights to approve");
  };

  const RejectButton = (roll) => {
    console.log(roll, facultyDetails);
    facultyDetails.rights === "tutor"
      ? toast.promise(fetchTutorReject(roll), {
        loading: "Rejecting...",
        success: (res) => {
          console.log("Rejected");
          window.location.reload();
          return "Submission rejected successfully";
        },
        error: (err) => {
          console.log(err);
          return "Error rejecting";
        },
      })
      : facultyDetails.rights === "hod"
        ? toast.promise(fetchHODReject(roll), {
          loading: "Rejecting...",
          success: (res) => {
            console.log("Rejected");
            window.location.reload();
            return "Submission rejected successfully";
          },
          error: (err) => {
            console.log(err);
            return "Error rejecting";
          },
        })
        : facultyDetails.rights === "admin"
          ? toast.promise(fetchAdminReject(roll), {
            loading: "Rejecting...",
            success: (res) => {
              console.log("Rejected");
              window.location.reload();
              return "Submission rejected successfully";
            },
            error: (err) => {
              console.log(err);
              return "Error rejecting";
            },
          })
          : toast.error("You don't have the rights to reject");
  };

  return (
    <section className="">
      <Heading>HOD Approved Submissions</Heading>
      {/* {
        status === "Pending" ? (

      } */}

      {data !== null ? (data.length <= 0 ?
        <p className="mt-8 text-lg font-poppins">Loading...</p>
        : (
          <Table
            theads={["Profile", "Name", "Roll No.", "Dept.", "Status"]}
            tdata={data}
            tkeys={["image_url", "name", "roll", "dept", "status"]}
            className={`${data.length < 8
              ? "max-h-[calc(100vh-20rem)]"
              : "h-[calc(100vh-25rem)]"
              } w-full mt-8`}
            tratio="80px 1fr 0.5fr 1fr 1fr"
            handleDownload={handleDownload}
            ApproveButton={ApproveButton}
            RejectButton={RejectButton}
          />
        )) : (
        <p className="mt-8 text-lg font-poppins">Loading data...</p>
      )}
    </section>
  );
};

export default Submissions;
